export default {
    "：": "：",
    "简体": "簡體",
    "繁体": "繁體",
    "请选择": "請選擇",
    "首页": "首頁",
    "维修单服务": "維修單服務",
    "整机更换": "整機更換",
    "维修单查询": "維修單查詢",
    "零件信息查询": "零件信息查詢",
    "考核与统计": "考核與統計",
    "结账单服务": "結賬單服務",
    "自动咖啡机维护保养": "自動咖啡機維護保養",
    "维护保养查询": "維護保養查詢",
    "保养回访查询": "保養回訪查詢",
    "奖惩查询": "獎懲查詢",
    "奖惩": "獎懲",
    "奖惩项目": "獎懲項目",
    "奖惩金额": "獎懲金額",
    "设置": "設置",
    "用户名或密码错误，登录失败！": "用户名或密碼錯誤，登錄失敗！",
    "无法连接服务器": "無法連接服務器",
    "请输入用户名/密码": "請輸入用户名/密碼",
    "请输入验证码": "請輸入驗證碼",
    "验证码错误": "驗證碼錯誤",
    "验证码": "驗證碼",
    "请输入用户名": "請輸入用户名",
    "用户名": "用户名",
    "请输入密码": "請輸入密碼",
    "密码": "密碼",
    "自动登录": "自動登錄",
    "登录": "登錄",
    "网站备案/许可证号：沪ICP备20004800号-1": "網站備案/許可證號：滬ICP備20004800號-1",
    "沪公网安备31010702006927号": "滬公網安備31010702006927號",
    "版本：": "版本：",
    "版本": "版本",
    "提示": "提示",
    "浏览器当前无法播放声音，请点击确定按钮，以便及时接收到用户信息。": "瀏覽器當前無法播放聲音，請點擊確定按鈕，以便及時接收到用户信息。",
    "密码修改失败！": "密碼修改失敗！",
    "密码修改失败，请重试！": "密碼修改失敗，請重試！",
    "旧密码输入不正确或两次密码输入不一致！": "舊密碼輸入不正確或兩次密碼輸入不一致！",
    "网络售后维护": "網絡售後維護",
    "网络售后维护系统": "網絡售後維護系統",
    "当前用户：": "當前用户：",
    "当前用户": "當前用户",
    "【注销】": "【註銷】",
    "注销账号": "註銷賬號",
    "修改密码": "修改密碼",
    "确定": "確定",
    "取消": "取消",
    "注销": "註銷",
    "确定要注销当前账号吗？": "確定要註銷當前賬號嗎？",
    "您已超过90天没有修改密码，为了您的账号安全，请前往修改": "您已超過90天沒有修改密碼，為了您的賬號安全，請前往修改",
    "密码长度不小于8位，并包括大小写字母、特殊符号和数字": "密碼長度不小於8位，並包括大小寫字母、特殊符號和數字",
    "英文大小写字母、数字、_@.$%": "英文大小寫字母、數字、_@.$%",
    "前往修改": "前往修改",
    "原密码": "原密碼",
    "修改": "修改",
    "旧密码": "舊密碼",
    "新密码": "新密碼",
    "密码确认": "密碼確認",
    "未上传": "未上傳",
    "是": "是",
    "否": "否",
    "刷新": "刷新",
    "上传": "上傳",
    "新增": "新增",
    "查看": "查看",
    "编辑": "編輯",
    "删除": "刪除",
    "保存": "保存",
    "作废": "作廢",
    "打印": "打印",
    "返回": "返回",
    "导出Excel": "導出Excel",
    "导出CSV": "導出CSV",
    "导出SAP": "導出SAP",
    "导出TXT": "導出TXT",
    "维修服务工作单": "維修服務工作單",
    "热线电话：": "熱線電話：",
    "热线电话": "熱線電話",
    "可上门服务": "可上門服務",
    "维修站：": "維修站：",
    "维修站": "維修站",
    "系统单号：": "系統單號：",
    "系统单号": "系統單號",
    "单号：": "單號：",
    "单号": "單號",
    "服务类型：": "服務類型：",
    "服务类型": "服務類型",
    "服务单编号：": "服務單編號：",
    "服务单编号": "服務單編號",
    "服务单号": "服務單號",
    "客服系统单号：": "客服系統單號：",
    "客服系统工号：": "客服系統工號：",
    "客服系统单号": "客服系統單號",
    "客服系统工号": "客服系統工號",
    "用户姓名：": "用户姓名：",
    "接单日期：": "接單日期：",
    "公司名称：": "公司名稱：",
    "品牌": "品牌",
    "品牌：": "品牌：",
    "德龙": "德龍",
    "凯伍德": "凱伍德",
    "博朗": "博朗",
    "移动电话：": "移動電話：",
    "机器型号：": "機器型號：",
    "机器型号": "機器型號",
    "固定电话：": "固定電話：",
    "机器名称：": "機器名稱：",
    "联系地址：": "聯繫地址：",
    "机器编号：": "機器編號：",
    "机器编号": "機器編號",
    "随机附件：": "隨機附件：",
    "随机附件": "隨機附件",
    "产品性质：": "產品性質：",
    "产品性质": "產品性質",
    "售前": "售前",
    "售后": "售後",
    "样机": "樣機",
    "折扣机": "折扣機",
    "机身编号：": "機身編號：",
    "机身编号": "機身編號",
    "插座检查：": "插座檢查：",
    "插座检查": "插座檢查：",
    "插座故障：": "插座故障：",
    "纸质工单：": "紙質工單：",
    "纸质工单": "紙質工單",
    "两次或以上维修：": "兩次或以上維修：",
    "保修依据：": "保修依據：",
    "保修依据": "保修依據",
    "保修": "保修",
    "非保修": "非保修",
    "延保": "延保",
    "故障描述：": "故障描述：",
    "故障描述": "故障描述",
    "电话完成": "電話完成",
    "出仓日期：": "出倉日期：",
    "出仓日期": "出倉日期",
    "预计完成日期：": "預計完成日期：",
    "预计完成日期": "預計完成日期",
    "维修费用：": "維修費用：",
    "维修费用": "維修費用",
    "实际完成日期：": "實際完成日期：",
    "实际完成日期": "實際完成日期",
    "预约上门时间：": "預約上門時間：",
    "预约上门时间": "預約上門時間",
    "预约确认日期：": "預約確認日期：",
    "预约确认日期": "預約確認日期",
    "上门公里数：": "上門公里數：",
    "上门公里数": "上門公里數",
    "上门费用：": "上門費用：",
    "上门费用": "上門費用",
    "维修类型：": "維修類型：",
    "维修类型": "維修類型",
    "更换配件": "更換配件",
    "维护保养": "維護保養",
    "无问题": "無問題",
    "调试": "調試",
    "更换整机": "更換整機",
    "更换整机-颜色": "更換整機-顏色",
    "更换整机-送货到家": "更換整機-送貨到家",
    "检测": "檢測",
    "维修": "維修",
    "制作杯数：": "製作杯數：",
    "制作杯数": "製作杯數",
    "除垢次数：": "除垢次數：",
    "除垢次数": "除垢次數",
    "使用水量：": "使用水量：",
    "使用水量": "使用水量",
    "蒸汽次数：": "蒸汽次數：",
    "蒸汽次数": "蒸汽次數",
    "蒸汽水量：": "蒸汽水量：",
    "蒸汽水量": "蒸汽水量",
    "咖啡水升数：": "咖啡水升數：",
    "咖啡水升数": "咖啡水升數",
    "蒸汽水升数：": "蒸汽水升數：",
    "蒸汽水升数": "蒸汽水升數",
    "热水升数：": "熱水升數：",
    "热水升数": "熱水升數",
    "饮品总杯数：": "飲品總杯數：",
    "饮品总杯数": "飲品總杯數",
    "饮品总升数：": "飲品總升數：",
    "饮品总升数": "飲品總升數",
    "EKP单号：": "EKP單號：",
    "EKP单号": "EKP單號",
    "检查记录：": "檢查記錄：",
    "检查记录": "檢查記錄",
    "维修记录：": "維修記錄：",
    "维修记录": "維修記錄",
    "颜色：": "顏色：",
    "颜色": "顏色",
    "送货到家：": "送貨到家：",
    "送货到家": "送貨到家",
    "故障分类：": "故障分類：",
    "序号": "序號",
    "零件编码": "零件編碼",
    "零件名称": "零件名稱",
    "零件数量": "零件數量",
    "零件单价": "零件單價",
    "适用型号": "適用型號",
    "数量": "數量",
    "单价": "單價",
    "金额": "金額",
    "合计": "合計",
    "其他费用：": "其他費用：",
    "其他费用": "其他費用",
    "其他费用明细：": "其他費用明細：",
    "其他费用明细": "其他費用明細",
    "费用明细": "費用明細",
    "新机生产编号：": "新機生產編號：",
    "新机生产编号": "新機生產編號",
    "生产编号：": "生產編號：",
    "生产编号": "生產編號",
    "是否通知用户取货": "是否通知用户取貨",
    "需通知用户取货": "需通知用戶取貨",
    "通知日期：": "通知日期：",
    "通知日期": "通知日期",
    "取货日期：": "取貨日期：",
    "取货日期": "取貨日期",
    "奖励/处罚": "獎勵/處罰",
    "奖励和处罚的项目": "獎勵和處罰的項目",
    "接机人员：": "接機人員：",
    "接机人员": "接機人員",
    "维修人员：": "維修人員：",
    "维修人员": "維修人員",
    "维修站物流单号：": "維修站物流單號：",
    "维修站物流单号": "維修站物流單號",
    "顾客物流单号：": "顧客物流單號：",
    "顾客物流单号": "顧客物流單號",
    "快递名称+单号": "快遞名稱+單號",
    "备注：": "備注：",
    "备注": "備注",
    "加急工单：": "加急工單：",
    "加急工单": "加急工單",
    "是否加急工单": "是否加急工單",
    "首次维修：": "首次維修：",
    "首次维修": "首次維修",
    "不包括调试": "不包括調試",
    "请选择是否首次维修!": "請選擇是否首次維修!",
    "请选择是否两次或以上维修!": "請選擇是否兩次或以上維修!",
    "离仓数据上传明细": "離倉數據上傳明細",
    "请选择日期": "請選擇日期",
    "YYYY/MM": "YYYY/MM",
    "YYYY/MM/DD": "YYYY/MM/DD",
    "YYYY/MM/DD HH:mm": "YYYY/MM/DD HH:mm",
    "YYYY/MM/DD HH:mm:ss": "YYYY/MM/DD HH:mm:ss",
    "国内发票": "國內發票",
    "订单号查询": "訂單號查詢",
    "手机号查询": "手機號查詢",
    "保修卡号查询": "保修卡號查詢",
    "港澳地区凭证": "港澳地區憑證",
    "其他凭证": "其他憑證",
    "其他凭证上传（需总部确认）": "其他憑證上傳（需總部確認）",
    "全自动咖啡机或厨师机出仓日期保修": "全自動咖啡機或廚師機出倉日期保修",
    "出仓日期保修": "出倉日期保修",
    "海外机身号": "海外機身號",
    "海外机身号查询": "海外機身號查詢",
    "总部确认": "總部確認",
    "总部凭证确认": "總部憑證確認",
    "售前机": "售前機",
    "京东自营确认": "京東自營確認",
    "京东自营": "京東自營",
    "总部保修确认": "總部保修確認",
    "泵压式咖啡机EC系列": "泵壓式咖啡機EC系列",
    "自动咖啡机": "自動咖啡機",
    "咖啡机": "咖啡機",
    "厨师机": "廚師機",
    "抽湿机": "抽濕機",
    "榨汁机": "榨汁機",
    "吸尘器": "吸塵器",
    "暖风机": "暖風機",
    "磨豆机": "磨豆機",
    "面包机": "麪包機",
    "料理机": "料理機",
    "空气清新机": "空氣清新機",
    "空气净化器": "空氣淨化器",
    "搅拌机": "攪拌機",
    "加湿器": "加濕器",
    "多士炉": "多士爐",
    "电蒸锅": "電蒸鍋",
    "电炸锅": "電炸鍋",
    "电熨斗": "電熨斗",
    "电油汀": "電油汀",
    "电水壶": "電水壺",
    "电烤箱": "電烤箱",
    "电烤机": "電烤機",
    "电风扇": "電風扇",
    "电饭煲": "電飯煲",
    "冰淇淋机": "冰淇淋機",
    "冰激凌机": "冰激凌機",
    "高速切丝切片器": "高速切絲切片器",
    "谷物研磨器": "穀物研磨器",
    "果蔬压榨器": "果蔬壓榨器",
    "胶囊咖啡机": "膠囊咖啡機",
    "绞肉器": "絞肉器",
    "搅拌杯": "攪拌杯",
    "搅拌器": "攪拌器",
    "慢速切片/切丝器": "慢速切片/切絲器",
    "切面机": "切面機",
    "食物处理器": "食物處理器",
    "压面器": "壓面器",
    "研磨机": "研磨機",
    "榨橙汁器": "榨橙汁器",
    "制面器": "制面器",
    "真空罐": "真空罐",
    "电子称": "電子稱",
    "上门处理": "上門處理",
    "站点接修": "站點接修",
    "咨询产品使用": "諮詢產品使用",
    "咨询维修状况": "諮詢維修狀況",
    "咨询当地维修站或购买配件": "諮詢當地維修站或購買配件",
    "咨询产品潜在客户": "諮詢產品潛在客户",
    "投诉": "投訴",
    "称赞": "稱讚",
    "配件补发": "配件補發",
    "异地寄修": "異地寄修",
    "快递寄修": "快遞寄修",
    "省": "省",
    "市": "市",
    "详细地址": "詳細地址",
    "居住省份": "居住省份",
    "居住城市": "居住城市",
    "所在省份": "所在省份",
    "北京市": "北京市",
    "天津市": "天津市",
    "上海市": "上海市",
    "重庆市": "重慶市",
    "香港特别行政区": "香港特別行政區",
    "澳门特别行政区": "澳門特別行政區",
    "安徽省": "安徽省",
    "福建省": "福建省",
    "甘肃省": "甘肅省",
    "广东省": "廣東省",
    "广西壮族自治区": "廣西壯族自治區",
    "贵州省": "貴州省",
    "海南省": "海南省",
    "河北省": "河北省",
    "河南省": "河南省",
    "黑龙江省": "黑龍江省",
    "湖北省": "湖北省",
    "湖南省": "湖南省",
    "吉林省": "吉林省",
    "江苏省": "江蘇省",
    "江西省": "江西省",
    "辽宁省": "遼寧省",
    "内蒙古自治区": "內蒙古自治區",
    "宁夏回族自治区": "寧夏回族自治區",
    "青海省": "青海省",
    "山东省": "山東省",
    "山西省": "山西省",
    "陕西省": "陝西省",
    "四川省": "四川省",
    "西藏自治区": "西藏自治區",
    "新疆维吾尔自治区": "新疆維吾爾自治區",
    "云南省": "雲南省",
    "浙江省": "浙江省",
    "所在城市": "所在城市",
    "开县": "開縣",
    "綦江区": "綦江區",
    "南川区": "南川區",
    "长寿区": "長壽區",
    "涪陵区": "涪陵區",
    "黔江区": "黔江區",
    "铜梁区": "銅梁區",
    "北碚区": "北碚區",
    "大足区": "大足區",
    "璧山区": "璧山區",
    "合肥市": "合肥市",
    "芜湖市": "蕪湖市",
    "蚌埠市": "蚌埠市",
    "淮南市": "淮南市",
    "马鞍山市": "馬鞍山市",
    "淮北市": "淮北市",
    "铜陵市": "銅陵市",
    "宿州市": "宿州市",
    "安庆市": "安慶市",
    "滁州市": "滁州市",
    "阜阳市": "阜陽市",
    "巢湖市": "巢湖市",
    "六安市": "六安市",
    "池州市": "池州市",
    "宣城市": "宣城市",
    "黄山市": "黃山市",
    "亳州市": "亳州市",
    "桐城市": "桐城市",
    "天长市": "天長市",
    "明光市": "明光市",
    "宁国市": "寧國市",
    "广德县": "廣德縣",
    "蒙城县": "蒙城縣",
    "福州市": "福州市",
    "厦门市": "廈門市",
    "莆田市": "莆田市",
    "三明市": "三明市",
    "泉州市": "泉州市",
    "漳州市": "漳州市",
    "南平市": "南平市",
    "龙岩市": "龍巖市",
    "宁德市": "寧德市",
    "晋江市": "晉江市",
    "石狮市": "石獅市",
    "武夷山": "武夷山",
    "长乐市": "長樂市",
    "平潭县": "平潭縣",
    "兰州市": "蘭州市",
    "嘉峪关市": "嘉峪關市",
    "金昌市": "金昌市",
    "白银市": "白銀市",
    "天水市": "天水市",
    "武威市": "武威市",
    "张掖市": "張掖市",
    "平凉市": "平涼市",
    "酒泉市": "酒泉市",
    "庆阳市": "慶陽市",
    "定西市": "定西市",
    "临夏回族自治州": "臨夏回族自治州",
    "甘南藏族自治州": "甘南藏族自治州",
    "陇南市": "隴南市",
    "临夏市": "臨夏市",
    "广州市": "廣州市",
    "韶关市": "韶關市",
    "深圳市": "深圳市",
    "珠海市": "珠海市",
    "汕头市": "汕頭市",
    "佛山市": "佛山市",
    "江门市": "江門市",
    "湛江市": "湛江市",
    "茂名市": "茂名市",
    "肇庆市": "肇慶市",
    "惠州市": "惠州市",
    "梅州市": "梅州市",
    "汕尾市": "汕尾市",
    "河源市": "河源市",
    "阳江市": "陽江市",
    "清远市": "清遠市",
    "东莞市": "東莞市",
    "中山市": "中山市",
    "潮州市": "潮州市",
    "揭阳市": "揭陽市",
    "云浮市": "雲浮市",
    "南宁市": "南寧市",
    "柳州市": "柳州市",
    "桂林市": "桂林市",
    "梧州市": "梧州市",
    "北海市": "北海市",
    "防城港市": "防城港市",
    "钦州市": "欽州市",
    "贵港市": "貴港市",
    "玉林市": "玉林市",
    "百色市": "百色市",
    "贺州市": "賀州市",
    "河池市": "河池市",
    "来宾市": "來賓市",
    "崇左市": "崇左市",
    "龙胜市": "龍勝市",
    "阳朔市": "陽朔市",
    "贵阳市": "貴陽市",
    "六盘水市": "六盤水市",
    "遵义市": "遵義市",
    "安顺市": "安順市",
    "铜仁地区": "銅仁地區",
    "黔西南布依族苗族自治州": "黔西南布依族苗族自治州",
    "毕节地区": "畢節地區",
    "黔南布依族苗族自治州": "黔南布依族苗族自治州",
    "黔东南苗族侗族自治州": "黔東南苗族侗族自治州",
    "海口市": "海口市",
    "三亚市": "三亞市",
    "五指山市": "五指山市",
    "琼海市": "瓊海市",
    "儋州市": "儋州市",
    "文昌市": "文昌市",
    "万宁市": "萬寧市",
    "东方市": "東方市",
    "澄迈县": "澄邁縣",
    "定安县": "定安縣",
    "屯昌县": "屯昌縣",
    "临高县": "臨高縣",
    "保亭县": "保亭縣",
    "乐东县": "樂東縣",
    "琼中县": "瓊中縣",
    "白沙县": "白沙縣",
    "昌江县": "昌江縣",
    "陵水县": "陵水縣",
    "石家庄市": "石家莊市",
    "唐山市": "唐山市",
    "秦皇岛市": "秦皇島市",
    "邯郸市": "邯鄲市",
    "邢台市": "邢台市",
    "保定市": "保定市",
    "张家口市": "張家口市",
    "承德市": "承德市",
    "沧州市": "滄州市",
    "廊坊市": "廊坊市",
    "衡水市": "衡水市",
    "涿州市": "涿州市",
    "郑州市": "鄭州市",
    "开封市": "開封市",
    "洛阳市": "洛陽市",
    "平顶山市": "平頂山市",
    "安阳市": "安陽市",
    "鹤壁市": "鶴壁市",
    "新乡市": "新鄉市",
    "濮阳市": "濮陽市",
    "焦作市": "焦作市",
    "许昌市": "許昌市",
    "漯河市": "漯河市",
    "三门峡市": "三門峽市",
    "南阳市": "南陽市",
    "商丘市": "商丘市",
    "信阳市": "信陽市",
    "周口市": "周口市",
    "驻马店市": "駐馬店市",
    "济源市": "濟源市",
    "哈尔滨市": "哈爾濱市",
    "齐齐哈尔市": "齊齊哈爾市",
    "鸡西市": "雞西市",
    "鹤岗市": "鶴崗市",
    "双鸭山市": "雙鴨山市",
    "大庆市": "大慶市",
    "伊春市": "伊春市",
    "佳木斯市": "佳木斯市",
    "七台河市": "七台河市",
    "牡丹江市": "牡丹江市",
    "黑河市": "黑河市",
    "绥化市": "綏化市",
    "大兴安岭地区": "大興安嶺地區",
    "武汉市": "武漢市",
    "十堰市": "十堰市",
    "宜昌市": "宜昌市",
    "荆门市": "荊門市",
    "孝感市": "孝感市",
    "随州市": "隨州市",
    "襄阳市": "襄陽市",
    "咸宁市": "咸寧市",
    "荆州市": "荊州市",
    "黄冈市": "黃岡市",
    "仙桃市": "仙桃市",
    "鄂州市": "鄂州市",
    "恩施市": "恩施市",
    "长沙市": "長沙市",
    "株洲市": "株洲市",
    "湘潭市": "湘潭市",
    "衡阳市": "衡陽市",
    "邵阳市": "邵陽市",
    "岳阳市": "岳陽市",
    "益阳市": "益陽市",
    "郴州市": "郴州市",
    "永州市": "永州市",
    "怀化市": "懷化市",
    "娄底市": "婁底市",
    "湘西土家族苗族自治州": "湘西土家族苗族自治州",
    "常德市": "常德市",
    "张家界市": "張家界市",
    "凤凰市": "鳳凰市",
    "浏阳县": "瀏陽縣",
    "长春市": "長春市",
    "吉林市": "吉林市",
    "四平市": "四平市",
    "辽源市": "遼源市",
    "通化市": "通化市",
    "白山市": "白山市",
    "白城市": "白城市",
    "延边朝鲜族自治州": "延邊朝鮮族自治州",
    "松原市": "松原市",
    "南京市": "南京市",
    "无锡市": "無錫市",
    "徐州市": "徐州市",
    "常州市": "常州市",
    "苏州市": "蘇州市",
    "南通市": "南通市",
    "连云港市": "連雲港市",
    "淮安市": "淮安市",
    "盐城市": "鹽城市",
    "扬州市": "揚州市",
    "镇江市": "鎮江市",
    "泰州市": "泰州市",
    "宿迁市": "宿遷市",
    "昆山市": "崑山市",
    "吴江市": "吳江市",
    "常熟市": "常熟市",
    "江阴市": "江陰市",
    "仪征市": "儀徵市",
    "宜兴市": "宜興市",
    "张家港市": "張家港市",
    "太仓市": "太倉市",
    "南昌市": "南昌市",
    "新余市": "新餘市",
    "鹰潭市": "鷹潭市",
    "赣州市": "贛州市",
    "吉安市": "吉安市",
    "宜春市": "宜春市",
    "抚州市": "撫州市",
    "上饶市": "上饒市",
    "景德镇市": "景德鎮市",
    "萍乡市": "萍鄉市",
    "九江市": "九江市",
    "婺源县": "婺源縣",
    "沈阳市": "瀋陽市",
    "大连市": "大連市",
    "鞍山市": "鞍山市",
    "抚顺市": "撫順市",
    "本溪市": "本溪市",
    "丹东市": "丹東市",
    "锦州市": "錦州市",
    "营口市": "營口市",
    "阜新市": "阜新市",
    "辽阳市": "遼陽市",
    "盘锦市": "盤錦市",
    "铁岭市": "鐵嶺市",
    "朝阳市": "朝陽市",
    "葫芦岛市": "葫蘆島市",
    "呼和浩特市": "呼和浩特市",
    "包头市": "包頭市",
    "乌海市": "烏海市",
    "赤峰市": "赤峯市",
    "通辽市": "通遼市",
    "鄂尔多斯市": "鄂爾多斯市",
    "呼伦贝尔市": "呼倫貝爾市",
    "巴彦淖尔市": "巴彥淖爾市",
    "乌兰察布市": "烏蘭察布市",
    "兴安盟": "興安盟",
    "锡林郭勒盟": "錫林郭勒盟",
    "阿拉善盟": "阿拉善盟",
    "满洲里市": "滿洲里市",
    "银川市": "銀川市",
    "石嘴山市": "石嘴山市",
    "吴忠市": "吳忠市",
    "固原市": "固原市",
    "中卫市": "中衞市",
    "西宁市": "西寧市",
    "海东地区": "海東地區",
    "海北藏族自治州": "海北藏族自治州",
    "黄南藏族自治州": "黃南藏族自治州",
    "果洛藏族自治州": "果洛藏族自治州",
    "玉树藏族自治州": "玉樹藏族自治州",
    "海西蒙古族藏族自治州": "海西蒙古族藏族自治州",
    "海南藏族自治州": "海南藏族自治州",
    "济南市": "濟南市",
    "青岛市": "青島市",
    "淄博市": "淄博市",
    "枣庄市": "棗莊市",
    "东营市": "東營市",
    "烟台市": "煙台市",
    "潍坊市": "濰坊市",
    "济宁市": "濟寧市",
    "泰安市": "泰安市",
    "威海市": "威海市",
    "日照市": "日照市",
    "莱芜市": "萊蕪市",
    "临沂市": "臨沂市",
    "德州市": "德州市",
    "聊城市": "聊城市",
    "滨州市": "濱州市",
    "菏泽市": "菏澤市",
    "安丘市": "安丘市",
    "滕州市": "滕州市",
    "太原市": "太原市",
    "大同市": "大同市",
    "阳泉市": "陽泉市",
    "长治市": "長治市",
    "晋城市": "晉城市",
    "朔州市": "朔州市",
    "晋中市": "晉中市",
    "运城市": "運城市",
    "忻州市": "忻州市",
    "临汾市": "臨汾市",
    "吕梁市": "呂梁市",
    "古交市": "古交市",
    "清徐县": "清徐縣",
    "西安市": "西安市",
    "铜川市": "銅川市",
    "宝鸡市": "寶雞市",
    "咸阳市": "咸陽市",
    "渭南市": "渭南市",
    "延安市": "延安市",
    "汉中市": "漢中市",
    "榆林市": "榆林市",
    "安康市": "安康市",
    "商洛市": "商洛市",
    "杨陵区": "楊陵區",
    "成都市": "成都市",
    "自贡市": "自貢市",
    "攀枝花市": "攀枝花市",
    "泸州市": "瀘州市",
    "德阳市": "德陽市",
    "绵阳市": "綿陽市",
    "遂宁市": "遂寧市",
    "南充市": "南充市",
    "宜宾市": "宜賓市",
    "达州市": "達州市",
    "巴中市": "巴中市",
    "阿坝藏族羌族自治州": "阿壩藏族羌族自治州",
    "凉山彝族自治州": "涼山彝族自治州",
    "广元市": "廣元市",
    "内江市": "內江市",
    "乐山市": "樂山市",
    "眉山市": "眉山市",
    "广安市": "廣安市",
    "雅安市": "雅安市",
    "资阳市": "資陽市",
    "甘孜藏族自治州": "甘孜藏族自治州",
    "九寨沟": "九寨溝",
    "万州区": "萬州區",
    "永川区": "永川區",
    "江津区": "江津區",
    "合川区": "合川區",
    "绵竹市": "綿竹市",
    "南部县": "南部縣",
    "阆中市": "閬中市",
    "江油县": "江油縣",
    "什邡市": "什邡市",
    "广汉市": "廣漢市",
    "拉萨市": "拉薩市",
    "昌都地区": "昌都地區",
    "山南地区": "山南地區",
    "日喀则地区": "日喀則地區",
    "那曲地区": "那曲地區",
    "阿里地区": "阿里地區",
    "林芝地区": "林芝地區",
    "乌鲁木齐市": "烏魯木齊市",
    "克拉玛依市": "克拉瑪依市",
    "吐鲁番地区": "吐魯番地區",
    "哈密地区": "哈密地區",
    "昌吉回族自治州": "昌吉回族自治州",
    "伊犁哈萨克自治州": "伊犁哈薩克自治州",
    "塔城地区": "塔城地區",
    "博尔塔拉蒙古自治州": "博爾塔拉蒙古自治州",
    "巴音郭楞蒙古自治州": "巴音郭楞蒙古自治州",
    "阿克苏地区": "阿克蘇地區",
    "克孜勒苏柯尔克孜自治州": "克孜勒蘇柯爾克孜自治州",
    "喀什地区": "喀什地區",
    "和田地区": "和田地區",
    "阿勒泰地区": "阿勒泰地區",
    "石河子市": "石河子市",
    "阜康市": "阜康市",
    "奎屯市": "奎屯市",
    "独山子区": "獨山子區",
    "库尔勒市": "庫爾勒市",
    "乌苏市": "烏蘇市",
    "昆明市": "昆明市",
    "曲靖市": "曲靖市",
    "玉溪市": "玉溪市",
    "保山市": "保山市",
    "昭通市": "昭通市",
    "丽江市": "麗江市",
    "临沧市": "臨滄市",
    "楚雄彝族自治州": "楚雄彝族自治州",
    "红河哈尼族彝族自治州": "紅河哈尼族彝族自治州",
    "文山壮族苗族自治州": "文山壯族苗族自治州",
    "西双版纳傣族自治州": "西雙版納傣族自治州",
    "大理白族自治州": "大理白族自治州",
    "怒江傈僳族自治州": "怒江傈僳族自治州",
    "迪庆藏族自治州": "迪慶藏族自治州",
    "普洱市": "普洱市",
    "德宏傣族景颇族自治州": "德宏傣族景頗族自治州",
    "杭州市": "杭州市",
    "宁波市": "寧波市",
    "温州市": "温州市",
    "嘉兴市": "嘉興市",
    "湖州市": "湖州市",
    "绍兴市": "紹興市",
    "金华市": "金華市",
    "舟山市": "舟山市",
    "台州市": "台州市",
    "丽水市": "麗水市",
    "衢州市": "衢州市",
    "慈溪市": "慈溪市",
    "义乌市": "義烏市",
    "象山城": "象山城",
    "乌镇市": "烏鎮市",
    "瑞安市": "瑞安市",
    "乐清市": "樂清市",
    "余姚市": "餘姚市",
    "长兴市": "長興市",
    "德清市": "德清市",
    "安吉市": "安吉市",
    "永康市": "永康市",
    "兰溪市": "蘭溪市",
    "东阳市": "東陽市",
    "海宁市": "海寧市",
    "平湖市": "平湖市",
    "平阳县": "平陽縣",
    "苍南县": "蒼南縣",
    "其他": "其他",
    "400回访查询": "400回訪查詢",
    "系统日志": "系統日誌",
    "维修服务单": "維修服務單",
    "处理进度：": "處理進度：",
    "处理进度": "處理進度",
    "维修单数据导出失败，后台发生错误。": "維修單數據導出失敗，後台發生錯誤。",
    "维修单获取失败": "維修單獲取失敗",
    "请选择需要修改的维修单数据": "請選擇需要修改的維修單數據",
    "删除维修单": "刪除維修單",
    "确定要删除维修单【{{:a}}】吗？": "确定要删除维修单【{{:a}}】吗？",
    "请选择要删除的维修单": "請選擇要刪除的維修單",
    "请选择需要导入的文件": "請選擇需要導入的文件",
    "请选择Excel文件": "請選擇Excel文件",
    "批量上传成功": "批量上傳成功",
    "批量上传失败": "批量上傳失敗",
    "工单批量上传出错": "工單批量上傳出錯",
    "{{:a}}等工单信息导入出错": "{{:a}} 等工單信息導入出錯",
    "未选中可结账的维修单": "未選中可結賬的維修單",
    "请选择要作废的维修单": "請選擇要作廢的維修單",
    "操作失败，请稍候再试": "操作失敗，請稍候再試",
    "检查网络连接，请稍候再试": "檢查網絡連接，請稍候再試",
    "创建日期": "創建日期",
    "创建人": "創建人",
    "状态": "狀態",
    "回访": "回訪",
    "未回访": "未回訪",
    "已回访": "已回訪",
    "已完成": "已完成",
    "接单": "接單",
    "已派单": "已派單",
    "已预约上门": "已預約上門",
    "维修完成": "維修完成",
    "已通知取货": "已通知取貨",
    "用户已取货": "用户已取貨",
    "维修站已回访": "維修站已回訪",
    "总部已回访": "總部已回訪",
    "待结账审核": "待結賬審核",
    "待结账审批": "待結賬審批",
    "已批准结账": "已批准結賬",
    "已作废": "已作廢",
    "已批准": "已批准",
    "已开票": "已開票",
    "已报销": "已報銷",
    "已否决": "已否決",
    "已审核": "已審核",
    "申请中": "申請中",
    "取消维修站回访": "取消維修站回訪",
    "取消总部回访": "取消總部回訪",
    "提交主站审核": "提交主站審核",
    "退回修改": "退回修改",
    "公司已开票": "公司已開票",
    "经销商已报销": "經銷商已報銷",
    "申请作废": "申請作廢",
    "待确认": "待確認",
    "取消作废申请": "取消作廢申請",
    "保存维修单": "保存維修單",
    "派单处理": "派單處理",
    "预约上门": "預約上門",
    "通知取货": "通知取貨",
    "用户取货": "用户取貨",
    "待继续回访": "待繼續回訪",
    "完成回访": "完成回訪",
    "申请结账": "申請結賬",
    "审核结账": "審核結賬",
    "批准结账": "批准結賬",
    "否决处理": "否決處理",
    "作废处理": "作廢處理",
    "开票确认": "開票確認",
    "报销确认": "報銷確認",
    "主站审核": "主站審核",
    "审核": "審核",
    "批准": "批准",
    "否决": "否決",
    "结账单": "結賬單",
    "结账单编号": "結賬單編號",
    "结账单状态": "結賬單狀態",
    "派单时间": "派單時間",
    "上门日期": "上門日期",
    "完成日期": "完成日期",
    "是否保修": "是否保修",
    "维修性质": "維修性質",
    "维修费": "維修費",
    "维修完成时间": "維修完成時間",
    "SAP系统单号": "SAP系統單號",
    "操作": "操作",
    "作废原因": "作廢原因",
    "重复单号：": "重複單號：",
    "说明：": "説明：",
    "说明": "説明",
    "保修依据时间": "保修依據時間",
    "超时查询": "超時查詢",
    "预约时间超时": "預約時間超時",
    "上门服务超时": "上門服務超時",
    "维修完成超时": "維修完成超時",
    "维修站回访超时": "維修站回訪超時",
    "总部回访超时": "總部回訪超時",
    "客户电话": "客户電話",
    "各维修站": "各維修站",
    "待作废": "待作廢",
    "批量作废": "批量作廢",
    "待审批": "待審批",
    "待分配": "待分配",
    "待维修": "待維修",
    "待回访": "待回訪",
    "待预约": "待預約",
    "待上门": "待上門",
    "待通知取货": "待通知取貨",
    "待取货": "待取貨",
    "待结账": "待結賬",
    "待顾客寄出": "待顧客寄出",
    "取货待维修": "取貨待維修",
    "待顾客送修": "待顧客送修",
    "结账处理": "結賬處理",
    "高级查询": "高級查詢",
    "手动修改状态": "手動修改狀態",
    "批量上传": "批量上傳",
    "文件": "文件",
    "浏览": "瀏覽",
    "导出数据到SAP": "導出數據到SAP",
    "导出": "導出",
    "开始日期": "開始日期",
    "结束日期": "結束日期",
    "请输入处理意见": "請輸入處理意見",
    "过滤条件": "過濾條件",
    "查询": "查詢",
    "复位": "復位",
    "用户姓名": "用户姓名",
    "接单日期": "接單日期",
    "公司名称": "公司名稱",
    "移动电话": "移動電話",
    "固定电话": "固定電話",
    "按": "按",
    "过滤": "過濾",
    "开始时间": "開始時間",
    "结束时间": "結束時間",
    "工作单号": "工作單號",
    "请选择站点": "請選擇站點",
    "接修人": "接修人",
    "YYYY年MM月": "YYYY年MM月",
    "YYYY年MM月DD日": "YYYY年MM月DD日",
    "YYYY年MM月DD日 HH:mm": "YYYY年MM月DD日 HH:mm",
    "YYYY年MM月DD日 HH:mm:ss": "YYYY年MM月DD日 HH:mm:ss",
    "该选项保修一年": "該選項保修一年",
    "该选项保修两年": "該選項保修兩年",
    "请选择实际完成日期": "請選擇實際完成日期",
    "请选择预约上门日期": "請選擇預約上門日期",
    "实际完成时间": "實際完成時間",
    "预约上门日期": "預約上門日期",
    "完成回访日期": "完成回訪日期",
    "请选择维修站": "請選擇維修站",
    "回访注释：未成功接通电话，请点击下方待继续回访；每个工单最多能被回访三次产品。": "回訪註釋：未成功接通電話，請點擊下方待繼續回訪；每個工單最多能被回訪三次產品。",
    "成功接通后被挂断或不愿评分请填0": "成功接通後被掛斷或不願評分請填0",
    "前四项，评分范围1-10。": "前四項，評分範圍1-10。",
    "推荐值，评分范围1-10。": "推薦值，評分範圍1-10。",
    "正确": "正確",
    "缺地线": "缺地線",
    "缺零线": "缺零線",
    "缺火线": "缺火線",
    "火地线错位": "火地線錯位",
    "火零线错位": "火零線錯位",
    "相地错并缺地": "相地錯並缺地",
    "国内销售": "國內銷售",
    "海外旗舰店": "海外旗艦店",
    "海外分销商": "海外分銷商",
    "港澳销售": "港澳銷售",
    "海外水货": "海外水貨",
    "行货过保-过保修期": "行貨過保-過保修期",
    "行货过保-保内非质量问题": "行貨過保-保內非質量問題",
    "行货过保-购买配件": "行貨過保-購買配件",
    "奖励": "獎勵",
    "处罚": "處罰",
    "惩罚": "懲罰",
    "顾客来电表扬": "顧客來電錶揚",
    "特殊贡献奖": "特殊貢獻獎",
    "工单延误": "工單延誤",
    "服务态度": "服務態度",
    "维修质量": "維修質量",
    "重复建单": "重複建單",
    "顾客取消/无时间": "顧客取消/無時間",
    "顾客取消/无时间/无法联系上顾客": "顧客取消/無時間/無法聯系上顧客",
    "顾客自行解决": "顧客自行解決",
    "订单编号：": "訂單編號：",
    "订单编号": "訂單編號",
    "订单号": "訂單號",
    "手机号：": "手機號：",
    "手机号": "手機號",
    "购买型号：": "購買型號：",
    "购买数量：": "購買數量：",
    "购买平台：": "購買平台：",
    "购买时间：": "購買時間：",
    "购买时间": "購買時間",
    "注册时间：": "註冊時間：",
    "注册时间": "註冊時間",
    "失效时间": "失效時間",
    "凭证失效时间": "憑證失效時間",
    "购买型号": "購買型號",
    "购买数量": "購買數量",
    "购买平台": "購買平台",
    "请输入搜索条件": "請輸入搜索條件",
    "获取数据失败": "獲取數據失敗",
    "请选择服务类型!": "請選擇服務類型!",
    "请填写用户姓名!": "請填寫用户姓名!",
    "请填写用户联系电话!": "請填寫用户聯繫電話!",
    "请选择用户居住省份!": "請選擇用户居住省份!",
    "请选择品牌!": "請選擇品牌!",
    "请填写预计完成日期!": "請填寫預計完成日期!",
    "请填写故障描述!": "請填寫故障描述!",
    "请填写机器型号!": "請填寫機器型號!",
    "请填写有效的机器型号!": "請填寫有效的機器型號!",
    "请选择机器名称!": "請選擇機器名稱!",
    "请填写随机附件!": "請填寫隨機附件!",
    "请填写用户联系地址!": "請填寫用户聯繫地址!",
    "请填写颜色": "請填寫顏色",
    "请选择是否送货到家": "請選擇是否送貨到家",
    "请选择产品性质!": "請選擇產品性質!",
    "请选择保修依据!": "請選擇保修依據!",
    "请选择正确的保修依据!": "請選擇正確的保修依據!",
    "请选择维修类型!": "請選擇維修類型!",
    "请填写检查记录!": "請填寫檢查記錄!",
    "请填写维修记录!": "請填寫維修記錄!",
    "请填写服务单编号!": "請填寫服務單編號!",
    "请填写7位服务单编号!": "請填寫7位服務單編號!",
    "请填写上门公里数!": "請填寫上門公里數!",
    "请填写上门费用!": "請填寫上門費用!",
    "上门费用超过标准!": "上門費用超過標準!",
    "请填写机器编号! (需17-19位)": "請填寫機器編號! (需17-19位)",
    "请填写18位机器编号!": "請填寫18位機器編號!",
    "请填写19位机器编号!": "請填寫19位機器編號!",
    "请填写制作杯数!": "請填寫製作杯數!",
    "请填写除垢次数!": "請填寫除垢次數!",
    "请填写使用水量!": "請填寫使用水量!",
    "请填写蒸汽次数!": "請填寫蒸汽次數!",
    "请填写蒸汽水量!": "請填寫蒸汽水量!",
    "请填写咖啡水升数!": "請填寫咖啡水升數!",
    "请填写蒸汽水升数!": "請填寫蒸汽水升數!",
    "请填写热水升数!": "請填寫熱水升數!",
    "请填写饮品总杯数!": "請填寫飲品總杯數!",
    "请填写饮品总升数!": "請填寫飲品總升數!",
    "请填写机器编号!": "請填寫機器編號!",
    "请填写完整的零件信息!": "請填寫完整的零件信息!",
    "不是有效的零件编码!": "不是有效的零件編碼!",
    "请添加零件信息!": "請添加零件信息!",
    "请填写维修费用!": "請填寫維修費用!",
    "请选择延保数据!": "請選擇延保數據!",
    "请填写其它费用明细!": "請填寫其它費用明細!",
    "请填写其它费用!": "請填寫其它費用!",
    "请上传机身编号": "請上傳機身編號",
    "请上传插座检查照片": "請上傳插座檢查照片",
    "请上传纸质工单照片": "請上傳紙質工單照片",
    "请选择插座故障": "請選擇插座故障",
    "需总部确认": "需總部確認",
    "请选择保修日期": "請選擇保修日期",
    "请上传保修凭证": "請上傳保修憑證",
    "保修凭证": "保修憑證",
    "请输入开票单位": "請輸入開票單位",
    "请上传订单照片": "請上傳訂單照片",
    "请输入保修订单信息": "請輸入保修訂單信息",
    "请输入单号": "請輸入單號",
    "发票日期不符合": "發票日期不符合",
    "订单日期不符合": "訂單日期不符合",
    "出仓日期不符合": "出倉日期不符合",
    "请选择故障分类": "請選擇故障分類",
    "请选择完成日期": "請選擇完成日期",
    "完成日期早于接单日期!": "完成日期早於接單日期!",
    "请选择预约上门时间": "請選擇預約上門時間",
    "请填写产品推荐值评分!": "請填寫產品推薦值評分!",
    "请填写产品满意度评分!": "請填寫產品滿意度評分!",
    "请填写售后服务评分!": "請填寫售後服務評分!",
    "请填写维修速度评分!": "請填寫維修速度評分!",
    "请填写维修质量评分!": "請填寫維修質量評分!",
    "请选择作废理由!": "請選擇作廢理由!",
    "请填写重复单号!": "請填寫重複單號!",
    "请检查重复单号格式!": "請檢查重複單號格式!",
    "重复单号不能与当前系统单号相同!": "重複單號不能與當前系統單號相同!",
    "请填写作废说明!": "請填寫作廢説明!",
    "预约上门时间发生变化": "預約上門時間發生變化",
    "改到": "改到",
    "工单数据已变更，请先获取最新工单": "工單數據已變更，請先獲取最新工單",
    "请选择一条订单": "請選擇一條訂單",
    "订单时间(请设置单元格格式，分类为期，类型为yyyy/mm/dd)": "訂單時間(請設置單元格格式，分類為期，類型為yyyy/mm/dd)",
    "保修卡号": "保修卡號",
    "姓名": "姓名",
    "公司": "公司",
    "电话": "電話",
    "性质": "性質",
    "机器名称": "機器名稱",
    "接单时间": "接單時間",
    "地址": "地址",
    "只能输入 {{:a}}": "只能輸入 {{:a}}",
    "只能输入": "只能輸入",
    "只能输入数字": "只能輸入數字",
    "只能输入英文字母或数字": "只能輸入英文字母或數字",
    "点击查看": "點擊查看",
    "搜索": "搜索",
    "确认人": "確認人",
    "开票单位：": "開票單位：",
    "开票单位": "開票單位",
    "回访内容": "回訪內容",
    "现在机器能正常工作吗？还有什么可以为您服务吗？": "現在機器能正常工作嗎？還有什麼可以為您服務嗎？",
    "评分：": "評分：",
    "评分": "評分",
    "维修速度": "維修速度",
    "机器修了多长时间？维修速度满意吗？满分10分可以打多少分？": "機器修了多長時間？維修速度滿意嗎？滿分10分可以打多少分？",
    "售后服务": "售後服務",
    "维修师傅在维修过程服务态度还满意吗？10分满分可以打多少分？": "維修師傅在維修過程服務態度還滿意嗎？10分滿分可以打多少分？",
    "产品满意度": "產品滿意度",
    "机器使用到现在对产品还满意吗？满分10分可以打多少？": "機器使用到現在對產品還滿意嗎？滿分10分可以打多少？",
    "产品推荐值": "產品推薦值",
    "如果要介绍给朋友，10分一定会推荐，1分不会推荐，您可以打多少分？": "如果要介紹給朋友，10分一定會推薦，1分不會推薦，您可以打多少分？",
    "提交OA": "提交OA",
    "指派维修站": "指派維修站",
    "取货确认": "取貨確認",
    "总部作废": "總部作廢",
    "选择订单": "選擇訂單",
    "选择": "選擇",
    "请选择用户信息": "請選擇用户信息",
    "用户信息": "用户信息",
    "全部信息": "全部信息",
    "电话号码": "電話號碼",
    "作废理由": "作廢理由",
    "重复单号": "重複單號",
    "作废说明": "作廢説明",
    "显示第{{:a}}到第{{:b}}条记录，总共{{:c}}条记录": "显示第{{:a}}到第{{:b}}条记录，总共{{:c}}条记录",
    "很粗": "很粗",
    "略粗": "略粗",
    "正常": "正常",
    "略细": "略細",
    "很细": "很細",
    "不成形": "不成形",
    "略松散": "略鬆散",
    "成形": "成形",
    "市场部主任": "市場部主任",
    "产品经理": "產品經理",
    "市场营销部助理": "市場營銷部助理",
    "市场营销部经理": "市場營銷部經理",
    "上班": "上班",
    "出差": "出差",
    "休息": "休息",
    "病假": "病假",
    "事假": "事假",
    "婚假": "婚假",
    "产假": "產假",
    "丧假": "喪假",
    "年假": "年假",
    "其它": "其它",
    "展览用": "展覽用",
    "测试用": "測試用",
    "免费": "免費",
    "收费": "收費",
    "新增潜在用户": "新增潛在用户",
    "单次个人用户": "單次個人用户",
    "单次公司用户": "單次公司用户",
    "合同用户": "合同用户",
    "优惠券用户": "優惠券用户",
    "微信小程序用户": "微信小程序用户",
    "维修站主管": "維修站主管",
    "维修部助理": "維修部助理",
    "维修部经理": "維修部經理",
    "系统管理员": "系統管理員",
    "数据查询员": "數據查詢員",
    "旧件盘点员": "舊件盤點員",
    "订单数据导入员": "訂單數據導入員",
    "京东": "京東",
    "亚马逊": "亞馬遜",
    "我买网": "我買網",
    "苏宁易购": "蘇寧易購",
    "国美在线": "國美在線",
    "一号店": "一號店",
    "淘宝": "淘寶",
    "电视购物": "電視購物",
    "上海技嘉咖啡有限公司": "上海技嘉咖啡有限公司",
    "浙江速网电子商务有限公司": "浙江速網電子商務有限公司",
    "上海埃普咖啡有限公司": "上海埃普咖啡有限公司",
    "南京易佰购电子科技有限公司": "南京易佰購電子科技有限公司",
    "南京美富隆贸易有限公司": "南京美富隆貿易有限公司",
    "上海创龙贸易有限公司": "上海創龍貿易有限公司",
    "北京融欣伟业科技发展有限公司": "北京融欣偉業科技發展有限公司",
    "北京和美佳业经贸有限公司": "北京和美佳業經貿有限公司",
    "北京京东世纪信息技术有限公司": "北京京東世紀信息技術有限公司",
    "江苏圆周电子商务有限公司上海分公司": "江蘇圓周電子商務有限公司上海分公司",
    "广州晶东贸易有限公司": "廣州晶東貿易有限公司",
    "上海圆迈贸易有限公司": "上海圓邁貿易有限公司",
    "成都京东世纪贸易有限公司": "成都京東世紀貿易有限公司",
    "武汉京东世纪贸易有限公司": "武漢京東世紀貿易有限公司",
    "昆山京东尚信贸易有限公司": "崑山京東尚信貿易有限公司",
    "北京京东信息技术有限公司": "北京京東信息技術有限公司",
    "沈阳京东世纪贸易有限公司": "瀋陽京東世紀貿易有限公司",
    "西安华讯得贸易有限公司": "西安華訊得貿易有限公司",
    "北京世纪卓越信息技术有限公司": "北京世紀卓越信息技術有限公司",
    "中粮海优（北京）有限公司": "中糧海優（北京）有限公司",
    "南京亿飞科技有限公司": "南京億飛科技有限公司",
    "南京宜美阁网络科技有限公司": "南京宜美閣網絡科技有限公司",
    "南京睿嘉融信电子科技有限公司": "南京睿嘉融信電子科技有限公司",
    "南京易柏通商贸有限责任公司": "南京易柏通商貿有限責任公司",
    "纽海电子商务（上海）有限公司": "紐海電子商務（上海）有限公司",
    "上海伊维萨国际贸易有限公司": "上海伊維薩國際貿易有限公司",
    "上海优骐商贸有限公司": "上海優騏商貿有限公司",
    "上海舒阳贸易有限公司": "上海舒陽貿易有限公司",
    "上海迪慧经贸有限公司": "上海迪慧經貿有限公司",
    "上海浦美电器有限公司": "上海浦美電器有限公司",
    "上海娄江经贸有限公司": "上海婁江經貿有限公司",
    "上海广龙家电有限公司": "上海廣龍家電有限公司",
    "城市": "城市",
    "省份": "省份",
    "请选择开始日期和结束日期": "請選擇開始日期和結束日期",
    "请先统计数据": "請先統計數據",
    "城市维修量统计": "城市維修量統計",
    "统计": "統計",
    "工单时效完成": "工單時效完成",
    "工单时效未完成": "工單時效未完成",
    "城市维修量": "城市維修量",
    "维修站维修费": "維修站維修費",
    "海外维修费用": "海外維修費用",
    "国内维修费用": "國內維修費用",
    "总计": "總計",
    "海外维修费用统计": "海外維修費用統計",
    "国内维修费用统计": "國內維修費用統計",
    "零件费用": "零件費用",
    "其它费用": "其它費用",
    "费用合计": "費用合計",
    "上门费用汇总": "上門費用匯總",
    "零件费用汇总": "零件費用匯總",
    "其它费用汇总": "其它費用匯總",
    "维修费用汇总": "維修費用匯總",
    "维修站维修费统计": "維修站維修費統計",
    "工单数": "工單數",
    "维修费百分比": "維修費百分比",
    "汇总": "彙總",
    "工单时效统计": "工單時效統計",
    "工单时效统计完成": "工單時效統計完成",
    "工单时效[完成]": "工單時效[完成]",
    "工单时效统计[完成]": "工單時效統計[完成]",
    "工单时效统计未完成": "工單時效統計未完成",
    "工单时效[未完成]": "工單時效[未完成]",
    "工单时效统计[未完成]": "工單時效統計[未完成]",
    "当天内维修完成": "當天內維修完成",
    "当天内": "當天內",
    "3天内": "3天內",
    "7天内": "7天內",
    "14天内": "14天內",
    "30天内": "30天內",
    "超过30天": "超過30天",
    "品类选择": "品類選擇",
    "全部工单": "全部工單",
    "仅自动咖啡机": "僅自動咖啡機",
    "不包含自动咖啡机": "不包含自動咖啡機",
    "请选择类型": "請選擇類型",
    "全部": "全部",
    "加载失败，请重试": "加載失敗，請重試",
    "结账单获取失败": "結賬單獲取失敗",
    "请选择结账单": "請選擇結賬單",
    "请稍候再试": "請稍候再試",
    "手动改指定结账单": "手動改指定結賬單",
    "结账单加载中...": "結賬單加載中...",
    "无可结账的维修单，请刷新维修单列表后重试": "無可結賬的維修單，請刷新維修單列表後重試",
    "导出更换整机": "導出更換整機",
    "导出旧件数": "導出舊件數",
    "结算单配件统计": "結算單配件統計",
    "提交": "提交",
    "修改金额": "修改金額",
    "结账申请单": "結賬申請單",
    "创建人：": "創建人：",
    "创建日期：": "創建日期：",
    "型号": "型號",
    "上门费": "上門費",
    "零件费": "零件費",
    "其他费": "其他費",
    "增减费": "增減費",
    "奖惩费": "獎懲費",
    "小计": "小計",
    "否决所选维修单": "否決所選維修單",
    "总数：": "總數：",
    "总数": "總數",
    "处理中，请稍候...": "處理中，請稍候...",
    "请选择需要修改的产品信息": "請選擇需要修改的產品信息",
    "产品信息维护": "產品信息維護",
    "回访完成日期": "回訪完成日期",
    "回访次数": "回訪次數",
    "回访日期": "回訪日期",
    "回访时间": "回訪時間",
    "回访人员": "回訪人員",
    "负责清理的工程师是否主动出示授权服务工作卡": "負責清理的工程師是否主動出示授權服務工作卡",
    "清洁前是否主动介绍清洁工具和物料": "清潔前是否主動介紹清潔工具和物料",
    "清洁部件是否有展示照片或则现场展示": "清潔部件是否有展示照片或則現場展示",
    "清洁结束后是否有展示清洁前后的部件照片": "清潔結束後是否有展示清潔前後的部件照片",
    "清洁结束后是否重新调试咖啡机，并征询客户是否为原始设置": "清潔結束後是否重新調試咖啡機，並徵詢客户是否為原始設置",
    "工程师离开时，是否将清洁咖啡机的残留物清洁干净，并带离": "工程師離開時，是否將清潔咖啡機的殘留物清潔乾淨，並帶離",
    "请选择用户类型!": "請選擇用户類型!",
    "请选择下次推广时间!": "請選擇下次推廣時間!",
    "下次推广时间不能早于今天!": "下次推廣時間不能早於今天!",
    "请填写电话记录!": "請填寫電話記錄!",
    "请填写推广人员!": "請填寫推廣人員!",
    "请选择保养类型!": "請選擇保養類型!",
    "请选择保修类型!": "請選擇保修類型!",
    "请选择服务性质!": "請選擇服務性質!",
    "请填写保养记录!": "請填寫保養記錄!",
    "请上传机身编号!": "請上傳機身編號!",
    "请上传清洁物料照片!": "請上傳清潔物料照片!",
    "请上传萃取器清洁前照片!": "請上傳萃取器清潔前照片!",
    "请上传萃取器清洁后照片!": "請上傳萃取器清潔後照片!",
    "请上传磨豆机清洁前照片!": "請上傳磨豆機清潔前照片!",
    "请上传磨豆机清洁后照片!": "請上傳磨豆機清潔後照片!",
    "请上传压粉机清洁前照片!": "請上傳壓粉機清潔前照片!",
    "请上传压粉机清洁后照片!": "請上傳壓粉機清潔後照片!",
    "请上传咖啡流口清洁前照片!": "請上傳咖啡流口清潔前照片!",
    "请上传咖啡流口清洁后照片!": "請上傳咖啡流口清潔後照片!",
    "请上传仪器安全检查清洁前照片!": "請上傳儀器安全檢查清潔前照片!",
    "请上传仪器安全检查清洁后照片!": "請上傳儀器安全檢查清潔後照片!",
    "请上传粉饼厚度清洁前照片!": "請上傳粉餅厚度清潔前照片!",
    "请上传粉饼厚度清洁后照片!": "請上傳粉餅厚度清潔後照片!",
    "请选择清洁前的咖啡粉粗细!": "請選擇清潔前的咖啡粉粗細!",
    "请选择清洁后的咖啡粉粗细!": "請選擇清潔後的咖啡粉粗細!",
    "请选择清洁前的粉饼松散度!": "請選擇清潔前的粉餅鬆散度!",
    "请选择清洁后的粉饼松散度!": "請選擇清潔後的粉餅鬆散度!",
    "请选择下次预计保养时间!": "請選擇下次預計保養時間!",
    "下次预计保养时间不能早于今天!": "下次預計保養時間不能早於今天!",
    "请选择工程师!": "請選擇工程師!",
    "请填写合同号!": "請填寫合同號!",
    "请填写有效期!": "請填寫有效期!",
    "请填写剩余次数!": "請填寫剩餘次數!",
    "下次预计保养时间不能早于今天": "下次預計保養時間不能早於今天",
    "保养服务工作单": "保養服務工作單",
    "用户类型：": "用户類型：",
    "上门": "上門",
    "接修": "接修",
    "推广人员：": "推廣人員：",
    "推广人员": "推廣人員",
    "下次推广时间：": "下次推廣時間：",
    "下次推广时间": "下次推廣時間",
    "电话记录：": "電話記錄：",
    "保养类型：": "保養類型：",
    "A类": "A類",
    "B类": "B類",
    "工程师：": "工程師：",
    "工程师": "工程師",
    "保养记录：": "保養記錄：",
    "保养记录": "保養記錄",
    "合同号：": "合同號：",
    "有效期：": "有效期：",
    "有效期": "有效期",
    "剩余次数：": "剩餘次數：",
    "剩余次数": "剩餘次數",
    "清洁物料：": "清潔物料：",
    "清洁物料": "清潔物料",
    "清洁前": "清潔前",
    "清洁后": "清潔後",
    "清理前": "清理前",
    "清理后": "清理後",
    "萃取器：": "萃取器：",
    "萃取器": "萃取器",
    "萃取器/压力表：": "萃取器/壓力表：",
    "萃取器/压力表": "萃取器/壓力表",
    "粉饼厚度：": "粉餅厚度：",
    "粉饼厚度": "粉餅厚度",
    "磨豆机：": "磨豆機：",
    "磨豆机": "磨豆機",
    "咖啡粉粗细：": "咖啡粉粗細：",
    "咖啡粉粗细": "咖啡粉粗細",
    "压粉柱：": "壓粉柱：",
    "压粉柱": "壓粉柱",
    "粉饼松散度：": "粉餅鬆散度：",
    "粉饼松散度": "粉餅鬆散度",
    "咖啡流口：": "咖啡流口：",
    "咖啡流口": "咖啡流口",
    "绝缘仪器：": "絕緣儀器：",
    "绝缘仪器": "絕緣儀器",
    "接地仪器：": "接地儀器：",
    "接地仪器": "接地儀器",
    "预计下次保养时间：": "預計下次保養時間：",
    "预计下次保养时间": "預計下次保養時間",
    "预约成功单号：": "預約成功單號：",
    "预约成功单号": "預約成功單號",
    "请输入系统单号": "請輸入系統單號",
    "1、上门负责清理的工程师是否主动出示授权服务工作卡": "1、上門負責清理的工程師是否主動出示授權服務工作卡",
    "维修站是否主动出示清洁部件清单（满分10分）": "維修站是否主動出示清潔部件清單（滿分10分）",
    "上门负责清理的工程师是否主动出示授权服务工作卡": "上門負責清理的工程師是否主動出示授權服務工作卡",
    "维修站是否主动出示清洁部件清单": "維修站是否主動出示清潔部件清單",
    "2、清洁前是否主动介绍清洁工具和物料（满分10分）": "2、清潔前是否主動介紹清潔工具和物料（滿分10分）",
    "3、清洁部件是否有展示照片或则现场展示（满分10分）": "3、清潔部件是否有展示照片或則現場展示（滿分10分）",
    "4、清洁结束后是否重新调试咖啡机，将其调到了适合顾客的口味": "4、清潔結束後是否重新調試咖啡機，將其調到了適合顧客的口味",
    "取机时是否演示制作咖啡并展示粉饼（满分10分）": "取機時是否演示製作咖啡並展示粉餅（滿分10分）",
    "清洁结束后是否重新调试咖啡机，将其调到了适合顾客的口味": "清潔結束後是否重新調試咖啡機，將其調到了適合顧客的口味",
    "取机时是否演示制作咖啡并展示粉饼": "取機時是否演示製作咖啡並展示粉餅",
    "5、（上门服务）工程师离开时，是否将清洁咖啡机的残留物清洁干净，并带离": "5、（上門服務）工程師離開時，是否將清潔咖啡機的殘留物清潔乾淨，並帶離",
    "是否有开清洁工作单给顾客（满分10分）": "是否有開清潔工作單給顧客（滿分10分）",
    "（上门服务）工程师离开时，是否将清洁咖啡机的残留物清洁干净，并带离": "（上門服務）工程師離開時，是否將清潔咖啡機的殘留物清潔乾淨，並帶離",
    "是否有开清洁工作单给顾客": "是否有開清潔工作單給顧客",
    "修改下次保养时间": "修改下次保養時間",
    "修改预约成功单号": "修改預約成功單號",
    "修改预计下次保养时间": "修改預計下次保養時間",
    "请修改预约成功单号": "請修改預約成功單號",
    "保养单查询信息": "保養單查詢信息",
    "咖啡机保养单获取失败": "咖啡機保養單獲取失敗",
    "请选择需要修改的咖啡机保养单": "請選擇需要修改的咖啡機保養單",
    "工单完成起始时间": "工單完成起始時間",
    "工单完成结束时间": "工單完成結束時間",
    "保养到期起始时间": "保養到期起始時間",
    "保养到期结束时间": "保養到期結束時間",
    "回访起始时间": "回訪起始時間",
    "回访结束时间": "回訪結束時間",
    "新增客户起始时间": "新增客户起始時間",
    "新增客户结束时间": "新增客户結束時間",
    "联系电话": "聯繫電話",
    "合同有效起始时间": "合同有效起始時間",
    "合同有效结束时间": "合同有效結束時間",
    "合同号": "合同號",
    "保养类型": "保養類型",
    "删除保养工单": "刪除保養工單",
    "确定要删除当前保养工单吗？": "確定要刪除當前保養工單嗎？",
    "请选择要删除的保养工单": "請選擇要刪除的保養工單",
    "自动咖啡机数据": "自動咖啡機數據",
    "用户类型": "用户類型",
    "电话记录": "電話記錄",
    "请输入开始日期和结束日期": "請輸入開始日期和結束日期",
    "保修价格": "保修價格",
    "非保修价格": "非保修價格",
    "请选择维修单": "請選擇維修單",
    "添加备注": "添加備註",
    "已阅": "已閲",
    "请选择品牌": "請選擇品牌",
    "导入成功": "導入成功",
    "导入失败": "導入失敗",
    "保存成功": "保存成功",
    "保存失败": "保存失敗",
    "配件编码": "配件編碼",
    "名称": "名稱",
    "零售价格": "零售價格",
    "折扣": "折扣",
    "折扣（0-1）": "折扣（0-1）",
    "请选择需要修改的零件费用": "請選擇需要修改的零件費用",
    "请选择要删除的零件费用": "請選擇要刪除的零件費用",
    "删除零件费用": "刪除零件費用",
    "确定要删除选中所有零件费用吗？": "確定要刪除選中所有零件費用嗎？",
    "确定要删除配件编码为【{{:a}}】的零件费用吗？": "確定要刪除配件編碼為【{{:a}}】的零件費用嗎？",
    "零件费用已存在": "零件費用已存在",
    "{{:a}}等零件费用已存在": "{{:a}}等零件費用已存在",
    "零件费用品牌不匹配": "零件費用品牌不匹配",
    "{{:a}}等零件费用与选择品牌不对应，请核对": "{{:a}}等零件費用與選擇品牌不對應，請核對",
    "零件费用导入出错": "零件費用導入出錯",
    "{{:a}}等零件费用导入出错": "{{:a}}等零件費用導入出錯",
    "零件费用维护": "零件費用維護",
    "已启动后台导出，请耐心等候。": "已啓動後台導出，請耐心等候。",
    "导出失败，后台发生错误。": "導出失敗，後台發生錯誤。",
    "已启动后台导出，可能需要几分钟，请耐心等候。": "已啓動後台導出，可能需要幾分鐘，請耐心等候。",
    "零件费用导出失败，后台发生错误。": "零件費用導出失敗，後台發生錯誤。",
    "咖啡机离仓数据导出失败，后台发生错误。": "咖啡機離倉數據導出失敗，後台發生錯誤。",
    "订单维护数据导出失败，后台发生错误。": "訂單維護數據導出失敗，後台發生錯誤。",
    "订单维护数据导出失败，请重试。": "訂單維護數據導出失敗，請重試。",
    "预计到货数据导出失败，后台发生错误。": "預計到貨數據導出失敗，後台發生錯誤。",
    "预计到货数据导出失败，请重试": "預計到貨數據導出失敗，請重試",
    "配件库存数据导出失败，后台发生错误。": "配件庫存數據導出失敗，後台發生錯誤。",
    "配件库存数据导出失败，请重试": "配件庫存數據導出失敗，請重試",
    "延保数据导出失败，后台发生错误。": "延保數據導出失敗，後台發生錯誤。",
    "延保数据导出失败，请重试": "延保數據導出失敗，請重試",
    "上传EXCEL": "上傳EXCEL",
    "机器系列号": "機器系列號",
    "经销商名称": "經銷商名稱",
    "离仓时间": "離倉時間",
    "请选择需要修改的咖啡机离仓数据": "請選擇需要修改的咖啡機離倉數據",
    "请选择要删除的咖啡机离仓数据": "請選擇要刪除的咖啡機離倉數據",
    "删除咖啡机离仓数据": "刪除咖啡機離倉數據",
    "确定要删除选中所有咖啡机离仓数据吗？": "確定要刪除選中所有咖啡機離倉數據嗎？",
    "确定要删除机器型号为【{{:a}}】的咖啡机离仓数据吗？": "確定要刪除機器型號為【{{:a}}】的咖啡機離倉數據嗎？",
    "咖啡机离仓数据导入出错": "咖啡機離倉數據導入出錯",
    "{{:a}}等咖啡机离仓数据导入出错": "{{:a}}等咖啡機離倉數據導入出錯",
    "搜索关键字": "搜索關鍵字",
    "机器型号/机器系列号": "機器型號/機器系列號",
    "咖啡机离仓数据": "咖啡機離倉數據",
    "维修单时间修复完成": "維修單時間修復完成",
    "维修单时间修复失败": "維修單時間修復失敗",
    "修正时间": "修正時間",
    "一键修正": "一鍵修正",
    "海外": "海外",
    "国内": "國內",
    "维修费用统计核对表": "維修費用統計核對表",
    "海外维修费用统计核对表": "海外維修費用統計核對表",
    "国内维修费用统计核对表": "國內維修費用統計核對表",
    "维修站维修费用统计核对表": "維修站維修費用統計核對表",
    "维修站维修费用统计": "維修站維修費用統計",
    "Guarantee维修费用统计核对表": "Guarantee維修費用統計核對表",
    "下载": "下載",
    "请选择需要修改的故障信息": "請選擇需要修改的故障信息",
    "请选择要删除的故障信息": "請選擇要刪除的故障信息",
    "删除故障信息": "刪除故障信息",
    "确定要删除选中所有故障信息吗？": "確定要刪除選中所有故障信息嗎？",
    "确定要删除Description为【{{:a}}】的故障信息吗？": "確定要刪除Description為【{{:a}}】的故障信息嗎？",
    "Area": "Area",
    "IRIS Defect Code": "IRIS Defect Code",
    "Iris Defect Description": "Iris Defect Description",
    "故障分类": "故障分類",
    "缺陷描述": "缺陷描述",
    "具体补充": "具體補充",
    "显示顺序": "顯示順序",
    "邮件设置获取失败": "郵件設置獲取失敗",
    "邮件设置保存成功": "郵件設置保存成功",
    "邮件设置保存失败": "郵件設置保存失敗",
    "邮件设置": "郵件設置",
    "邮件服务器": "郵件服務器",
    "端口": "端口",
    "账户": "賬户",
    "邮件抬头": "郵件抬頭",
    "邮件落款": "郵件落款",
    "服务器链接": "服務器鏈接",
    "请选择需要修改的订单数据": "請選擇需要修改的訂單數據",
    "请选择要删除的订单数据": "請選擇要刪除的訂單數據",
    "确定要删除订单数据吗？": "確定要刪除訂單數據嗎？",
    "确定要删除订单编号为【{{:a}}】的订单数据吗？": "確定要刪除訂單編號為【{{:a}}】的订单数据吗？",
    "确定要删除手机号为【{{:a}}】的订单数据吗？": "確定要刪除手機號為【{{:a}}】的訂單數據嗎？",
    "确定要删除保修卡号为【{{:a}}】的订单数据吗？": "確定要刪除保修卡號為【{{:a}}】的訂單數據嗎？",
    "删除订单数据": "刪除訂單數據",
    "确定要删除选中所有订单数据吗？": "確定要刪除選中所有訂單數據嗎？",
    "订单数据已存在": "訂單數據已存在",
    "{{:a}}等订单数据已存在": "{{:a}}等訂單數據已存在",
    "订单数据导入出错": "訂單數據導入出錯",
    "{{:a}}等订单数据导入出错": "{{:a}}等訂單數據導入出錯",
    "销售渠道": "銷售渠道",
    "批量删除": "批量刪除",
    "订单维护": "訂單維護",
    "请选择需要修改的预计到货数据": "請選擇需要修改的預計到貨數據",
    "请选择要删除的预计到货数据": "請選擇要刪除的預計到貨數據",
    "删除预计到货数据": "刪除預計到貨數據",
    "确定要删除订单编号为【{{:a}}】的预计到货数据吗？": "確定要刪除訂單編號為【{{:a}}】的預計到貨數據嗎？",
    "预计到货数据导入出错": "預計到貨數據導入出錯",
    "{{:a}}等预计到货数据导入出错": "{{:a}}等預計到貨數據導入出錯",
    "预计到货日期": "預計到貨日期",
    "预计到货": "預計到貨",
    "请选择需要修改的配件库存数据": "請選擇需要修改的配件庫存數據",
    "请选择要删除的配件库存数据": "請選擇要刪除的配件庫存數據",
    "删除配件库存数据": "刪除配件庫存數據",
    "确定要删除当前配件库存数据吗？": "確定要刪除當前配件庫存數據嗎？",
    "配件库存数据已存在": "配件庫存數據已存在",
    "{{:a}}等配件库存数据已存在": "{{:a}}等配件庫存數據已存在",
    "配件库存数据导入出错": "配件庫存數據導入出錯",
    "{{:a}}等配件库存数据导入出错": "{{:a}}等配件庫存數據導入出錯",
    "库存数量": "庫存數量",
    "配件库存数据": "配件庫存數據",
    "请选择要删除的产品信息": "請選擇要刪除的產品信息",
    "删除产品信息": "刪除產品信息",
    "确定要删除选中所有产品信息吗？": "確定要刪除選中所有產品信息嗎？",
    "确定要删除产品型号为【{{:a}}】的产品信息吗？": "確定要刪除產品型號為【{{:a}}】的產品信息嗎？",
    "产品信息已存在": "產品信息已存在",
    "{{:a}}等产品信息已存在": "{{:a}}等產品信息已存在",
    "产品信息品牌不匹配": "產品信息品牌不匹配",
    "{{:a}}等产品信息与选择品牌不对应，请核对": "{{:a}}等產品信息與選擇品牌不對應，請核對",
    "产品信息导入出错": "產品信息導入出錯",
    "{{:a}}等产品信息导入出错": "{{:a}}等產品信息導入出錯",
    "机器类别": "機器類別",
    "产品型号": "產品型號",
    "导出产品型号": "導出產品型號",
    "导出时的产品型号": "導出時的產品型號",
    "导出总部表单": "導出總部表單",
    "总部品类": "總部品類",
    "产品名称": "產品名稱",
    "预约期限": "預約期限",
    "上门期限": "上門期限",
    "维修期限": "維修期限",
    "维修站回访期限": "維修站回訪期限",
    "总部回访期限": "總部回訪期限",
    "保修维修费": "保修維修費",
    "标准维修费": "標準維修費",
    "检测费用": "檢測費用",
    "产品信息": "產品信息",
    "服务期限设置获取失败": "服務期限設置獲取失敗",
    "服务期限设置保存成功": "服務期限設置保存成功",
    "服务期限设置保存失败": "服務期限設置保存失敗",
    "超时设置": "超時設置",
    "预约时间期限（小时）": "預約時間期限（小時）",
    "上门服务期限（天）": "上門服務期限（天）",
    "维修用时期限（天）": "維修用時期限（天）",
    "咖啡机上门期限（天）": "咖啡機上門期限（天）",
    "咖啡机维修期限（天）": "咖啡機維修期限（天）",
    "回访时间期限（维修站）": "回訪時間期限（維修站）",
    "回访时间期限（总部）": "回訪時間期限（總部）",
    "更新前台页面": "更新前台頁面",
    "确定更新前台页面代码？": "確定更新前台頁面代碼？",
    "更新后台": "更新後台",
    "更新后台代码": "更新後台代碼",
    "确定更新后台代码？": "確定更新後台代碼？",
    "更新": "更新",
    "更新成功": "更新成功",
    "更新失败": "更新失敗",
    "德龙售后": "德龍售後",
    "删除部门": "刪除部門",
    "确定要删除部门【{{:a}}】吗？": "確定要刪除部門【{{:a}}】嗎？",
    "部门信息": "部門信息",
    "编辑部门信息": "編輯部門信息",
    "部门名称": "部門名稱",
    "请输入部门名称!": "請輸入部門名稱!",
    "请选择所在省份!": "請選擇所在省份!",
    "请输入所在城市!": "請輸入所在城市!",
    "维修站编号": "維修站編號",
    "请输入维修站编号!": "請輸入維修站編號!",
    "联系人": "聯繫人",
    "联系地址": "聯繫地址",
    "当前余额": "當前餘額",
    "请输入当前余额!": "請輸入當前餘額!",
    "保养模块权限": "保養模塊權限",
    "配件模块权限": "配件模塊權限",
    "DLS配件订购": "DLS配件訂購",
    "历史站点": "歷史站點",
    "新增子区域": "新增子區域",
    "新增员工": "新增員工",
    "删除员工": "刪除員工",
    "确定要删除当前员工【{{:a}}】吗？": "確定要刪除當前員工【{{:a}}】嗎？",
    "员工信息": "員工信息",
    "编辑员工信息": "編輯員工信息",
    "员工姓名": "員工姓名",
    "请输入员工姓名!": "請輸入員工姓名!",
    "所属部门": "所屬部門",
    "员工职位": "員工職位",
    "上级主管": "上級主管",
    "性别": "性別",
    "女": "女",
    "男": "男",
    "登录用户名": "登錄用户名",
    "请输入登录用户名!": "請輸入登錄用户名!",
    "登录密码": "登錄密碼",
    "咖啡机养护工程师": "咖啡機養護工程師",
    "养护工程师编号": "養護工程師編號",
    "工程师编号": "工程師編號",
    "导出Excel权限": "導出Excel權限",
    "请选择需要修改的延保信息": "請選擇需要修改的延保信息",
    "请选择要删除的延保信息": "請選擇要刪除的延保信息",
    "删除延保信息": "刪除延保信息",
    "确定要删除选中所有延保信息吗？": "確定要刪除選中所有延保信息嗎？",
    "确定要删除订单编号为【{{:a}}】的延保信息吗？": "確定要刪除訂單編號為【{{:a}}】的延保信息嗎？",
    "延保数据": "延保數據",
    "用户设置": "用户設置",
    "服务期限维护": "服務期限維護",
    "配件库存": "配件庫存",
    "Excel": "Excel",
    "设置成功": "設置成功",
    "设置失败": "設置失敗",
    "点击或者拖拽上传语音提醒文件": "點擊或者拖拽上傳語音提醒文件",
    "每次只能上传一个音频文件": "每次只能上傳一個音頻文件",
    "用户电话不接，稍后联系": "用户電話不接，稍後聯繫",
    "用户出差，稍后联系": "用户出差，稍後聯繫",
    "用户出差，等用户来电": "用户出差，等用户來電",
    "等用户来电确认时间": "等用户來電確認時間",
    "已经电话/视频指导，稍后联系确认": "已經電話/視頻指導，稍後聯繫確認",
    "等用户提供有效凭证": "等用户提供有效憑證",
    "用户申请费用中，等用户来电": "用户申請費用中，等用户來電",
    "配件已订购，待配件到货": "配件已訂購，待配件到貨",
    "机器已修复，待客户付款/客户待取/待送回": "機器已修復，待客户付款/客户待取/待送回",
    "机器/生产编号": "機器/生產編號",
    "制作杯数(自动咖啡机)": "製作杯數(自動咖啡機)",
    "除垢次数(自动咖啡机)": "除垢次數(自動咖啡機)",
    "使用水量(自动咖啡机)": "使用水量(自動咖啡機)",
    "渠道分类": "渠道分類",
    "配件金额合计": "配件金額合計",
    "回访状态": "回訪狀態",
    "工单数据已被修改过，请先获取最新工单": "工單數據已被修改過，請先獲取最新工單",
    "没有发现数据，导⼊失败": "沒有發現數據，導⼊失敗",
    "没有发现数据": "沒有發現數據",
    "Excel数据导⼊格式不正确，请确认": "Excel數據導⼊格式不正確，請確認",
    "数据导⼊失败": "數據導⼊失敗",
    "文件不存在": "文件不存在",
    "开始查询维修单服务数据...": "開始查詢維修單服務數據...",
    "维修单服务数据导出失败": "維修單服務數據導出失敗",
    "维修单服务数据获取成功，开始生成Excel文件": "維修單服務數據獲取成功，開始生成Excel文件",
    "正在将维修单服务数据写入Excel文件": "正在將維修單服務數據寫入Excel文件",
    "维修单服务数据文件导出成功": "維修單服務數據文件導出成功",
    "开始查询维修单查询数据...": "開始查詢維修單查詢數據...",
    "维修单查询数据获取成功，开始构建数据": "維修單查詢數據獲取成功，開始構建數據",
    "维修单查询数据构建完成，开始生成Excel文件": "維修單查詢數據構建完成，開始生成Excel文件",
    "维修单查询数据": "維修單查詢數據",
    "维修单查询数据文件导出成功": "維修單查詢數據文件導出成功",
    "没有可导出的维修单查询数据": "沒有可導出的維修單查詢數據",
    "没有可导出的维修单数据": "沒有可導出的維修單數據",
    "维修单查询数据导出失败": "維修單查詢數據導出失敗",
    "正在将维修单查询数据写入Excel文件": "正在將維修單查詢數據寫入Excel文件",
    "超预约时间": "超預約時間",
    "超上门服务时间": "超上門服務時間",
    "超维修时间": "超維修時間",
    "超回访时间": "超回訪時間",
    "找不到模板文件": "找不到模板文件",
    "正在统计，请稍候...": "正在統計，請稍候...",
    "批量作废完成": "批量作廢完成",
    "旧件数配件": "舊件數配件",
    "维修站点": "維修站點",
    "配件名称": "配件名稱",
    "配件编号": "配件編號",
    "没有找到咖啡机保养单": "沒有找到咖啡機保養單",
    "清洁前咖啡粉粗细": "清潔前咖啡粉粗細",
    "清洁后咖啡粉粗细": "清潔後咖啡粉粗細",
    "清洁前粉饼松散度": "清潔前粉餅鬆散度",
    "清洁后粉饼松散度": "清潔後粉餅鬆散度",
    "预计下次保养时间-年": "預計下次保養時間-年",
    "预计下次保养时间-月": "預計下次保養時間-月",
    "预计下次保养时间-日": "預計下次保養時間-日",
    "修改下次保养时间为：": "修改下次保養時間為：",
    "修改预约成功单号为：": "修改預約成功單號為：",
    "表头格式": "表頭格式",
    "预计到货时间": "預計到貨時間",
    "已存在相同登录账户用户": "已存在相同登錄賬户用户",
    "没有找到对应用户": "沒有找到對應用户",
    "登录失败，请重试": "登錄失敗，請重試",
    "用户名不存在": "用户名不存在",
    "密码修改失败，请重试": "密碼修改失敗，請重試",
    "上传时间": "上傳時間",
    "保修内": "保修內",
    "过保": "過保",
    "图片上传失败": "圖片上傳失敗",
    "故障现象：": "故障現象：",
    "故障现象": "故障現象",
    "关闭": "關閉",
    "上传人：": "上傳人：",
    "上传时间：": "上傳時間：",
    "（点击查看大图）": "（點擊查看大圖）",
    "查看照片": "查看照片",
    "正在提交，请不要重复操作": "正在提交，請不要重複操作",
    "请不要重复操作": "請不要重複操作",
    "维修状态统计": "維修狀態統計",
    "开单时间-预约确认时间": "開單時間-預約確認時間",
    "3小时以内": "3小時以內",
    "3-6小时": "3-6小時",
    "6-12小时": "6-12小時",
    "1天": "1天",
    "3天": "3天",
    "3天以上": "3天以上",
    "预约上门时间-完成维修时间": "預約上門時間-完成維修時間",
    "当天": "當天",
    "待送修": "待送修",
    "待寄修": "待寄修",
    "顾客送修": "顧客送修",
    "顾客寄修": "顧客寄修",
    "送修已收到": "送修已收到",
    "寄修已收到": "寄修已收到",
    "因疫情需要，我们将每天统计您站的工作范围": "因疫情需要，我們將每天統計您站的工作範圍",
    "请告知您站目前的工作范围": "請告知您站目前的工作範圍",
    "请问您站预计恢复工作的时间": "請問您站預計恢復工作的時間",
    "休假": "休假",
    "站点休假": "站點休假",
    "正常营业": "正常營業",
    "部分区域上门": "部分區域上門",
    "仅接修": "僅接修",
    "仅接机": "僅接機",
    "站点关闭": "站點關閉",
    "确认提交": "確認提交",
    "全国服务网点工作范围查询": "全國服務網點工作範圍查詢",
    "全国网点": "全國網點",
    "休假统计": "休假統計",
    "未统计": "未統計",
    "统计中": "統計中",
    "已结束": "已結束",
    "标题": "標題",
    "有效期限": "有效期限",
    "开始统计": "開始統計",
    "创建时间": "創建時間",
    "更新时间": "更新時間",
    "数据提交失败，请重试": "數據提交失敗，請重試",
    "前往填报": "前往填報",
    "请选择要删除的休假统计公告": "請選擇要刪除的休假統計公告",
    "删除休假统计公告": "刪除休假統計公告",
    "确定要删除休假统计公告【{{:a}}】吗？": "確定要刪除休假統計公告【{{:a}}】嗎？",
    "请选择要删除的记录": "請選擇要刪除的記錄",
    "删除记录": "刪除記錄",
    "确定要删除维修站【{{:a}}】的记录吗？": "確定要刪除維修站【{{:a}}】的記錄嗎？",
    "工作范围": "工作範圍",
    "营业状态": "營業狀態",
    "起止日期": "起止日期",
    "休假起止日期": "休假起止日期",
    "休假日期": "休假日期",
    "更新人": "更新人",
    "补充说明": "補充說明",
    "疫情统计": "疫情統計",
    "疫情期间工作范围": "疫情期間工作範圍",
    "节假日营业状态": "節假日營業狀態",
    "电话转接": "電話轉接",
    "预计恢复时间": "預計恢復時間",
    "请输入标题": "請輸入標題",
    "请选择有效期限": "請選擇有效期限",
    "休假统计记录": "休假統計記錄",
    "查看记录": "查看記錄",
    "公告标题": "公告標題",
    "已删除，无需填报": "已刪除，無需填報",
    "其他人已填报": "其他人已填報",
    "WEB页面已更新，当前WEB端版本不是最低要求版本，请刷新页面": "WEB頁面已更新，當前WEB端版本不是最低要求版本，請刷新頁面",
    "WEB页面已更新，请刷新页面": "WEB頁面已更新，請刷新頁面",
    "WEB页面更新": "WEB頁面更新",
    "刷新页面": "刷新頁面",
    "最后更新日期": "最後更新日期",
    "最新操作": "最新操作",
    "无效的延保": "無效的延保",
    "保修依据购买日期不能大于注册时间": "保修依據購買日期不能大於註冊時間",
    "保修日期不符合": "保修日期不符合",
    "延保不符合": "延保不符合",
    "该维修单的预约上门时间为：": "該維修單的預約上門時間為：",
    "请修改上门日期": "請修改上門日期",
    "该维修单的预约上门时间为：{{:1}}，请修改上门日期": "該維修單的預約上門時間為：{{:1}}，請修改上門日期",
    "操作太快": "操作太快",
    "正在获取数据，请稍候...": "正在獲取數據，請稍候...",
    "收机方法：": "收機方法：",
    "收机方法": "收機方法",
    "短信已发送": "短信已發送",
    "短信发送失败，请重试": "短信發送失敗，請重試",
    "订单编号/手机号": "訂單編號/手機號",
    "订单编号/手机号/型号/机身编号": "訂單編號/手機號/型號/機身編號",
    "产品名称/产品型号/导出产品型号": "產品名稱/產品型號/導出產品型號",
    "请填写维修站物流单号!": "請填寫維修站物流單號!",
    "移动电话必须填写11位号码!": "移動電話必須填寫11位號碼!",
    "请选择出仓日期": "請選擇出倉日期",
    "请输入正确格式的手机号!": "請輸入正確格式的手機號!",
    "预约上门时间不能小于当前时间": "預約上門時間不能小於當前時間",
    "寄出旧件单号：": "寄出舊件單號：",
    "寄出旧件单号": "寄出舊件單號",
    "寄送发票单号：": "寄送發票單號：",
    "寄送发票单号": "寄送發票單號",
    "发票号：": "發票號：",
    "发票号": "發票號",
    "发票照片：": "發票照片：",
    "发票照片": "發票照片",
    "点击或拖拽文件到此区域上传照片": "點擊或拖拽檔案到此區域上傳照片",
    "更换旧件": "更換舊件",
    "待盘点旧件": "待盤點舊件",
    "待盘点": "待盤點",
    "旧件已盘点": "舊件已盤點",
    "快递公司+快递单号": "快遞公司+快遞單號",
    "请填写寄出发票单号": "請填寫寄出發票單號",
    "请填写发票号": "請填寫發票號",
    "请上传发票照片": "請上傳發票照片",
    "请输入配件旧件数量": "請輸入配件舊件數量",
    "请输入整机旧件数量": "請輸入整機舊件數量",
    "配件搜索": "配件搜索",
    "应返数量": "應返數量",
    "盘点数量": "盤點數量",
    "总部备注": "總部備註",
    "经销商": "經銷商",
    "经销商数据": "經銷商數據",
    "请选择需要修改的经销商信息": "請選擇需要修改的經銷商信息",
    "请选择要删除的经销商信息": "請選擇要删除的經銷商信息",
    "删除经销商信息": "删除經銷商信息",
    "确定要删除名称为【{{:a}}】的经销商信息吗？": "確定要删除名稱為【{{:a}}】的經銷商信息嗎？",
    "确定要删除选中所有经销商信息吗？": "確定要删除選中所有經銷商信息嗎？",
    "经销商数据导出失败，后台发生错误。": "經銷商數據匯出失敗，後臺發生錯誤。",
    "经销商数据导出失败，请重试": "經銷商數據匯出失敗，請重試",
    "商品": "商品",
    "商品数据": "商品數據",
    "请选择需要修改的商品信息": "請選擇需要修改的商品信息",
    "请选择要删除的商品信息": "請選擇要删除的商品信息",
    "删除商品信息": "删除商品信息",
    "确定要删除名称为【{{:a}}】的商品信息吗？": "確定要删除名稱為【{{:a}}】的商品信息嗎？",
    "确定要删除选中所有商品信息吗？": "確定要删除選中所有商品信息嗎？",
    "商品数据导出失败，后台发生错误。": "商品數據匯出失敗，後臺發生錯誤。",
    "商品数据导出失败，请重试": "商品數據匯出失敗，請重試",
    "商品型号": "商品型號",
    "EAN/UPC": "EAN/UPC",
    "导入成功, 【{{:a}}】等{{:b}}个经销商已存在": "導入成功, 【{{:a}}】等{{:b}}個經銷商已存在",
    "导入成功，【{{:a}}】等{{:b}}个800条码已存在": "導入成功，【{{:a}}】等{{:b}}個800條碼已存在",
    "导入文件模板": "導入文件模板",
    "导入文件\n模板": "導入文件\n模板",
    "没有可提交的数据": "沒有可提交的數據",
    "请输入商品型号": "請輸入商品型號",
    "请输入商品EAN/UPC": "請輸入商品EAN/UPC",
    "仓库扫码": "倉庫掃碼",
    "没有访问权限": "沒有訪問權限",
    "上传离仓数据": "上傳離倉數據",
    "上传离仓数据统计": "上傳離倉資料統計",
    "上传人": "上傳人",
    "请填写EKP单号": "請填寫EKP單號",
    "待发货": "待發貨",
    "请联系总部索要单号": "請聯系總部索要單號",
    "请输入维修站物流单号！1、送货到家，请联系总部索要单号。2、维修站寄出自行填写": "請填寫維修站物流單號！1、送貨到家，請聯系總部索要單號。 2、維修站寄出自行填寫",
    "旧件待寄出": "舊件待寄出",
    "旧件已寄出": "舊件已寄出",
    "待返回旧件": "待返回舊件",
    "急": "急",
    "诉": "訴",
    "投诉待处理": "投訴待處理",
    "投诉已处理": "投訴已處理",
    "按批次删除": "按批次刪除",
    "上传批次": "上傳批次",
    "请输入上传批次": "請輸入上傳批次",
    "按批次删除订单失败": "按批次刪除訂單失敗",
    "制作杯数大于等于10000不能做保修": "製作杯數大於等於10000不能做保修",
    "拍照人": "拍照人",
    "点击查看大图": "點擊查看大圖",
    "工单完成量": "工單完成量",
    "工单完成量统计": "工單完成量統計",
    "需总部选择保修日期": "需總部選擇保修日期",
    "工单实效(品类/天)": "工單實效(品類/天)",
    "年度汇总": "年度匯總",
    "工单实效(品类/天)统计": "工單實效(品類/天)統計",
    "请选择年份": "請選擇年份",
    "所在城市：": "所在城市：",
    "工作范围：": "工作範圍：",
    "电话转接：": "電話轉接：",
    "预计恢复时间：": "預計恢復時間：",
    "休假时间：": "休假時間：",
    "更新人：": "更新人：",
    "更新时间：": "更新時間：",
    "请输入站点所在城市": "請輸入站點所在城市",
    "平均工单用时（天）": "平均工單用時（天）",
    "月份": "月份",
    "年份": "年份",
    "总工单数": "總工單數",
    "结算公司抬头": "結算公司擡頭",
    "请选择过滤条件": "請選擇過濾條件",
    "短信记录": "短信記錄",
    "短信内容": "短信內容",
    "已发送": "已發送",
    "已发送其他短信": "已發送其他短信",
    "已过期": "已過期",
    "待发送": "待發送",
    "今天": "今天",
    "昨天": "昨天",
    "前天": "前天",
    "最近三天": "最近三天",
    "最近一周": "最近一周",
    "最近一月": "最近一月",
    "选择状态": "選擇狀態",
    "单号/移动电话/SMSKey": "單號/移動電話/SMSKey",
    "开发维护": "開發維護",
    "两次或以上维修": "兩次或以上維修",
    "结账单号": "結賬單號",
    "400热线派单": "400熱線派單",
    "订单号/手机号/保修卡号/海外机身号/京东单号": "訂單號/手機號/保修卡號/海外機身號/京東單號",
    "退回作废申请": "退回作廢申請",
    "保修性质": "保修性質",
    "导出明细": "導出明細",
    "抽查": "抽查",
    "已抽查": "已抽查",
    "确定将【{{:a}}】标记为已抽查吗?": "確定將【{{:a}}】標記為已抽查嗎?",
    "查看图片": "查看圖片",
    "添加发票照片（最多10张）": "添加發票照片（最多10張）",
    "维修站备注": "維修站備註",
    "维修结果": "維修結果",
    "维修时长": "維修時長",
    "维修师服务": "維修師服務",
    "产品使用": "產品使用",
    "NPS打分": "NPS打分",
    "IP地址": "IP地址",
    "操作人": "操作人",
    "操作账号": "操作賬號",
    "描述": "描述",
    "数据写入失败": "數據寫入失敗",
    "请上传上次更新时间": "請上傳上次更新時間",
    "没有登录权限": "沒有登錄權限",
    "年": "年",
    "月": "月",
    "区域": "區域",
    "分区": "分區",
    "新界": "新界",
    "离岛": "離島",
    "九龙": "九龍",
    "香港岛": "香港島",
    "大围": "大圍",
    "沙田": "沙田",
    "火炭": "火炭",
    "马鞍山": "馬鞍山",
    "大埔": "大埔",
    "太和": "太和",
    "粉岭": "粉嶺",
    "上水": "上水",
    "罗湖": "羅湖",
    "落马洲": "落馬洲",
    "葵芳": "葵芳",
    "葵涌": "葵涌",
    "葵兴": "葵興",
    "荃湾": "荃灣",
    "青衣": "青衣",
    "马湾": "馬灣",
    "深井": "深井",
    "屯门": "屯門",
    "元朗": "元朗",
    "天水围": "天水圍",
    "流浮山": "流浮山",
    "西贡": "西貢",
    "将军澳": "將軍澳",
    "宝琳": "寶琳",
    "坑口": "坑口",
    "愉景湾": "愉景灣",
    "东涌": "東涌",
    "赤𫚭角": "赤鱲角",
    "大屿山": "大嶼山",
    "大澳": "大澳",
    "长洲": "長洲",
    "坪洲": "坪洲",
    "南丫岛": "南丫島",
    "蒲苔岛": "蒲苔島",
    "美孚": "美孚",
    "荔枝角": "荔枝角",
    "长沙湾": "長沙灣",
    "深水埗": "深水埗",
    "太子": "太子",
    "旺角": "旺角",
    "大角咀": "大角咀",
    "油麻地": "油麻地",
    "佐敦": "佐敦",
    "尖沙咀": "尖沙咀",
    "红磡": "紅磡",
    "何文田": "何文田",
    "土瓜湾": "土瓜灣",
    "石硖尾": "石硤尾",
    "九龙塘": "九龍塘",
    "九龙城": "九龍城",
    "乐富": "樂富",
    "黄大仙": "黃大仙",
    "新蒲岗": "新蒲崗",
    "钻石山": "鑽石山",
    "慈云山": "慈雲山",
    "彩虹": "彩虹",
    "九龙湾": "九龍灣",
    "牛头角": "牛頭角",
    "观塘": "觀塘",
    "蓝田": "藍田",
    "油塘": "油塘",
    "鲤鱼门": "鯉魚門",
    "西环": "西環",
    "石塘咀": "石塘咀",
    "西营盘": "西營盤",
    "坚尼地城": "堅尼地城",
    "上环": "上環",
    "中环": "中環",
    "半山": "半山",
    "山顶": "山頂",
    "金钟": "金鐘",
    "湾仔": "灣仔",
    "跑马地": "跑馬地",
    "铜锣湾": "銅鑼灣",
    "大坑": "大坑",
    "天后": "天后",
    "北角": "北角",
    "鲗鱼涌": "鰂魚涌",
    "太古": "太古",
    "西湾河": "西灣河",
    "筲箕湾": "筲箕灣",
    "杏花邨": "杏花邨",
    "柴湾": "柴灣",
    "薄扶林": "薄扶林",
    "数码港": "數碼港",
    "香港仔": "香港仔",
    "鸭脷洲": "鴨脷洲",
    "深水湾": "深水灣",
    "黄竹坑": "黃竹坑",
    "浅水湾": "淺水灣",
    "石澳": "石澳",
    "赤住": "赤住",
    "修改了用户：{{:a}} 的密码": "修改了用戶：{{:a}} 的密碼",
    "开始查询系统日志数据...": "開始查詢系統日誌數據...",
    "系统日志数据获取成功，开始构建数据": "系統日誌數據獲取成功，開始構建數據",
    "没有可导出的系统日志数据": "沒有可導出的系統日誌數據",
    "系统日志数据导出失败": "系統日誌數據導出失敗",
    "系统日志数据构建完成，开始生成Excel文件": "系統日誌數據構建完成，開始生成Excel文件",
    "正在写入系统日志数据文件": "正在寫入系統日誌數據文件",
    "系统日志数据": "系統日誌數據",
    "系统日志数据文件导出中": "系統日誌數據文件導出中",
    "系统日志数据解压": "系統日誌數據解壓",
    "系统日志加密数据导出成功": "系統日誌加密數據導出成功",
    "系统日志数据文件导出失败": "系統日誌數據文件導出失敗",
    "数据提交失败": "數據提交失敗",
    "用户名或密码错误，请确认输入是否正确": "用戶名或密碼錯誤，請確認輸入是否正確",
    "密码不正确": "密碼不正確",
    "工作统计公告": "工作統計公告",
    "电话转接服务": "電話轉接服務",
    "预计恢复日期": "預計恢復日期",
    "休假开始日期": "休假開始日期",
    "休假结束日期": "休假結束日期",
    "更新日期": "更新日期",
    "工作范围统计": "工作範圍統計",
    "插座故障": "插座故障",
    "维修单查询数据文件导出失败": "維修單查詢數據文件導出失敗",
    "正在写入维修单查询数据文件": "正在寫入維修單查詢數據文件",
    "维修单查询数据文件导出中": "維修單查詢數據文件導出中",
    "1月": "1月",
    "2月": "2月",
    "3月": "3月",
    "4月": "4月",
    "5月": "5月",
    "6月": "6月",
    "7月": "7月",
    "8月": "8月",
    "9月": "9月",
    "10月": "10月",
    "11月": "11月",
    "12月": "12月",
    "添加备注：": "添加備註：",
    "添加备注": "添加備註",
    "400系统派单": "400系統派單",
    "已短信通知": "已短信通知",
    "处理维修单，单号：": "處理維修單，單號：",
    "操作：": "操作：",
    "APP版本已更新，请安装新版本APP": "APP版本已更新，請安裝新版本APP",
    "YYYY年": "YYYY年",
    "MM月": "MM月",
    "DD日": "DD日",
    "德龙Delonghi京东自营旗舰店": "德龍Delonghi京東自營旗艦店",
    "知道了": "知道了",
    "更新测试页面": "更新測試頁面",
    "确定更新测试页面代码？": "確定更新測試頁面代碼？",
    "拉花师": "拉花師",
    "订单维护审批权限": "訂單維護審批權限",
    "订单上传": "訂單上傳",
    "模板文件": "模板文件",
    "点击下载": "點擊下載",
    "点击下载模板文件": "點擊下載模板文件",
    "养护工程师": "養護工程師",
    "拉花师泵压式咖啡机EC系列": "拉花師泵壓式咖啡機EC系列",
    "拉花师自动咖啡机": "拉花師自動咖啡機",
    "账户统计": "賬户統計",
    "拉花照片：": "拉花照片：",
    "拉花照片": "拉花照片",
    "拉花费用：": "拉花費用：",
    "拉花费用": "拉花費用",
    "未预约：": "未預約：",
    "未预约": "未預約",
    "未完成：": "未完成：",
    "未完成": "未完成",
    "未完成维修：": "未完成維修：",
    "未完成维修": "未完成維修",
    "维修单": "維修單",
    "深度清洁": "深度清潔",
    "系统工单状态": "系統工單狀態",
    "订单数据": "訂單數據",
    "已提交": "已提交",
    "已退回": "已退回",
    "已上传": "已上傳",
    "保存订单数据": "保存訂單數據",
    "提交订单数据": "提交訂單數據",
    "退回订单数据": "退回訂單數據",
    "上传订单数据": "上傳訂單數據",
    "重新上传订单数据": "重新上傳訂單數據",
    "订单数据获取失败": "訂單數據獲取失敗",
    "订单数据已被修改过，请先获取最新数据": "訂單數據已被修改過，請先獲取最新數據",
    "导出订单数据": "導出訂單數據",
    "新增订单数据，手机号：{{:1}}": "新增訂單數據，手機號：{{:1}}",
    "新增订单数据，订单编号：{{:1}}": "新增訂單數據，訂單編號：{{:1}}",
    "新增订单数据，保修卡号：{{:1}}": "新增訂單數據，保修卡號：{{:1}}",
    "保存订单数据，手机号：{{:1}}": "保存訂單數據，手機號：{{:1}}",
    "保存订单数据，订单编号：{{:1}}": "保存訂單數據，訂單編號：{{:1}}",
    "保存订单数据，保修卡号：{{:1}}": "保存訂單數據，保修卡號：{{:1}}",
    "删除订单数据，订单ID：{{:1}}，手机号：{{:2}}": "刪除訂單數據，訂單ID：{{:1}}，手機號：{{:2}}",
    "删除订单数据，订单ID：{{:1}}，订单编号：{{:2}}": "刪除訂單數據，訂單ID：{{:1}}，訂單編號：{{:2}}",
    "删除订单数据，订单ID：{{:1}}，保修卡号：{{:2}}": "刪除訂單數據，訂單ID：{{:1}}，保修卡號：{{:2}}",
    "上传订单数据到订单维护，手机号：{{:1}}": "上傳訂單數據到訂單維護，手機號：{{:1}}",
    "上传订单数据到订单维护，订单编号：{{:1}}": "上傳訂單數據到訂單維護，訂單編號：{{:1}}",
    "上传订单数据到订单维护，保修卡号：{{:1}}": "上傳訂單數據到訂單維護，保修卡號：{{:1}}",
    "上传订单数据到订单维护失败，手机号：{{:1}}": "上傳訂單數據到訂單維護失敗，手機號：{{:1}}",
    "上传订单数据到订单维护失败，订单编号：{{:1}}": "上傳訂單數據到訂單維護失敗，訂單編號：{{:1}}",
    "上传订单数据到订单维护失败，保修卡号：{{:1}}": "上傳訂單數據到訂單維護失敗，保修卡號：{{:1}}",
    "上传订单数据，上传批次：{{:1}}": "上傳訂單數據，上傳批次：{{:1}}",
    "批量删除订单数据，订单ID：{{:1}}": "批量刪除訂單數據，訂單ID：{{:1}}",
    "按批次删除订单数据，订单批次：{{:1}}": "按批次刪除訂單數據，訂單批次：{{:1}}",
    "重新提交": "重新提交",
    "订单数据上传明细": "訂單數據上傳明細",
    "重新上传订单数据，手机号：{{:1}}": "重新上傳訂單數據，手機號：{{:1}}",
    "重新上传订单数据，订单编号：{{:1}}": "重新上傳訂單數據，訂單編號：{{:1}}",
    "重新上传订单数据，保修卡号：{{:1}}": "重新上傳訂單數據，保修卡號：{{:1}}",
    "重新上传": "重新上傳",
    "订单维护已存在，手机号：{{:1}}": "訂單維護已存在，手機號：{{:1}}",
    "订单维护已存在，订单编号：{{:1}}": "訂單維護已存在，訂單編號：{{:1}}",
    "订单维护已存在，保修卡号：{{:1}}": "訂單維護已存在，保修卡號：{{:1}}",
    "《De'longhi Group》服务器磁盘空间不足警告": "《De'longhi Group》服務器磁盤空間不足警告",
    "注意：《De'longhi Group》网络售后维护系统服务器磁盘空间即将用尽，空间不足会影响系统功能正常使用，请尽快处理": "注意：《De'longhi Group》網絡售後維護系統服務器磁盤空間即將用盡，空間不足會影響系統功能正常使用，請儘快處理",
    "模板": "模板",
    "导入模板": "導入模板",
    "深度清洁费用": "深度清潔費用",
    "深度清洁结算金额": "深度清潔結算金額",
    "小程序单号": "小程序單號",
    "海外数据": "海外數據",
    "请选择需要修改的海外数据": "請選擇需要修改的海外數據",
    "请选择要删除的海外数据": "請選擇要刪除的海外數據",
    "删除海外数据": "刪除海外數據",
    "确定要删除机器型号为【{{:a}}】的海外数据吗？": "確定要刪除機器型號為【{{:a}}】的海外數據嗎？",
    "确定要删除选中所有海外数据吗？": "確定要刪除選中所有海外數據嗎？",
    "海外数据导入出错": "海外數據導入出錯",
    "{{:a}}等海外数据导入出错": "{{:a}}等海外數據導入出錯",
    "海外数据导出失败，后台发生错误。": "海外數據導出失敗，後台發生錯誤。",
    "海外数据上传明细": "海外數據上傳明細",
    "导出海外数据": "導出海外數據",
    "导出咖啡机离仓数据": "導出咖啡機離倉數據",
    "新增海外数据，机器型号：{{:1}}，机器系列号：{{:2}}": "新增海外數據，機器型號：{{:1}}，機器系列號：{{:2}}",
    "更新海外数据，机器型号：{{:1}}，机器系列号：{{:2}}": "更新海外數據，機器型號：{{:1}}，機器系列號：{{:2}}",
    "删除海外数据，机器型号：{{:1}}，机器系列号：{{:2}}": "刪除海外數據，機器型號：{{:1}}，機器系列號：{{:2}}",
    "删除海外数据，ID：{{:1}}": "刪除海外數據，ID：{{:1}}",
    "批量删除海外数据，ID：{{:1}}": "批量刪除海外數據，ID：{{:1}}",
    "批量删除海外数据": "批量刪除海外數據",
    "上传海外数据": "上傳海外數據",
    "新增咖啡机离仓数据，机器型号：{{:1}}，机器系列号：{{:2}}": "新增咖啡機離倉數據，機器型號：{{:1}}，機器系列號：{{:2}}",
    "更新咖啡机离仓数据，机器型号：{{:1}}，机器系列号：{{:2}}": "更新咖啡機離倉數據，機器型號：{{:1}}，機器系列號：{{:2}}",
    "删除咖啡机离仓数据，机器型号：{{:1}}，机器系列号：{{:2}}": "刪除咖啡機離倉數據，機器型號：{{:1}}，機器系列號：{{:2}}",
    "批量删除咖啡机离仓数据，ID：{{:1}}": "批量刪除咖啡機離倉數據，ID：{{:1}}",
    "批量删除咖啡机离仓数据": "批量刪除咖啡機離倉數據",
    "上传咖啡机离仓数据": "上傳咖啡機離倉數據",
    "否决所选保养单": "否決所選保養單",
    "请选择要否决的工单": "請選擇要否決的工單",
    "保养": "保養",
    "结账单类型": "結賬單類型",
    "新建维修单，单号：": "新建維修單，單號：",
    "账号统计": "賬號統計",
    "上月": "上月",
    "本月": "本月",
    "本年": "本年",
    "1月": "1月",
    "2月": "2月",
    "3月": "3月",
    "4月": "4月",
    "5月": "5月",
    "6月": "6月",
    "7月": "7月",
    "8月": "8月",
    "9月": "9月",
    "10月": "10月",
    "11月": "11月",
    "12月": "12月",
    "系统版本": "系統版本",
    "最低要求版本": "最低要求版本",
    "当前版本": "當前版本",
    "新版本": "新版本",
    "休假时间": "休假時間",
    "新建保养单，单号：": "新建保養單，單號：",
    "处理保养单，单号：": "處理保養單，單號：",
    "删除保养单，单号：": "刪除保養單，單號：",
    "删除维修单，单号：": "刪除維修單，單號：",
    "批量上传维修单，单号：": "批量上傳維修單，單號：",
    "更新维修单机器编号，单号：{{:1}} 机器编号：{{:2}}": "更新維修單機器編號，單號：{{:1}} 機器編號：{{:2}}",
    "更新维修单服务单编号，单号：{{:1}} 服务单编号：{{:2}}": "更新維修單服務單編號，單號：{{:1}} 服務單編號：{{:2}}",
    "更新下次保养时间，单号：": "更新下次保養時間，單號：",
    "更新预约成功单号，单号：": "更新預約成功單號，單號：",
    "确定删除发票{{:1}}吗？": "確定刪除發票{{:1}}嗎？",
    "查看发票": "查看發票",
    "国内出仓日期保修": "國內出倉日期保修",
    "海外出仓日期保修": "海外出倉日期保修",
    "否决所选订单": "否決所選訂單",
    "订单数据加载中...": "訂單數據加載中...",
    "订单维护中已存在，手机号：{{:1}}": "訂單維護中已存在，手機號：{{:1}}",
    "订单维护中已存在，订单编号：{{:1}}": "訂單維護中已存在，訂單編號：{{:1}}",
    "订单维护中已存在，保修卡号：{{:1}}": "訂單維護中已存在，保修卡號：{{:1}}",
    "删除订单数据，ID：{{:1}}，手机号：{{:2}}": "刪除訂單數據，ID：{{:1}}，手機號：{{:2}}",
    "删除订单数据，ID：{{:1}}，订单编号：{{:2}}": "刪除訂單數據，ID：{{:1}}，訂單編號：{{:2}}",
    "删除订单数据，ID：{{:1}}，保修卡号：{{:2}}": "刪除訂單數據，ID：{{:1}}，保修卡號：{{:2}}",
    "收起": "收起",
    "展开": "展開",
    "查看全部订单": "查看全部訂單",
    "查看退回订单": "查看退回訂單",
    "查看否决订单": "查看否決訂單",
    "退回数量": "退回數量",
    "否决数量": "否決數量",
    "待提交": "待提交",
    "否决订单数据": "否決訂單數據",
    "作废订单数据": "作廢訂單數據",
    "删除订单": "刪除訂單",
    "删除订单服务中的订单": "刪除訂單服務中的訂單",
    "删除{{:1}}条订单": "刪除{{:1}}條訂單",
    "按上传批次删除订单维护数据，上传批次：{{:1}}": "按上傳批次刪除訂單維護數據，上傳批次：{{:1}}",
    "订单服务工单": "訂單服務工單",
    "订单服务工单获取失败": "訂單服務工單獲取失敗",
    "请选择订单服务工单": "請選擇訂單服務工單",
    "删除订单服务工单": "刪除訂單服務工單",
    "删除工单同时会删除工单中所有关联的订单数据，确定要删除订单服务工单【{{:1}}】吗？": "刪除工單同時會刪除工單中所有關聯的訂單數據，確定要刪除訂單服務工單【{{:1}}】嗎？",
    "请选择要删除的订单服务工单": "請選擇要刪除的訂單服務工單",
    "配件重复": "配件重複",
    "保养回访查询导出失败，后台发生错误。": "保養回訪查詢導出失敗，後台發生錯誤。",
    "离仓数据上传明细导出失败，后台发生错误。": "離倉數據上傳明細導出失敗，後台發生錯誤。",
    "数据导出失败，后台发生错误。": "數據導出失敗，後台發生錯誤。",
    "数据导出失败，请重试": "數據匯出失敗，請重試",
}