export default {
    "：": ": ",
    "简体": "簡體",
    "繁体": "繁體",
    "请选择": "Please select",
    "首页": "Home",
    "维修单服务": "Repair Order Service",
    "整机更换": "Complete Replacement",
    "维修单查询": "Repair Order Enquiry",
    "零件信息查询": "Part Information Enquiry",
    "考核与统计": "Assessment and Statistics",
    "结账单服务": "Checkout service",
    "自动咖啡机维护保养": "Automatic Coffee Maker Maintenance",
    "维护保养查询": "Maintenance Enquiry",
    "保养回访查询": "Maintenance Return Enquiry",
    "奖惩查询": "Reward and Punishment Enquiry",
    "奖惩": "Rewards and Punishments",
    "奖惩项目": "Reward and Punishment Programme",
    "奖惩金额": "Reward and Punishment Amount",
    "设置": "Settings",
    "用户名或密码错误，登录失败！": "Username or password error, login failed!",
    "无法连接服务器": "Unable to connect to the server",
    "请输入用户名/密码": "Please enter username/password",
    "请输入验证码": "Please enter the verification code",
    "验证码错误": "The verification code error",
    "验证码": "Verification code",
    "请输入用户名": "Please enter a username",
    "用户名": "Username",
    "请输入密码": "Please enter a password",
    "密码": "Password",
    "自动登录": "Automatic login",
    "登录": "Login",
    "网站备案/许可证号：沪ICP备20004800号-1": "ICP filing on the website: 沪ICP备20004800号-1",
    "沪公网安备31010702006927号": "Shanghai public network security 31010702006927",
    "版本：": "Version: ",
    "版本": "Version",
    "提示": "Tips",
    "浏览器当前无法播放声音，请点击确定按钮，以便及时接收到用户信息。": "The browser is currently unable to play sound, please click the OK button to receive user information in a timely manner.",
    "密码修改失败！": "Password change failed!",
    "密码修改失败，请重试！": "Password change failed, please try again!",
    "旧密码输入不正确或两次密码输入不一致！": "The old password was entered incorrectly or the two passwords were entered inconsistently!",
    "网络售后维护": "Network after-sales maintenance",
    "网络售后维护系统": "Network after-sales maintenance system",
    "当前用户：": "Current user: ",
    "当前用户": "Current user",
    "【注销】": "【Logout】",
    "注销账号": "Logout account",
    "修改密码": "Change password",
    "确定": "OK",
    "取消": "Cancel",
    "注销": "Logout",
    "确定要注销当前账号吗？": "Sure you want to cancel your current account?",
    "您已超过90天没有修改密码，为了您的账号安全，请前往修改": "You have not changed your password for more than 90 days, for your account security, please go to change it",
    "密码长度不小于8位，并包括大小写字母、特殊符号和数字": "The password must be at least 8 characters long and include uppercase and lowercase letters, special symbols, and numbers",
    "英文大小写字母、数字、_@.$%": "English upper and lower case letters, numbers, _@.$%",
    "前往修改": "Go to change",
    "原密码": "Original Password",
    "修改": "Modify",
    "旧密码": "Old Password",
    "新密码": "New Password",
    "密码确认": "Password Confirmation",
    "未上传": "Not uploaded",
    "是": "YES",
    "否": "NO",
    "刷新": "Refresh",
    "上传": "Upload",
    "新增": "Add",
    "查看": "View",
    "编辑": "Edit",
    "删除": "Delete",
    "保存": "Save",
    "作废": "Void",
    "打印": "Print",
    "返回": "Return",
    "导出Excel": "Export Excel",
    "导出CSV": "Export CSV",
    "导出SAP": "Export SAP",
    "导出TXT": "Export TXT",
    "维修服务工作单": "Repair service work orders",
    "热线电话：": "Hotline:",
    "热线电话": "Hotline",
    "可上门服务": "Visit service",
    "维修站：": "Service station:",
    "维修站": "Service station",
    "系统单号：": "System No:",
    "系统单号": "System No",
    "单号：": "System No:",
    "单号": "System No",
    "服务类型：": "Service Type:",
    "服务类型": "Service Type",
    "服务单编号：": "Sheet No:",
    "服务单编号": "Sheet No",
    "服务单号": "Sheet No",
    "客服系统单号：": "Customer System No:",
    "客服系统工号：": "Customer System Work No:",
    "客服系统单号": "Customer System No",
    "客服系统工号": "Customer System Work No",
    "用户姓名：": "Username:",
    "接单日期：": "Receive Date:",
    "公司名称：": "Company Name:",
    "品牌": "Brand",
    "品牌：": "Brand:",
    "德龙": "Delong",
    "凯伍德": "KENWOOD",
    "博朗": "Braun",
    "移动电话：": "Mobile phone:",
    "机器型号：": "Product:",
    "机器型号": "Product",
    "固定电话：": "Fixed Phone:",
    "机器名称：": "Product description:",
    "机器名称": "Product description",
    "联系地址：": "Address:",
    "机器编号：": "Machine No:",
    "机器编号": "Machine No",
    "随机附件：": "Accssary:",
    "随机附件": "Accssary",
    "产品性质：": "Product Nature:",
    "产品性质": "Product Nature:",
    "售前": "Pre-Sales",
    "售后": "After-sales",
    "样机": "Mockup",
    "折扣机": "Discount machine",
    "机身编号：": "Fuselage No:",
    "机身编号": "Fuselage No",
    "插座检查：": "Socket Check:",
    "插座检查": "Socket Check",
    "插座故障：": "Socket Fault:",
    "纸质工单：": "Paper Ticket:",
    "纸质工单": "Paper Ticket",
    "两次或以上维修：": "Two or more repairs:",
    "保修依据：": "Confirm condition:",
    "保修依据": "Confirm condition",
    "保修": "Warranty",
    "非保修": "Non-Warranty",
    "延保": "Extended warranty",
    "故障描述：": "Trouble description:",
    "故障描述": "Trouble description",
    "电话完成": "The call is done",
    "出仓日期：": "Out of date from warehouse:",
    "出仓日期": "Out of date from warehouse",
    "预计完成日期：": "Expected finish date:",
    "预计完成日期": "Expected finish date",
    "维修费用：": "Labor cost:",
    "维修费用": "Labor cost",
    "实际完成日期：": "Actual Completion Date:",
    "实际完成日期": "Actual Completion Date:",
    "预约上门时间：": "Appointment Date:",
    "预约上门时间": "Appointment Date",
    "预约确认日期：": "Appointment Settle Date:",
    "预约确认日期": "Appointment Settle Date",
    "上门公里数：": "Distance:",
    "上门公里数": "Distance",
    "上门费用：": "Transpotation Cost:",
    "上门费用": "Transpotation Cost",
    "维修类型：": "Repair Method:",
    "维修类型": "Repair Method",
    "更换配件": "Replacement Accessory",
    "维护保养": "Maintenance",
    "无问题": "No problem",
    "调试": "Debug",
    "更换整机": "Complete product",
    "更换整机-颜色": "Colour complete product",
    "更换整机-送货到家": "Express product to customer",
    "检测": "Detect",
    "维修": "Repair",
    "制作杯数：": " Used cup:",
    "制作杯数": " Used cup",
    "除垢次数：": "Desclae No. :",
    "除垢次数": "Desclae No. ",
    "使用水量：": "Water used:",
    "使用水量": "Water used",
    "蒸汽次数：": "Steam times:",
    "蒸汽次数": "Steam times",
    "蒸汽水量：": "Steam water used:",
    "蒸汽水量": "Steam water used",
    "咖啡水升数：": "Coffee litres:",
    "咖啡水升数": "Coffee litres",
    "蒸汽水升数：": "Liters of steam:",
    "蒸汽水升数": "Liters of steam",
    "热水升数：": "Liters of hot water:",
    "热水升数": "Liters of hot water",
    "饮品总杯数：": "Total number of drinks:",
    "饮品总杯数": "Total number of drinks",
    "饮品总升数：": "Total litres of beverages:",
    "饮品总升数": "Total litres of beverages",
    "EKP单号：": "EKP tracking number:",
    "EKP单号": "EKP tracking number",
    "检查记录：": "Check Record:",
    "检查记录": "Check Record",
    "维修记录：": "Repair Record:",
    "维修记录": "Repair Record",
    "颜色：": "Color:",
    "颜色": "Color",
    "送货到家：": "Home delivery:",
    "送货到家": "Home delivery",
    "故障分类：": "Fault classification:",
    "序号": "No.",
    "零件编码": "Spare parts No",
    "零件名称": "Description",
    "零件数量": "Used qty",
    "零件单价": "Unit price",
    "适用型号": "Applicable models",
    "数量": "Quantity",
    "单价": "Unit Price",
    "金额": "Amount",
    "合计": "Total",
    "其他费用：": "Other fees:",
    "其他费用": "Other fees",
    "其他费用明细：": "Detail of other cost:",
    "其他费用明细": "Detail of other cost",
    "费用明细": "Detail of other cost",
    "新机生产编号：": "Date code of new product:",
    "新机生产编号": "Date code of new product",
    "生产编号：": "Date code of new product:",
    "生产编号": "Date code of new product",
    "是否通知用户取货": "Whether to notify the user to pick up the order",
    "需通知用户取货": "Inform customer",
    "通知日期：": "Inform date:",
    "通知日期": "Inform date",
    "取货日期：": "Pick-up date:",
    "取货日期": "Pick-up date",
    "奖励/处罚": "Reward/Penalty",
    "奖励和处罚的项目": "Items of rewards and penalties",
    "接机人员：": "Received by:",
    "接机人员": "Received by",
    "维修人员：": "Maintenance personnel:",
    "维修人员": "Maintenance personnel",
    "维修站物流单号：": "Repair station tracking number:",
    "维修站物流单号": "Repair station tracking number",
    "顾客物流单号：": "Customer Tracking No. :",
    "顾客物流单号": "Customer Tracking No.",
    "快递名称+单号": "Courier name + tracking No.",
    "备注：": "Remark:",
    "备注": "Remark",
    "加急工单：": "Urgent work order:",
    "加急工单": "Urgent work order",
    "是否加急工单": "Whether the work order is urgent",
    "首次维修：": "First repair:",
    "首次维修": "First repair",
    "不包括调试": "Debugging is not included",
    "请选择是否首次维修!": "Please select whether or not to repair for the first time!",
    "请选择是否两次或以上维修!": "Please select whether two or more repairs!",
    "离仓数据上传明细": "Data upload details for leaving the warehouse",
    "请选择日期": "Please select a date",
    "YYYY/MM": "YYYY/MM",
    "YYYY/MM/DD": "YYYY/MM/DD",
    "YYYY/MM/DD HH:mm": "YYYY/MM/DD HH:mm",
    "YYYY/MM/DD HH:mm:ss": "YYYY/MM/DD HH:mm:ss",
    "国内发票": "Domestic Invoice",
    "订单号查询": "Order Number Query",
    "手机号查询": "Mobile phone number query",
    "保修卡号查询": "Warranty Card Number Inquiry",
    "港澳地区凭证": "Hong Kong and Macau Certificate",
    "其他凭证": "Other Documents",
    "其他凭证上传（需总部确认）": "Other credential upload (need to be confirmed by headquarters)",
    "全自动咖啡机或厨师机出仓日期保修": "Fully Automatic Coffee Machine or Kitchen Machine Delivery Date Warranty",
    "出仓日期保修": "Warranty on the date of shipment",
    "海外机身号": "Overseas fuselage number",
    "海外机身号查询": "Overseas fuselage number inquiry",
    "总部确认": "Headquarters Acknowledgement",
    "总部凭证确认": "Headquarters Voucher Confirmation",
    "售前机": "Pre-sales machine",
    "京东自营确认": "JD Self-operated Confirmation",
    "京东自营": "JD Self-operated",
    "总部保修确认": "Headquarters Warranty Acknowledgement",
    "泵压式咖啡机EC系列": "EC Series Pump Coffee Machines",
    "自动咖啡机": "Automatic coffee machine",
    "咖啡机": "Coffee Machine",
    "厨师机": "Kitchen machine",
    "抽湿机": "Dehumidifier",
    "榨汁机": "Juicer",
    "吸尘器": "Vacuum cleaner",
    "暖风机": "Fan Heater",
    "磨豆机": "Grinder",
    "面包机": "Bread Maker",
    "料理机": "Blender",
    "空气清新机": "Air Purifier Machine",
    "空气净化器": "Air Purifier",
    "搅拌机": "Mixer",
    "加湿器": "Humidifier",
    "多士炉": "Toaster",
    "电蒸锅": "Electric Steamer",
    "电炸锅": "Electric Fryer",
    "电熨斗": "Iron",
    "电油汀": "Oil",
    "电水壶": "Electric kettle",
    "电烤箱": "Electric Oven",
    "电烤机": "Electric Oven Machine",
    "电风扇": "Electric Fan",
    "电饭煲": "Rice cooker",
    "冰淇淋机": "Ice Cream Maker",
    "冰激凌机": "Ice Cream Machine",
    "高速切丝切片器": "High-speed shredding slicer",
    "谷物研磨器": "Grain grinder",
    "果蔬压榨器": "Fruit and vegetable press",
    "胶囊咖啡机": "Capsule coffee machine",
    "绞肉器": "Meat grinder",
    "搅拌杯": "Mixing Cup",
    "搅拌器": "Agitator",
    "慢速切片/切丝器": "Slow slicer/Shredder",
    "切面机": "Dough cutter",
    "食物处理器": "Food processor",
    "压面器": "Dough sheeter",
    "研磨机": "Grinder",
    "榨橙汁器": "Orange juicer",
    "制面器": "Noodle maker",
    "真空罐": "Vacuum tank",
    "电子称": "electronic scale",
    "上门处理": "on-site processing",
    "站点接修": "Site Repair",
    "咨询产品使用": "Consult product usage",
    "咨询维修状况": "Inquire about the status of repair",
    "咨询当地维修站或购买配件": "Consult your local workshop or purchase spare parts",
    "咨询产品潜在客户": "Consult a product lead",
    "投诉": "Complaint",
    "称赞": "Compliment",
    "配件补发": "Accessory Reissue",
    "异地寄修": "off-site Repair",
    "快递寄修": "Express Delivery",
    "省": "Province",
    "市": "City",
    "详细地址": "Detailed address",
    "居住省份": "Province of residence",
    "居住城市": "City of residence",
    "所在省份": "Province",
    "北京市": "BeiJing",
    "天津市": "TianJin",
    "上海市": "ShangHai",
    "重庆市": "ChongQing",
    "香港特别行政区": "HongKong",
    "澳门特别行政区": "Macau",
    "安徽省": "AnHui",
    "福建省": "FuJian",
    "甘肃省": "GanSu",
    "广东省": "GuangDong",
    "广西壮族自治区": "GuangXi",
    "贵州省": "GuiZhou",
    "海南省": "HaiNan",
    "河北省": "HeBei",
    "河南省": "HeNan",
    "黑龙江省": "HeiLongJiang",
    "湖北省": "HuBei",
    "湖南省": "HuNan",
    "吉林省": "JiLin",
    "江苏省": "JiangSu",
    "江西省": "JiangXi",
    "辽宁省": "LiaoNing",
    "内蒙古自治区": "NeiMengGu",
    "宁夏回族自治区": "NingXia",
    "青海省": "QingHai",
    "山东省": "ShanDong",
    "山西省": "ShanXi",
    "陕西省": "ShaanXi",
    "四川省": "SiChuan",
    "西藏自治区": "XiZang",
    "新疆维吾尔自治区": "XinJiang",
    "云南省": "YunNan",
    "浙江省": "ZheJiang",
    "所在城市": "City",
    "开县": "Kaixian",
    "綦江区": "Qijiang District",
    "南川区": "Nanchuan District",
    "长寿区": "Changshou District",
    "涪陵区": "Fuling District",
    "黔江区": "Qianjiang District",
    "铜梁区": "Tongliang District",
    "北碚区": "Beibei District",
    "大足区": "Dazu District",
    "璧山区": "Bishan District",
    "合肥市": "Hefei City",
    "芜湖市": "Wuhu City",
    "蚌埠市": "Bengbu City",
    "淮南市": "Huainan City",
    "马鞍山市": "Maanshan City",
    "淮北市": "Huaibei City",
    "铜陵市": "Tongling City",
    "宿州市": "Suzhou City",
    "安庆市": "Anqing City",
    "滁州市": "Chuzhou City",
    "阜阳市": "Fuyang City",
    "巢湖市": "Chaohu City",
    "六安市": "Liuan City",
    "池州市": "Chizhou City",
    "宣城市": "Xuancheng City",
    "黄山市": "Huangshan City",
    "亳州市": "Bozhou City",
    "桐城市": "Tongcheng City",
    "天长市": "Tianchang City",
    "明光市": "Mingguang City",
    "宁国市": "Ningguo City",
    "广德县": "Guangde County",
    "蒙城县": "Mengcheng County",
    "福州市": "Fuzhou City",
    "厦门市": "Xiamen City",
    "莆田市": "Putian City",
    "三明市": "Sanming City",
    "泉州市": "Quanzhou City",
    "漳州市": "Zhangzhou City",
    "南平市": "Nanping City",
    "龙岩市": "Longyan City",
    "宁德市": "Ningde City",
    "晋江市": "Jinjiang City",
    "石狮市": "Shishi City",
    "武夷山": "Wuyishan",
    "长乐市": "Changle City",
    "平潭县": "Pingtan County",
    "兰州市": "Lanzhou City",
    "嘉峪关市": "Jiayuguan City",
    "金昌市": "Jinchang City",
    "白银市": "Baiyin City",
    "天水市": "Tianshui City",
    "武威市": "Wuwei City",
    "张掖市": "Zhangye City",
    "平凉市": "Pingliang City",
    "酒泉市": "Jiuquan City",
    "庆阳市": "Qingyang City",
    "定西市": "Dingxi City",
    "临夏回族自治州": "Linxia Hui Autonomous Prefecture",
    "甘南藏族自治州": "Gannan Tibetan Autonomous Prefecture",
    "陇南市": "Longnan City",
    "临夏市": "Linxia City",
    "广州市": "Guangzhou City",
    "韶关市": "Shaoguan City",
    "深圳市": "Shenzhen City",
    "珠海市": "Zhuhai City",
    "汕头市": "Shantou City",
    "佛山市": "Foshan City",
    "江门市": "Jiangmen City",
    "湛江市": "Zhanjiang City",
    "茂名市": "Maoming City",
    "肇庆市": "Zhaoqing City",
    "惠州市": "Huizhou City",
    "梅州市": "Meizhou City",
    "汕尾市": "Shanwei City",
    "河源市": "Heyuan City",
    "阳江市": "Yangjiang City",
    "清远市": "Qingyuan City",
    "东莞市": "Dongguan City",
    "中山市": "Zhongshan City",
    "潮州市": "Chaozhou City",
    "揭阳市": "Jieyang City",
    "云浮市": "Yunfu City",
    "南宁市": "Nanning City",
    "柳州市": "Liuzhou City",
    "桂林市": "Guilin City",
    "梧州市": "Wuzhou City",
    "北海市": "Beihai City",
    "防城港市": "Fangchenggang City",
    "钦州市": "Qinzhou City",
    "贵港市": "Guigang City",
    "玉林市": "Yulin City",
    "百色市": "Baise City",
    "贺州市": "Hezhou City",
    "河池市": "Hechi City",
    "来宾市": "Laibin City",
    "崇左市": "Chongzuo City",
    "龙胜市": "Longsheng City",
    "阳朔市": "Yangshuo City",
    "贵阳市": "Guiyang City",
    "六盘水市": "Liupanshui City",
    "遵义市": "Zunyi City",
    "安顺市": "Anshun City",
    "铜仁地区": "Tongren District",
    "黔西南布依族苗族自治州": "Qianxinan Buyi and Miao Autonomous Prefecture",
    "毕节地区": "Bijie Area",
    "黔南布依族苗族自治州": "Qiannan Buyi and Miao Autonomous Prefecture",
    "黔东南苗族侗族自治州": "Qiandongnan Miao-Dong Autonomous Prefecture",
    "海口市": "Haikou City",
    "三亚市": "Sanya City",
    "五指山市": "Wuzhishan City",
    "琼海市": "Qionghai City",
    "儋州市": "Danzhou City",
    "文昌市": "Wenchang City",
    "万宁市": "Wanning City",
    "东方市": "Dongfang City",
    "澄迈县": "Chengmai County",
    "定安县": "Ding'an County",
    "屯昌县": "Tunchang County",
    "临高县": "Lingao County",
    "保亭县": "Baoting County",
    "乐东县": "Ledong County",
    "琼中县": "Qiongzhong County",
    "白沙县": "Baisha County",
    "昌江县": "Changjiang County",
    "陵水县": "Lingshui County",
    "石家庄市": "Shijiazhuang City",
    "唐山市": "Tangshan City",
    "秦皇岛市": "Qinhuangdao City",
    "邯郸市": "Handan City",
    "邢台市": "Xingtai City",
    "保定市": "Baoding City",
    "张家口市": "Zhangjiakou City",
    "承德市": "Chengde City",
    "沧州市": "Cangzhou City",
    "廊坊市": "Langfang City",
    "衡水市": "Hengshui City",
    "涿州市": "Zhuozhou City",
    "郑州市": "Zhengzhou City",
    "开封市": "Kaifeng City",
    "洛阳市": "Luoyang City",
    "平顶山市": "Pingdingshan City",
    "安阳市": "Anyang City",
    "鹤壁市": "Hebi City",
    "新乡市": "Xinxiang City",
    "濮阳市": "Puyang City",
    "焦作市": "Jiaozuo City",
    "许昌市": "Xuchang City",
    "漯河市": "Luohe City",
    "三门峡市": "Sanmenxia City",
    "南阳市": "Nanyang City",
    "商丘市": "Shangqiu City",
    "信阳市": "Xinyang City",
    "周口市": "Zhoukou City",
    "驻马店市": "Zhumadian City",
    "济源市": "Jiyuan City",
    "哈尔滨市": "Harbin City",
    "齐齐哈尔市": "Qiqihar City",
    "鸡西市": "Jixi City",
    "鹤岗市": "Hegang City",
    "双鸭山市": "Shuangyashan City",
    "大庆市": "Daqing City",
    "伊春市": "Yichun City",
    "佳木斯市": "Jiamusi City",
    "七台河市": "Qitaihe City",
    "牡丹江市": "Mudanjiang City",
    "黑河市": "Heihe City",
    "绥化市": "Suihua City",
    "大兴安岭地区": "Daxing'anling Prefecture",
    "武汉市": "Wuhan City",
    "十堰市": "Shiyan City",
    "宜昌市": "Yichang City",
    "荆门市": "Jingmen City",
    "孝感市": "Xiaogan City",
    "随州市": "Suizhou City",
    "襄阳市": "Xiangyang City",
    "咸宁市": "Xianning City",
    "荆州市": "Jingzhou City",
    "黄冈市": "Huanggang City",
    "仙桃市": "Xiantao City",
    "鄂州市": "Ezhou City",
    "恩施市": "Enshi City",
    "长沙市": "Changsha City",
    "株洲市": "Zhuzhou City",
    "湘潭市": "Xiangtan City",
    "衡阳市": "Hengyang City",
    "邵阳市": "Shaoyang City",
    "岳阳市": "Yueyang City",
    "益阳市": "Yiyang City",
    "郴州市": "Chenzhou City",
    "永州市": "Yongzhou City",
    "怀化市": "Huaihua City",
    "娄底市": "Loudi City",
    "湘西土家族苗族自治州": "Xiangxi Tujia and Miao Autonomous Prefecture",
    "常德市": "Changde City",
    "张家界市": "Zhangjiajie City",
    "凤凰市": "Fenghuang City",
    "浏阳县": "Liuyang County",
    "长春市": "Changchun City",
    "吉林市": "Jilin City",
    "四平市": "Siping City",
    "辽源市": "Liaoyuan City",
    "通化市": "Tonghua City",
    "白山市": "Baishan City",
    "白城市": "Baicheng City",
    "延边朝鲜族自治州": "Yanbian Korean Autonomous Prefecture",
    "松原市": "Songyuan City",
    "南京市": "Nanjing City",
    "无锡市": "Wuxi City",
    "徐州市": "Xuzhou City",
    "常州市": "Changzhou City",
    "苏州市": "Suzhou City",
    "南通市": "Nantong City",
    "连云港市": "Lianyungang City",
    "淮安市": "Huaian City",
    "盐城市": "Yancheng City",
    "扬州市": "Yangzhou City",
    "镇江市": "Zhenjiang City",
    "泰州市": "Taizhou City",
    "宿迁市": "Suqian City",
    "昆山市": "Kunshan City",
    "吴江市": "Wujiang City",
    "常熟市": "Changshu City",
    "江阴市": "Jiangyin City",
    "仪征市": "Yizheng City",
    "宜兴市": "Yixing City",
    "张家港市": "Zhangjiagang City",
    "太仓市": "Taicang City",
    "南昌市": "Nanchang City",
    "新余市": "Xinyu City",
    "鹰潭市": "Yingtan City",
    "赣州市": "Ganzhou City",
    "吉安市": "Ji'an City",
    "宜春市": "Yichun City",
    "抚州市": "Fuzhou City",
    "上饶市": "Shangrao City",
    "景德镇市": "Jingdezhen City",
    "萍乡市": "Pingxiang City",
    "九江市": "Jiujiang City",
    "婺源县": "Wuyuan County",
    "沈阳市": "Shenyang City",
    "大连市": "Dalian City",
    "鞍山市": "Anshan City",
    "抚顺市": "Fushun City",
    "本溪市": "Benxi City",
    "丹东市": "Dandong City",
    "锦州市": "Jinzhou City",
    "营口市": "Yingkou City",
    "阜新市": "Fuxin City",
    "辽阳市": "Liaoyang City",
    "盘锦市": "Panjin City",
    "铁岭市": "Tieling City",
    "朝阳市": "Chaoyang City",
    "葫芦岛市": "Huludao City",
    "呼和浩特市": "Hohhot City",
    "包头市": "Baotou City",
    "乌海市": "Wuhai City",
    "赤峰市": "Chifeng City",
    "通辽市": "Tongliao City",
    "鄂尔多斯市": "Ordos City",
    "呼伦贝尔市": "Hulunbuir City",
    "巴彦淖尔市": "Bayannur City",
    "乌兰察布市": "Ulaanchab City",
    "兴安盟": "Xing'an League",
    "锡林郭勒盟": "Xilin Gol League",
    "阿拉善盟": "Alxa League",
    "满洲里市": "Manzhouli City",
    "银川市": "Yinchuan City",
    "石嘴山市": "Shizuishan City",
    "吴忠市": "Wuzhong City",
    "固原市": "Guyuan City",
    "中卫市": "Zhongwei City",
    "西宁市": "Xining City",
    "海东地区": "Haidong District",
    "海北藏族自治州": "Haibei Tibetan Autonomous Prefecture",
    "黄南藏族自治州": "Huangnan Tibetan Autonomous Prefecture",
    "果洛藏族自治州": "Golog Tibetan Autonomous Prefecture",
    "玉树藏族自治州": "Yushu Tibetan Autonomous Prefecture",
    "海西蒙古族藏族自治州": "Haixi Mongol and Tibetan Autonomous Prefecture",
    "海南藏族自治州": "Hainan Tibetan Autonomous Prefecture",
    "济南市": "Jinan City",
    "青岛市": "Qingdao City",
    "淄博市": "Zibo City",
    "枣庄市": "Zaozhuang City",
    "东营市": "Dongying City",
    "烟台市": "Yantai City",
    "潍坊市": "Weifang City",
    "济宁市": "Jining City",
    "泰安市": "Tai'an City",
    "威海市": "Weihai City",
    "日照市": "Rizhao City",
    "莱芜市": "Laiwu City",
    "临沂市": "Linyi City",
    "德州市": "Dezhou City",
    "聊城市": "Liaocheng City",
    "滨州市": "Binzhou City",
    "菏泽市": "Heze City",
    "安丘市": "Anqiu City",
    "滕州市": "Tengzhou City",
    "太原市": "Taiyuan City",
    "大同市": "Datong City",
    "阳泉市": "Yangquan City",
    "长治市": "Changzhi City",
    "晋城市": "Jincheng City",
    "朔州市": "Shuozhou City",
    "晋中市": "Jinzhong City",
    "运城市": "Yuncheng City",
    "忻州市": "Xinzhou City",
    "临汾市": "Linfen City",
    "吕梁市": "Lvliang City",
    "古交市": "Gujiao City",
    "清徐县": "Qingxu County",
    "西安市": "Xi'an City",
    "铜川市": "Tongchuan City",
    "宝鸡市": "Baoji City",
    "咸阳市": "Xianyang City",
    "渭南市": "Weinan City",
    "延安市": "Yan'an City",
    "汉中市": "Hanzhong City",
    "榆林市": "Yulin City",
    "安康市": "Ankang City",
    "商洛市": "Shangluo City",
    "杨陵区": "Yangling District",
    "成都市": "Chengdu City",
    "自贡市": "Zigong City",
    "攀枝花市": "Panzhihua City",
    "泸州市": "Luzhou City",
    "德阳市": "Deyang City",
    "绵阳市": "Mianyang City",
    "遂宁市": "Suining City",
    "南充市": "Nanchong City",
    "宜宾市": "Yibin City",
    "达州市": "Dazhou City",
    "巴中市": "Bazhong City",
    "阿坝藏族羌族自治州": "Aba Tibetan and Qiang Autonomous Prefecture",
    "凉山彝族自治州": "Liangshan Yi Autonomous Prefecture",
    "广元市": "Guangyuan City",
    "内江市": "Neijiang City",
    "乐山市": "Leshan City",
    "眉山市": "Meishan City",
    "广安市": "Guang'an City",
    "雅安市": "Ya'an City",
    "资阳市": "Ziyang City",
    "甘孜藏族自治州": "Ganzi Tibetan Autonomous Prefecture",
    "九寨沟": "Jiuzhaigou",
    "万州区": "Wanzhou District",
    "永川区": "Yongchuan District",
    "江津区": "Jiangjin District",
    "合川区": "Hechuan District",
    "绵竹市": "Mianzhu City",
    "南部县": "Nanbu County",
    "阆中市": "Langzhong City",
    "江油县": "Jiangyou County",
    "什邡市": "Shifang City",
    "广汉市": "Guanghan City",
    "拉萨市": "Lhasa City",
    "昌都地区": "Qamdo Prefecture",
    "山南地区": "Shannan Prefecture",
    "日喀则地区": "Rikaze Prefecture",
    "那曲地区": "Nagqu Region",
    "阿里地区": "Ngari Area",
    "林芝地区": "Linzhi Area",
    "乌鲁木齐市": "Urumqi City",
    "克拉玛依市": "Kelamayi City",
    "吐鲁番地区": "Turpan Region",
    "哈密地区": "Hami Region",
    "昌吉回族自治州": "Changji Hui Autonomous Prefecture",
    "伊犁哈萨克自治州": "Ili Kazakh Autonomous Prefecture",
    "塔城地区": "Tacheng Area",
    "博尔塔拉蒙古自治州": "Bortala Mongol Autonomous Prefecture",
    "巴音郭楞蒙古自治州": "Bayin'guoleng Mongol Autonomous Prefecture",
    "阿克苏地区": "Aksu Prefecture",
    "克孜勒苏柯尔克孜自治州": "Kizilsu-Kirghiz Autonomous Prefecture",
    "喀什地区": "Kashgar Region",
    "和田地区": "Hotan Region",
    "阿勒泰地区": "Altay Region",
    "石河子市": "Shihezi City",
    "阜康市": "Fukang City",
    "奎屯市": "Kuitun City",
    "独山子区": "Dushanzi District",
    "库尔勒市": "Korla City",
    "乌苏市": "Wusu City",
    "昆明市": "Kunming City",
    "曲靖市": "Qujing City",
    "玉溪市": "Yuxi City",
    "保山市": "Baoshan City",
    "昭通市": "Zhaotong City",
    "丽江市": "Lijiang City",
    "临沧市": "Lincang City",
    "楚雄彝族自治州": "Chuxiong Yi Autonomous Prefecture",
    "红河哈尼族彝族自治州": "Honghe Hani and Yi Autonomous Prefecture",
    "文山壮族苗族自治州": "Wenshan Zhuang and Miao Autonomous Prefecture",
    "西双版纳傣族自治州": "Xishuangbanna Dai Autonomous Prefecture",
    "大理白族自治州": "Dali Bai Autonomous Prefecture",
    "怒江傈僳族自治州": "Nujiang Lisu Autonomous Prefecture",
    "迪庆藏族自治州": "Diqing Tibetan Autonomous Prefecture",
    "普洱市": "Pu'er City",
    "德宏傣族景颇族自治州": "Dehong Dai and Jingpo Autonomous Prefecture",
    "杭州市": "Hangzhou City",
    "宁波市": "Ningbo City",
    "温州市": "Wenzhou City",
    "嘉兴市": "Jiaxing City",
    "湖州市": "Huzhou City",
    "绍兴市": "Shaoxing City",
    "金华市": "Jinhua City",
    "舟山市": "Zhoushan City",
    "台州市": "Taizhou City",
    "丽水市": "Lishui City",
    "衢州市": "Quzhou City",
    "慈溪市": "Cixi City",
    "义乌市": "Yiwu City",
    "象山城": "Xiangshan City",
    "乌镇市": "Wuzhen City",
    "瑞安市": "Rui'an City",
    "乐清市": "Yueqing City",
    "余姚市": "Yuyao City",
    "长兴市": "Changxing City",
    "德清市": "Deqing City",
    "安吉市": "Anji City",
    "永康市": "Yongkang City",
    "兰溪市": "Lanxi City",
    "东阳市": "Dongyang City",
    "海宁市": "Haining City",
    "平湖市": "Pinghu City",
    "平阳县": "Pingyang County",
    "苍南县": "Cangnan County",
    "其他": "Other",
    "400回访查询": "400 Return visit inquiry",
    "系统日志": "System logs",
    "维修服务单": "Repair Service Order",
    "处理进度：": "Progress:",
    "处理进度": "Progress",
    "维修单数据导出失败，后台发生错误。": "Repair order data export failed, an error occurred in the background.",
    "维修单获取失败": "Failed to get repair order",
    "请选择需要修改的维修单数据": "Please select the repair order data that needs to be modified",
    "删除维修单": "Delete Repair Order",
    "确定要删除维修单【{{:a}}】吗？": "Sure you want to delete the repair order 【{{:a}}】?",
    "请选择要删除的维修单": "Please select the repair order to delete",
    "请选择需要导入的文件": "Please select the files to import",
    "请选择Excel文件": "Please select Excel file",
    "批量上传成功": "Batch Upload Successful",
    "批量上传失败": "Batch Upload Failed",
    "工单批量上传出错": "Work Order Batch Upload Error",
    "{{:a}}等工单信息导入出错": "Error importing work order information such as {{:a}}",
    "未选中可结账的维修单": "Unchecked checkout-ready repair orders",
    "请选择要作废的维修单": "Please select the repair order to be cancelled",
    "操作失败，请稍候再试": "The operation failed, please try again later",
    "检查网络连接，请稍候再试": "Checking the network connection, please try again later",
    "创建日期": "Created Date",
    "创建人": "Created by",
    "状态": "Status",
    "回访": "Return visit",
    "未回访": "Not Returned",
    "已回访": "Returned",
    "已完成": "Completed",
    "接单": "Order Taking",
    "已派单": "Dispatched",
    "已预约上门": "Walk-in appointment booked",
    "维修完成": "Repair completed",
    "已通知取货": "Pick-up notified",
    "用户已取货": "User Picked Up",
    "维修站已回访": "The service station has returned",
    "总部已回访": "Headquarters has returned",
    "待结账审核": "Pending Closing Audit",
    "待结账审批": "Pending Closing Approval",
    "已批准结账": "Approved Closing",
    "已作废": "Cancelled",
    "已批准": "Approved",
    "已开票": "Invoiced",
    "已报销": "Reimbursed",
    "已否决": "Vetoed",
    "已审核": "Audited",
    "申请中": "Pending",
    "取消维修站回访": "Cancellation of service station return",
    "取消总部回访": "Cancellation of headquarters return",
    "提交主站审核": "Submitted for master review",
    "退回修改": "Return to Modify",
    "公司已开票": "Company Invoiced",
    "经销商已报销": "Distributor Reimbursed",
    "申请作废": "Application for cancellation",
    "待确认": "To be confirmed",
    "取消作废申请": "Cancellation of nullification request",
    "保存维修单": "Save Repair Order",
    "派单处理": "Dispatch Processing",
    "预约上门": "Appointment to visit",
    "通知取货": "Notify pickup",
    "用户取货": "User pickup",
    "待继续回访": "To be continued return",
    "完成回访": "Complete the return visit",
    "申请结账": "Apply for checkout",
    "审核结账": "Audit checkout",
    "批准结账": "Approve checkout",
    "否决处理": "Veto processing",
    "作废处理": "Void disposal",
    "开票确认": "Invoicing Confirmation",
    "报销确认": "Reimbursement Confirmation",
    "主站审核": "Master Audit",
    "审核": "Audit",
    "批准": "Approved",
    "否决": "Veto",
    "结账单": "Claim",
    "结账单编号": "Claim No",
    "结账单状态": "Claim Status",
    "派单时间": "Dispatch Time",
    "上门日期": "Visit date",
    "完成日期": "Finish date",
    "是否保修": "Warranty or not",
    "维修性质": "Repair type",
    "维修费": "Repair fee",
    "维修完成时间": "Syetem complete date",
    "SAP系统单号": "SAP System Order No.",
    "操作": "Operation",
    "作废原因": "Reason for cancellation",
    "重复单号：": "Duplicate Order No.: ",
    "说明：": "Description: ",
    "说明": "Description",
    "保修依据时间": "Purchase date",
    "超时查询": "Timeout Queries",
    "预约时间超时": "Appointment Timeout",
    "上门服务超时": "Home service overrun",
    "维修完成超时": "Repair Finish Timeout",
    "维修站回访超时": "Service Station Return Timeout",
    "总部回访超时": "HQ Return Timeout",
    "客户电话": "Customer Phone",
    "各维修站": "Service stations",
    "待作废": "To be cancelled",
    "批量作废": "Batch cancellation",
    "待审批": "Pending Approval",
    "待分配": "To be allocated",
    "待维修": "Pending repair",
    "待回访": "To be returned",
    "待预约": "To be appointed",
    "待上门": "To be visited",
    "待通知取货": "To be notified of pick-up",
    "待取货": "To be picked up",
    "待结账": "To be checkout",
    "待顾客寄出": "To be sent",
    "取货待维修": "Pick-up for repair",
    "待顾客送修": "To be delivery",
    "结账处理": "Checkout processing",
    "高级查询": "Advanced queries",
    "手动修改状态": "手動修改狀態",
    "批量上传": "Batch upload",
    "文件": "File",
    "浏览": "Browse",
    "导出数据到SAP": "Export Data to SAP",
    "导出": "Export",
    "开始日期": "Start Date",
    "结束日期": "End Date",
    "请输入处理意见": "Please enter a disposition",
    "过滤条件": "Filter Criteria",
    "查询": "Query",
    "复位": "Reset",
    "用户姓名": "Customer Name",
    "接单日期": "Receive date",
    "公司名称": "Company(customer)",
    "移动电话": "Mobile Phone No",
    "固定电话": "Phone No",
    "按": "Filter by ",
    "过滤": " ",
    "开始时间": "Start Time",
    "结束时间": "End Time",
    "工作单号": "Order No.",
    "请选择站点": "Please select a station",
    "接修人": "Received by",
    "YYYY年MM月": "YYYY-MM",
    "YYYY年MM月DD日": "YYYY-MM-DD",
    "YYYY年MM月DD日 HH:mm": "YYYY-MM-DD HH:mm",
    "YYYY年MM月DD日 HH:mm:ss": "YYYY-MM-DD HH:mm:ss",
    "该选项保修一年": "The option is guaranteed for one year",
    "该选项保修两年": "The option is guaranteed for two years",
    "请选择实际完成日期": "Please select the actual date of completion",
    "请选择预约上门日期": "Please select a date for your home appointment",
    "实际完成时间": "Actual complete date",
    "预约上门日期": "Appointment Date",
    "完成回访日期": "Completion date of return visit",
    "请选择维修站": "Please select a service station",
    "回访注释：未成功接通电话，请点击下方待继续回访；每个工单最多能被回访三次产品。": "Return Note: Unsuccessful call, please click below to be continued; each work order can be called back up to three products.",
    "成功接通后被挂断或不愿评分请填0": "Hanged up after successful connection or do not wish to rate please fill in 0",
    "前四项，评分范围1-10。": "The first four items, rated on a scale of 1-10.",
    "推荐值，评分范围1-10。": "Recommended value, rating range 1-10.",
    "正确": "Correct",
    "缺地线": "Missing ground",
    "缺零线": "Missing zero line",
    "缺火线": "Lack of Fireline",
    "火地线错位": "Misalignment of fire and earth wires",
    "火零线错位": "salignment of fire and zero wire",
    "相地错并缺地": "The phase is wrong and missing",
    "国内销售": "Domestic Sales",
    "海外旗舰店": "Overseas Flagship Stores",
    "海外分销商": "Overseas Distributor",
    "港澳销售": "Hong Kong and Macau Sale",
    "海外水货": "seas parallel imports",
    "行货过保-过保修期": "Linear Goods Out of Warranty - Out of warranty period",
    "行货过保-保内非质量问题": "Linear Goods Out of Warranty - Non-Quality Problems Under Warranty",
    "行货过保-购买配件": "Linear Goods Out of Warranty - Buy accessories",
    "奖励": "Reward",
    "处罚": "Penalties",
    "惩罚": "Punishment",
    "顾客来电表扬": "Customer caller commendation",
    "特殊贡献奖": "Special Contribution Award",
    "工单延误": "Ticket Delay",
    "服务态度": "Service Attitude",
    "维修质量": "Repair Quality",
    "重复建单": "Duplicate Build Order",
    "顾客取消/无时间": "Customer Cancellation/No Time",
    "顾客取消/无时间/无法联系上顾客": "Customer cancellation/No time/Cannot reach customer",
    "顾客自行解决": "Customer self-settlement",
    "订单编号：": "Order No:",
    "订单编号": "Order No",
    "订单号": "Order No",
    "手机号：": "Mobile Phone No:",
    "手机号": "Mobile Phone No",
    "购买型号：": "Purchase Model:",
    "购买数量：": "Purchase Quantity:",
    "购买平台：": "Purchase Platform:",
    "购买时间：": "Purchase Time:",
    "购买时间": "Purchase Time",
    "注册时间：": "Registration Time:",
    "注册时间": "Registration Time",
    "失效时间": "Expiry time",
    "凭证失效时间": "Voucher expiration time",
    "购买型号": "Purchase Model",
    "购买数量": "Purchase Quantity",
    "购买平台": "Purchase Platform",
    "请输入搜索条件": "Please enter the search criteria",
    "获取数据失败": "Failed to fetch data",
    "请选择服务类型!": "Please select the type of service!",
    "请填写用户姓名!": "Please fill in user name!",
    "请填写用户联系电话!": "Please fill in the user contact phone number!",
    "请选择用户居住省份!": "Please select the user's province of residence!",
    "请选择品牌!": "Please select a brand!",
    "请填写预计完成日期!": "Please fill in the expected finish date!",
    "请填写故障描述!": "Please fill in the trouble description!",
    "请填写机器型号!": "Please fill in the product!",
    "请填写有效的机器型号!": "Please fill in a valid product!",
    "请选择机器名称!": "Please select product description!",
    "请填写随机附件!": "Please fill in the accssary!",
    "请填写用户联系地址!": "Please fill in the user contact address!",
    "请填写颜色": "Please fill in the colour",
    "请选择是否送货到家": "Please choose whether to deliver to your home",
    "请选择产品性质!": "Please select product nature!",
    "请选择保修依据!": "Please select confirm condition!",
    "请选择正确的保修依据!": "Please select the correct confirm condition!",
    "请选择维修类型!": "Please select the method of repair!",
    "请填写检查记录!": "Please fill in the check record!",
    "请填写维修记录!": "Please fill in the repair record!",
    "请填写服务单编号!": "Please fill in the Sheet No!",
    "请填写7位服务单编号!": "Please fill in the 7-digit Sheet No!",
    "请填写上门公里数!": "Please fill in the distance!",
    "请填写上门费用!": "Please fill in the transpotation cost!",
    "上门费用超过标准!": "The transpotation cost was more than standard!",
    "请填写机器编号! (需17-19位)": "Please fill in the machine No! (17-19 digits required)",
    "请填写18位机器编号!": "Please fill in the 18-digit machine No!",
    "请填写19位机器编号!": "Please fill in the 19-digit machine No!",
    "请填写制作杯数!": "Please fill in the Used cup!",
    "请填写除垢次数!": "Please fill in the Desclae No!",
    "请填写使用水量!": "Please fill in the Water used!",
    "请填写蒸汽次数!": "Please fill in the Steam times!",
    "请填写蒸汽水量!": "Please fill in the Steam water used!",
    "请填写咖啡水升数!": "Please fill in the Coffee litres!",
    "请填写蒸汽水升数!": "Please fill in the Liters of steam!",
    "请填写热水升数!": "Please fill in the Liters of hot water!",
    "请填写饮品总杯数!": "Please fill in the Total number of drinks!",
    "请填写饮品总升数!": "Please fill in the Total litres of beverages!",
    "请填写机器编号!": "Please fill in the machine No!",
    "请填写完整的零件信息!": "Please fill in complete part information!",
    "不是有效的零件编码!": "Not a valid spare parts No!",
    "请添加零件信息!": "Please add part information!",
    "请填写维修费用!": "Please fill in the labor cost!",
    "请选择延保数据!": "Please select extended warranty data!",
    "请填写其它费用明细!": "Please fill in other cost details!",
    "请填写其它费用!": "Please fill in other cost!",
    "请上传机身编号": "Please upload fuselage No",
    "请上传插座检查照片": "Please upload photo of socket check",
    "请上传纸质工单照片": "Please upload photos of paper ticket",
    "请选择插座故障": "Please select socket fault",
    "需总部确认": "Headquarters confirmation required",
    "请选择保修日期": "Please select a warranty date",
    "请上传保修凭证": "Please upload warranty voucher",
    "保修凭证": "Warranty Voucher",
    "请输入开票单位": "Please enter the invoicing unit",
    "请上传订单照片": "Please upload order photo",
    "请输入保修订单信息": "Please enter warranty order information",
    "请输入单号": "Please enter the order No",
    "发票日期不符合": "Invoice date does not match",
    "订单日期不符合": "Order date does not match",
    "出仓日期不符合": "Out of date from warehouse does not match",
    "请选择故障分类": "Please select the fault classification",
    "请选择完成日期": "Please select the finish date",
    "完成日期早于接单日期!": "Finish date is earlier than the receive date!",
    "请选择预约上门时间": "Please select the appointment date",
    "请填写产品推荐值评分!": "Please fill in the product recommendation value rating!",
    "请填写产品满意度评分!": "Please fill in the product satisfaction rating!",
    "请填写售后服务评分!": "Please fill in the after sales service rating!",
    "请填写维修速度评分!": "Please fill out the speed of repair rating!",
    "请填写维修质量评分!": "Please fill out the repair quality rating!",
    "请选择作废理由!": "Please select a reason for cancellation!",
    "请填写重复单号!": "Please fill in the duplicate order No!",
    "请检查重复单号格式!": "Please check duplicate order No format!",
    "重复单号不能与当前系统单号相同!": "Duplicate order No cannot be the same as the current system order No!",
    "请填写作废说明!": "Please fill in the cancellation description!",
    "预约上门时间发生变化": "The appointment date has changed",
    "改到": "change to",
    "工单数据已变更，请先获取最新工单": "Ticket data has been changed, please get the latest ticket first",
    "请选择一条订单": "Please select an order",
    "订单时间(请设置单元格格式，分类为期，类型为yyyy/mm/dd)": "Order time (please set cell format, category duration, type yyyy/mm/dd)",
    "保修卡号": "Warranty Card No",
    "姓名": "Name",
    "公司": "Company",
    "电话": "Phone",
    "性质": "Nature",
    "接单时间": "Receive Date",
    "地址": "Address",
    "只能输入 {{:a}}": "Only {{:a}} can be entered.",
    "只能输入": "Input only ",
    "只能输入数字": "Input digits only",
    "只能输入英文字母或数字": "Only English letters or numbers can be input",
    "点击查看": "Click to view",
    "搜索": "Search",
    "确认人": "Confirm person",
    "开票单位：": "Invoice by:",
    "开票单位": "Invoice by",
    "回访内容": "Return visit content",
    "现在机器能正常工作吗？还有什么可以为您服务吗？": "Is the machine working properly now? Is there anything else I can do for you?",
    "评分：": "Rating:",
    "评分": "Rating",
    "维修速度": "Repair Speed",
    "机器修了多长时间？维修速度满意吗？满分10分可以打多少分？": "How long did it take to repair the machine? Was the speed of repair satisfactory? How many points out of 10 can be scored?",
    "售后服务": "After-sales Service",
    "维修师傅在维修过程服务态度还满意吗？10分满分可以打多少分？": "Repair master in the repair process service attitude is still satisfied? 10 points out of 10 can play how many points?",
    "产品满意度": "Product Satisfaction",
    "机器使用到现在对产品还满意吗？满分10分可以打多少？": "How satisfied are you with the product so far with the machine? How much can I score out of 10?",
    "产品推荐值": "Product Recommendation Value",
    "如果要介绍给朋友，10分一定会推荐，1分不会推荐，您可以打多少分？": "If you were to introduce it to a friend, 10 points would definitely recommend it, 1 point would not recommend it, what score can you give?",
    "提交OA": "Submission of OA",
    "指派维修站": "Assigned Service Station",
    "取货确认": "Pickup Confirmation",
    "总部作废": "Headquarters nullified",
    "选择订单": "Select Order",
    "选择": "Select",
    "请选择用户信息": "Please select user information",
    "用户信息": "User information",
    "全部信息": "All informatio",
    "电话号码": "Phone No",
    "作废理由": "Cancellation Reason",
    "重复单号": "Duplicate order No",
    "作废说明": "Cancellation Description",
    "显示第{{:a}}到第{{:b}}条记录，总共{{:c}}条记录": "Displays records {{:a}} through {{:b}}, for a total of {{:c}} records.",
    "很粗": "Very thick",
    "略粗": "Slightly thicker",
    "正常": "Normal",
    "略细": "Slightly fine",
    "很细": "Very fine",
    "不成形": "Unformed",
    "略松散": "Slightly loose",
    "成形": "Shaping",
    "市场部主任": "Marketing Director",
    "产品经理": "Product Manager",
    "市场营销部助理": "Marketing Assistant",
    "市场营销部经理": "Marketing Manager",
    "上班": "Going to work",
    "出差": "Business trip",
    "休息": "Rest",
    "病假": "Sick leave",
    "事假": "Personal leave",
    "婚假": "Marriage leave",
    "产假": "Maternity leave",
    "丧假": "Bereavement leave",
    "年假": "Annual leave",
    "其它": "Other",
    "展览用": "Exhibition",
    "测试用": "Test",
    "免费": "Free",
    "收费": "Charge",
    "新增潜在用户": "New Potential User",
    "单次个人用户": "Single Individual User",
    "单次公司用户": "Single Corporate User",
    "合同用户": "Contract user",
    "优惠券用户": "CouponUser",
    "微信小程序用户": "WeChat Applet User",
    "维修站主管": "Service station supervisor",
    "维修部助理": "Maintenance Department Assistant",
    "维修部经理": "Maintenance Manager",
    "系统管理员": "Administrator",
    "数据查询员": "Data Enquiry Clerk",
    "旧件盘点员": "Old Parts Inventory Clerk",
    "订单数据导入员": "Order Data Importer",
    "京东": "JingDong",
    "亚马逊": "Amazon",
    "我买网": "IBuy.com",
    "苏宁易购": "SUNING",
    "国美在线": "Gome Online",
    "一号店": "Shop One",
    "淘宝": "Taobao",
    "电视购物": "TV shopping",
    "上海技嘉咖啡有限公司": "上海技嘉咖啡有限公司",
    "浙江速网电子商务有限公司": "浙江速網電子商務有限公司",
    "上海埃普咖啡有限公司": "上海埃普咖啡有限公司",
    "南京易佰购电子科技有限公司": "南京易佰購電子科技有限公司",
    "南京美富隆贸易有限公司": "南京美富隆貿易有限公司",
    "上海创龙贸易有限公司": "上海創龍貿易有限公司",
    "北京融欣伟业科技发展有限公司": "北京融欣偉業科技發展有限公司",
    "北京和美佳业经贸有限公司": "北京和美佳業經貿有限公司",
    "北京京东世纪信息技术有限公司": "北京京東世紀信息技術有限公司",
    "江苏圆周电子商务有限公司上海分公司": "江蘇圓周電子商務有限公司上海分公司",
    "广州晶东贸易有限公司": "廣州晶東貿易有限公司",
    "上海圆迈贸易有限公司": "上海圓邁貿易有限公司",
    "成都京东世纪贸易有限公司": "成都京東世紀貿易有限公司",
    "武汉京东世纪贸易有限公司": "武漢京東世紀貿易有限公司",
    "昆山京东尚信贸易有限公司": "崑山京東尚信貿易有限公司",
    "北京京东信息技术有限公司": "北京京東信息技術有限公司",
    "沈阳京东世纪贸易有限公司": "瀋陽京東世紀貿易有限公司",
    "西安华讯得贸易有限公司": "西安華訊得貿易有限公司",
    "北京世纪卓越信息技术有限公司": "北京世紀卓越信息技術有限公司",
    "中粮海优（北京）有限公司": "中糧海優（北京）有限公司",
    "南京亿飞科技有限公司": "南京億飛科技有限公司",
    "南京宜美阁网络科技有限公司": "南京宜美閣網絡科技有限公司",
    "南京睿嘉融信电子科技有限公司": "南京睿嘉融信電子科技有限公司",
    "南京易柏通商贸有限责任公司": "南京易柏通商貿有限責任公司",
    "纽海电子商务（上海）有限公司": "紐海電子商務（上海）有限公司",
    "上海伊维萨国际贸易有限公司": "上海伊維薩國際貿易有限公司",
    "上海优骐商贸有限公司": "上海優騏商貿有限公司",
    "上海舒阳贸易有限公司": "上海舒陽貿易有限公司",
    "上海迪慧经贸有限公司": "上海迪慧經貿有限公司",
    "上海浦美电器有限公司": "上海浦美電器有限公司",
    "上海娄江经贸有限公司": "上海婁江經貿有限公司",
    "上海广龙家电有限公司": "上海廣龍家電有限公司",
    "城市": "City",
    "省份": "Province",
    "请选择开始日期和结束日期": "Please select a start date and end date",
    "请先统计数据": "Please count the data first",
    "城市维修量统计": "City maintenance statistics",
    "统计": "Statistics",
    "工单时效完成": "Work Order Timescale Completion",
    "工单时效未完成": "Work Order Time Limit Not Completed",
    "城市维修量": "City maintenance volume",
    "维修站维修费": "Service Station Repair Costs",
    "海外维修费用": "Overseas Repair Costs",
    "国内维修费用": "Domestic Repair Costs",
    "总计": "Total",
    "海外维修费用统计": "Overseas Repair Cost Statistics",
    "国内维修费用统计": "Domestic Repair Cost Statistics",
    "零件费用": "Spare parts cost",
    "其它费用": "Other Cost",
    "费用合计": "Total Cost",
    "上门费用汇总": "Summary of home visit costs",
    "零件费用汇总": "Parts Cost Summary",
    "其它费用汇总": "Other Cost Summary",
    "维修费用汇总": "ORepairther Cost Summary",
    "维修站维修费统计": "Repair cost statistics for service stations",
    "工单数": "Number of work orders",
    "维修费百分比": "Percentage of repair cost",
    "汇总": "Summary",
    "工单时效统计": "Work Order Lifetime Statistics",
    "工单时效统计完成": "Work Order Timescale Statistics Completed",
    "工单时效[完成]": "Work Order Timeliness [Completed]",
    "工单时效统计[完成]": "Work Order Timeliness Statistics [Completed]",
    "工单时效统计未完成": "Work Order Timing Statistics Not Completed",
    "工单时效[未完成]": "Work Order Timeliness [Not Completed]",
    "工单时效统计[未完成]": "Work Order Timeliness Statistics [Not Completed]",
    "当天内维修完成": "Repair completed within the same day",
    "当天内": "Within the same day",
    "3天内": "Within 3 days",
    "7天内": "Within 7 days",
    "14天内": "Within 14 days",
    "30天内": "Within 30 days",
    "超过30天": "More than 30 days",
    "品类选择": "Category Selection",
    "全部工单": "All Work Orders",
    "仅自动咖啡机": "Automatic Coffee Maker Only",
    "不包含自动咖啡机": "Automatic Coffee Maker not included",
    "请选择类型": "Please select a type",
    "全部": "All",
    "加载失败，请重试": "Failed to load, please retry",
    "结账单获取失败": "Failed to get checkout form",
    "请选择结账单": "Please select a checkout form",
    "请稍候再试": "Please try again later",
    "手动改指定结账单": "Manually change the specified checkout form",
    "结账单加载中...": "Checkout form loading...",
    "无可结账的维修单，请刷新维修单列表后重试": "There are no repair orders available for checkout, please refresh the repair order list and retry",
    "导出更换整机": "Export Complete Product",
    "导出旧件数": "Export Old Pieces",
    "结算单配件统计": "Checkout Form Parts Statistics",
    "提交": "Submit",
    "修改金额": "Modified Amount",
    "结账申请单": "Checkout Request Form",
    "创建人：": "Created By:",
    "创建日期：": "Created Date:",
    "型号": "Product",
    "上门费": "Cover charge",
    "零件费": "Spare parts costs",
    "其他费": "Other costs",
    "增减费": "Increase/decrease in costs",
    "奖惩费": "Award and penalty costs",
    "小计": "Subtotal",
    "否决所选维修单": "Veto Selected Repair Order",
    "总数：": "Total:",
    "总数": "Total",
    "处理中，请稍候...": "Processing, please wait...",
    "请选择需要修改的产品信息": "Please select the product information that needs to be modified",
    "产品信息维护": "Product Information Maintenance",
    "回访完成日期": "Return Visit Completion Date",
    "回访次数": "Number of return visits",
    "回访日期": "Return visit date",
    "回访时间": "Return visit time",
    "回访人员": "Returners",
    "负责清理的工程师是否主动出示授权服务工作卡": "Whether the engineer responsible for the clean-up has volunteered to produce an authorised service work card",
    "清洁前是否主动介绍清洁工具和物料": "Whether the cleaning tools and materials are proactively introduced before cleaning",
    "清洁部件是否有展示照片或则现场展示": "Does the cleaning part have display photos or is it displayed on-site",
    "清洁结束后是否有展示清洁前后的部件照片": "Does the end of the cleaning show photos of the parts before and after the cleaning",
    "清洁结束后是否重新调试咖啡机，并征询客户是否为原始设置": "Whether the coffee machine is recommissioned after cleaning and the customer is consulted as to whether it is the original setting",
    "工程师离开时，是否将清洁咖啡机的残留物清洁干净，并带离": "Do engineers clean the residue from cleaning the coffee machine and take it with them when they leave",
    "请选择用户类型!": "Please select user type!",
    "请选择下次推广时间!": "Please select next promotion time!",
    "下次推广时间不能早于今天!": "The next promotion can't be earlier than today!",
    "请填写电话记录!": "Please fill out the phone log!",
    "请填写推广人员!": "Please fill in promoter!",
    "请选择保养类型!": "Please select the type of maintenance!",
    "请选择保修类型!": "Please select a warranty type!",
    "请选择服务性质!": "Please select the nature of service!",
    "请填写保养记录!": "Please fill in the maintenance record!",
    "请上传机身编号!": "Please upload fuselage No!",
    "请上传清洁物料照片!": "Please upload photos of cleaning materials!",
    "请上传萃取器清洁前照片!": "Please upload extractor pre-cleaning photos!",
    "请上传萃取器清洁后照片!": "Please upload photos of extractor after cleaning!",
    "请上传磨豆机清洁前照片!": "Please upload bean grinder pre-cleaning photos!",
    "请上传磨豆机清洁后照片!": "Please upload photos of the bean grinder after cleaning!",
    "请上传压粉机清洁前照片!": "Please upload pre-cleaning photos of the powder press!",
    "请上传压粉机清洁后照片!": "Please upload photos of powder press after cleaning!",
    "请上传咖啡流口清洁前照片!": "Please upload coffee runners mouth cleaning before photos!",
    "请上传咖啡流口清洁后照片!": "lease upload photos of coffee runners after cleaning!",
    "请上传仪器安全检查清洁前照片!": "Please upload instrument safety check pre-cleaning photos!",
    "请上传仪器安全检查清洁后照片!": "Please upload photos of the instrument after safety check cleaning!",
    "请上传粉饼厚度清洁前照片!": "Please upload a photo of Powder Thickness Before Cleaning!",
    "请上传粉饼厚度清洁后照片!": "Please upload a photo of the powder thickness after cleaning!",
    "请选择清洁前的咖啡粉粗细!": "Please select the thickness of the coffee grounds before cleaning!",
    "请选择清洁后的咖啡粉粗细!": "Please select the coarseness of the coffee powder after cleaning!",
    "请选择清洁前的粉饼松散度!": "Please select the looseness of the powder before cleaning!",
    "请选择清洁后的粉饼松散度!": "Please select the looseness of the powder after cleaning!",
    "请选择下次预计保养时间!": "Please select the next estimated maintenance time!",
    "下次预计保养时间不能早于今天!": "The next scheduled maintenance can't be earlier than today!",
    "请选择工程师!": "Please select engineer!",
    "请填写合同号!": "Please fill in the contract No!",
    "请填写有效期!": "Please fill in the expiry date!",
    "请填写剩余次数!": "Please fill in the remaining times!",
    "下次预计保养时间不能早于今天": "The next scheduled maintenance should not be earlier than today",
    "保养服务工作单": "Maintenance Service Work Order",
    "用户类型：": "UserType:",
    "上门": "Visit",
    "接修": "Repair",
    "推广人员：": "Promoter:",
    "推广人员": "Promoter",
    "下次推广时间：": "Next Promotion Time:",
    "下次推广时间": "Next Promotion Time",
    "电话记录：": "Phone Record:",
    "保养类型：": "Maintenance Type:",
    "A类": "Category A",
    "B类": "Category B",
    "工程师：": "Engineer:",
    "工程师": "Engineer",
    "保养记录：": "Maintenance Record:",
    "保养记录": "Maintenance Record",
    "合同号：": "Contract No:",
    "有效期：": "Expiry Date:",
    "有效期": "Expiry Date",
    "剩余次数：": "Remaining times:",
    "剩余次数": "Remaining times",
    "清洁物料：": "Cleaning material:",
    "清洁物料": "Cleaning material",
    "清洁前": "Before cleaning",
    "清洁后": "After cleaning",
    "清理前": "Pre-clean-up",
    "清理后": "Post-clean-up",
    "萃取器：": "Extractor:",
    "萃取器": "Extractor",
    "萃取器/压力表：": "Extractor/Pressure Gauge:",
    "萃取器/压力表": "Extractor/Pressure Gauge",
    "粉饼厚度：": "Powder thickness:",
    "粉饼厚度": "Powder thickness",
    "磨豆机：": "Bean Grinder:",
    "磨豆机": "Bean Grinder",
    "咖啡粉粗细：": "Coffee Ground Coarse:",
    "咖啡粉粗细": "Coffee Ground Coarse",
    "压粉柱：": "Powder compactor column:",
    "压粉柱": "Powder compactor column",
    "粉饼松散度：": "Powder Loose:",
    "粉饼松散度": "Powder Loose",
    "咖啡流口：": "Coffee Runner:",
    "咖啡流口": "Coffee Runner",
    "绝缘仪器：": "Insulation Instrument:",
    "绝缘仪器": "Insulation Instrument",
    "接地仪器：": "Grounding Instrument:",
    "接地仪器": "Grounding Instrument",
    "预计下次保养时间：": "Expected Next Maintenance Time:",
    "预计下次保养时间": "Expected Next Maintenance Time",
    "预约成功单号：": "Appointment Successful Order No:",
    "预约成功单号": "Appointment Successful Order No",
    "请输入系统单号": "Please enter the System No",
    "1、上门负责清理的工程师是否主动出示授权服务工作卡": "1. Whether the engineer responsible for the clean-up at the door took the initiative to show the authorised service work card",
    "维修站是否主动出示清洁部件清单（满分10分）": "Whether the repair station volunteered to present a list of cleaning parts (out of 10)",
    "上门负责清理的工程师是否主动出示授权服务工作卡": "Whether the engineer responsible for the clean-up at the door took the initiative to produce the authorised service work card",
    "维修站是否主动出示清洁部件清单": "Does the repair station take the initiative to present a list of cleaning parts",
    "2、清洁前是否主动介绍清洁工具和物料（满分10分）": "2. Whether to take the initiative to introduce cleaning tools and materials before cleaning (out of 10 marks)",
    "3、清洁部件是否有展示照片或则现场展示（满分10分）": "3. Whether the cleaning parts are displayed in photographs or on-site (out of 10 points)",
    "4、清洁结束后是否重新调试咖啡机，将其调到了适合顾客的口味": "4. Whether the coffee machine was re-commissioned after cleaning and adjusted to the customer's taste",
    "取机时是否演示制作咖啡并展示粉饼（满分10分）": "Was there a demonstration of coffee making and display of powdered cake at the time of pick up (out of 10)",
    "清洁结束后是否重新调试咖啡机，将其调到了适合顾客的口味": "Was the coffee machine recommissioned after the cleaning and adjusted it to the right flavour for the customer",
    "取机时是否演示制作咖啡并展示粉饼": "Whether or not to demonstrate coffee making and show the powder cake when picking up the machine",
    "5、（上门服务）工程师离开时，是否将清洁咖啡机的残留物清洁干净，并带离": "5. (Visit service) Whether the engineers cleaned the residues of the cleaning coffee machine and took them away when they left",
    "是否有开清洁工作单给顾客（满分10分）": "Whether a cleaning work order was issued to the customer (out of 10)",
    "（上门服务）工程师离开时，是否将清洁咖啡机的残留物清洁干净，并带离": "(Visit Service) Whether the engineer cleaned the residue from cleaning the coffee machine and took it away with him when he left",
    "是否有开清洁工作单给顾客": "Whether a cleaning work order was issued to the customer",
    "修改下次保养时间": "Modify Next Maintenance Time",
    "修改预约成功单号": "Modify Appointment Successful Order No",
    "修改预计下次保养时间": "Modify the Estimated Next Maintenance Time",
    "请修改预约成功单号": "Please modify the Appointment Successful Order No",
    "保养单查询信息": "Maintenance Order Enquiry Information",
    "咖啡机保养单获取失败": "Failed to get coffee machine maintenance order",
    "请选择需要修改的咖啡机保养单": "Please select the coffee machine maintenance order that needs to be modified",
    "工单完成起始时间": "Work Order Completion Start Time",
    "工单完成结束时间": "Work Order Completion End Time",
    "保养到期起始时间": "Maintenance Expiration Start Time",
    "保养到期结束时间": "Maintenance Expiration End Time",
    "回访起始时间": "Return Visit Start Time",
    "回访结束时间": "Return Visit End Time",
    "新增客户起始时间": "New Customer Start Time",
    "新增客户结束时间": "New Customer End Time",
    "联系电话": "Contact number",
    "合同有效起始时间": "Contract Validity Start Time",
    "合同有效结束时间": "Contract Validity End Time",
    "合同号": "Contract No",
    "保养类型": "Maintenance Type",
    "删除保养工单": "Delete Maintenance Work Order",
    "确定要删除当前保养工单吗？": "Sure you want to delete the current maintenance work order?",
    "请选择要删除的保养工单": "Please select the maintenance work orders to be deleted",
    "自动咖啡机数据": "Automatic Coffee Maker Data",
    "用户类型": "UserType",
    "电话记录": "Phone record",
    "请输入开始日期和结束日期": "Please enter the start date and end date",
    "保修价格": "Warranty Price",
    "非保修价格": "Non-warranty Price",
    "请选择维修单": "Please select a repair order",
    "添加备注": "Add Remark",
    "已阅": "Read",
    "请选择品牌": "Please select a brand",
    "导入成功": "Import Success",
    "导入失败": "Import Failed",
    "保存成功": "Saved Success",
    "保存失败": "Save Failed",
    "配件编码": "Accessory code",
    "名称": "Name",
    "零售价格": "Retail Price",
    "折扣": "Discount",
    "折扣（0-1）": "Discounts (0-1)",
    "请选择需要修改的零件费用": "Please select the cost of the part to be modified",
    "请选择要删除的零件费用": "Please select the cost of the parts to be deleted",
    "删除零件费用": "Delete Accessory Cost",
    "确定要删除选中所有零件费用吗？": "Sure you want to delete all parts costs in the selection?",
    "确定要删除配件编码为【{{:a}}】的零件费用吗？": "Are you sure you want to remove part charges with accessory code of 【{{:a}}】?",
    "零件费用已存在": "Accessory Cost already exist",
    "{{:a}}等零件费用已存在": "Accessory Cost such as {{:a}} already exist",
    "零件费用品牌不匹配": "Accessory Cost brand mismatch",
    "{{:a}}等零件费用与选择品牌不对应，请核对": "The cost of {{:a}} and other parts does not correspond to the selected brand, please check",
    "零件费用导入出错": "Error importing accessory cost",
    "{{:a}}等零件费用导入出错": "There was an error importing parts costs such as {{:a}}",
    "零件费用维护": "Accessory Cost Maintenance",
    "已启动后台导出，请耐心等候。": "Background export has been enabled, please be patient.",
    "导出失败，后台发生错误。": "Export failed, an error occurred in the background.",
    "已启动后台导出，可能需要几分钟，请耐心等候。": "Background export has been enabled, it may take a few minutes, please be patient.",
    "零件费用导出失败，后台发生错误。": "Accessory cost export failed, an error occurred in the background.",
    "咖啡机离仓数据导出失败，后台发生错误。": "Coffee machine out of warehouse data export failed, an error occurred in the background.",
    "订单维护数据导出失败，后台发生错误。": "Order maintenance data export failed, an error occurred in the backend.",
    "订单维护数据导出失败，请重试。": "Order maintenance data export failed, please try again.",
    "预计到货数据导出失败，后台发生错误。": "Expected arrival data export failed, an error occurred in the background.",
    "预计到货数据导出失败，请重试": "Expected Arrival Data Export Failed, Please Retry",
    "配件库存数据导出失败，后台发生错误。": "Parts inventory data export failed, an error occurred in the background.",
    "配件库存数据导出失败，请重试": "Failed to export parts inventory data, please retry",
    "延保数据导出失败，后台发生错误。": "Extended warranty data export failed, an error occurred in the background.",
    "延保数据导出失败，请重试": "Extended Warranty Data Export Failed, Please Retry",
    "上传EXCEL": "Upload EXCEL",
    "机器系列号": "Product Serial No",
    "经销商名称": "Distributor Name",
    "离仓时间": "Departure Time",
    "请选择需要修改的咖啡机离仓数据": "Please select the coffee machine departure data that needs to be modified",
    "请选择要删除的咖啡机离仓数据": "Please select the data of the Coffee Machine De-storage to be deleted",
    "删除咖啡机离仓数据": "Delete Coffee Maker Out-of-Stock Data",
    "确定要删除选中所有咖啡机离仓数据吗？": "Sure you want to delete all Coffee Machine De-storage data in the selection?",
    "确定要删除机器型号为【{{:a}}】的咖啡机离仓数据吗？": "Are you sure you want to delete the Coffee Machine De-storage data with the machine model【{{:a}}】?",
    "咖啡机离仓数据导入出错": "Error importing data for Coffee Machine De-storage",
    "{{:a}}等咖啡机离仓数据导入出错": "{{:a}} and other Coffee Machine De-storage data import errors",
    "搜索关键字": "Search keywords",
    "机器型号/机器系列号": "Product/Product Serial No",
    "咖啡机离仓数据": "Coffee Machine De-storage Data",
    "维修单时间修复完成": "Repair Order Time Repair Complete",
    "维修单时间修复失败": "Repair Order Time Repair Failed",
    "修正时间": "Correction time",
    "一键修正": "One-Click Fix",
    "海外": "Overseas",
    "国内": "Domestic",
    "维修费用统计核对表": "Maintenance cost statistics checklist",
    "海外维修费用统计核对表": "Overseas Maintenance Expenses Statistical Checklist",
    "国内维修费用统计核对表": "Domestic maintenance cost statistics checklist",
    "维修站维修费用统计核对表": "Checklist of statistics on repair costs of service stations",
    "维修站维修费用统计": "Repair cost statistics for service stations",
    "Guarantee维修费用统计核对表": "Guarantee Maintenance Cost Statistics Checklist",
    "下载": "Download",
    "请选择需要修改的故障信息": "Please select the fault messages that needs to be modified",
    "请选择要删除的故障信息": "Please select the fault messages to be deleted",
    "删除故障信息": "Delete Fault Message",
    "确定要删除选中所有故障信息吗？": "Sure you want to delete all fault messages in the selection?",
    "确定要删除Description为【{{:a}}】的故障信息吗？": "Are you sure you want to delete a fault message with a Description of【{{:a}}】?",
    "Area": "Area",
    "IRIS Defect Code": "IRIS Defect Code",
    "Iris Defect Description": "Iris Defect Description",
    "故障分类": "Fault classification",
    "缺陷描述": "Defect description",
    "具体补充": "Specific additions",
    "显示顺序": "Display order",
    "邮件设置获取失败": "Mail Settings Failed to Get",
    "邮件设置保存成功": "Mail setting saved successfully",
    "邮件设置保存失败": "Mail setting saved failed",
    "邮件设置": "Mail Settings",
    "邮件服务器": "Mail Server",
    "端口": "Port",
    "账户": "Account",
    "邮件抬头": "Mail Header",
    "邮件落款": "Mail Drop",
    "服务器链接": "Server Link",
    "请选择需要修改的订单数据": "Please select the order data that needs to be modified",
    "请选择要删除的订单数据": "Please select the order data to be deleted",
    "确定要删除订单数据吗？": "Are you sure you want to delete order data?",
    "确定要删除订单编号为【{{:a}}】的订单数据吗？": "Are you sure you want to delete order data with an order number of【{{:a}}】?",
    "确定要删除手机号为【{{:a}}】的订单数据吗？": "Are you sure you want to delete order data with a mobile phone number of【{{:a}}】?",
    "确定要删除保修卡号为【{{:a}}】的订单数据吗？": "Are you sure you want to delete the order data with warranty card number【{{:a}}】?",
    "删除订单数据": "Delete Order Data",
    "确定要删除选中所有订单数据吗？": "Sure you want to delete all order data in the selection?",
    "订单数据已存在": "Order data already exist",
    "{{:a}}等订单数据已存在": "Order data such as {{:a}} already exists",
    "订单数据导入出错": "Order Data Import Error",
    "{{:a}}等订单数据导入出错": "There was an error in importing order data such as {{:a}}",
    "销售渠道": "Sales channels",
    "批量删除": "Batch Delete",
    "订单维护": "Order Maintenance",
    "请选择需要修改的预计到货数据": "Please select the estimated arrival data that needs to be modified",
    "请选择要删除的预计到货数据": "Please select the estimated arrival data to be deleted",
    "删除预计到货数据": "Delete Expected Arrival Data",
    "确定要删除订单编号为【{{:a}}】的预计到货数据吗？": "Are you sure you want to delete ETA with an order ID of【{{:a}}】?",
    "预计到货数据导入出错": "Error in importing expected arrival data",
    "{{:a}}等预计到货数据导入出错": "There is an error in importing the estimated arrival data such as {{:a}}",
    "预计到货日期": "Estimated Arrival Date",
    "预计到货": "Expected Arrival",
    "请选择需要修改的配件库存数据": "Please select the accessory inventory data that needs to be modified",
    "请选择要删除的配件库存数据": "Please select the accessory inventory data to be deleted",
    "删除配件库存数据": "Delete Accessory Inventory Data",
    "确定要删除当前配件库存数据吗？": "Sure you want to delete the current accessory inventory data?",
    "配件库存数据已存在": "Accessory Inventory Data Exists",
    "{{:a}}等配件库存数据已存在": "Inventory data for accessories such as {{:a}} already exists",
    "配件库存数据导入出错": "Error importing accessory inventory data",
    "{{:a}}等配件库存数据导入出错": "There is an error in importing inventory data for accessories such as {{:a}}",
    "库存数量": "Quantity in stock",
    "配件库存数据": "Accessory Inventory Data",
    "请选择要删除的产品信息": "Please select the product information to be deleted",
    "删除产品信息": "Delete Product Information",
    "确定要删除选中所有产品信息吗？": "Sure you want to delete all product information in the selection?",
    "确定要删除产品型号为【{{:a}}】的产品信息吗？": "Are you sure you want to delete the product information with the product model number 【{{:a}}】?",
    "产品信息已存在": "Product Information Already Exists",
    "{{:a}}等产品信息已存在": "Product information such as {{:a}} already exists",
    "产品信息品牌不匹配": "Product Information Brand Mismatch",
    "{{:a}}等产品信息与选择品牌不对应，请核对": "Product information such as {{:a}} does not correspond to the selected brand, please check",
    "产品信息导入出错": "Error importing product information",
    "{{:a}}等产品信息导入出错": "There was an error importing product information such as {{:a}}",
    "机器类别": "Category",
    "产品型号": "Product Model",
    "导出产品型号": "Export Product Model",
    "导出时的产品型号": "Product Model at Export",
    "导出总部表单": "Export HQ Forms",
    "总部品类": "Headquarters category",
    "产品名称": "Product name",
    "预约期限": "Appointment duration",
    "上门期限": "Visit deadline",
    "维修期限": "Repair period",
    "维修站回访期限": "Station return period",
    "总部回访期限": "HQ return period",
    "保修维修费": "Warranty Maintenance Fee",
    "标准维修费": "Standard Maintenance Fee",
    "检测费用": "Test Fee",
    "产品信息": "Product Information",
    "服务期限设置获取失败": "Failed to get duration of service setting",
    "服务期限设置保存成功": "Service Term Settings Saved Successfully",
    "服务期限设置保存失败": "Failed to save duration of service setting",
    "超时设置": "Timeout Settings",
    "预约时间期限（小时）": "Appointment Time Duration (hours)",
    "上门服务期限（天）": "Duration of on-site service (days)",
    "维修用时期限（天）": "Repair Duration (days)",
    "咖啡机上门期限（天）": "Coffee machine visit duration (days)",
    "咖啡机维修期限（天）": "Coffee machine repair period (days)",
    "回访时间期限（维修站）": "Return visit time duration (station)",
    "回访时间期限（总部）": "Return visit time period (HQ)",
    "更新前台页面": "Update WEB",
    "确定更新前台页面代码？": "Sure about updating the WEB code?",
    "更新后台": "Update backend",
    "更新后台代码": "Update backend code",
    "确定更新后台代码？": "Sure about updating backend code?",
    "更新": "Update",
    "更新成功": "Update Successful",
    "更新失败": "Update Failed",
    "德龙售后": "Delonghi After-sales",
    "删除部门": "Delete Department",
    "确定要删除部门【{{:a}}】吗？": "Are you sure you want to delete department【{{:a}}】?",
    "部门信息": "Department information",
    "编辑部门信息": "Edit department info",
    "部门名称": "Department Name",
    "请输入部门名称!": "Please enter department name!",
    "请选择所在省份!": "Please select province!",
    "请输入所在城市!": "Please enter city!",
    "维修站编号": "Station No",
    "请输入维修站编号!": "Please enter the station No!",
    "联系人": "Contact",
    "联系地址": "Address",
    "当前余额": "Current Balance",
    "请输入当前余额!": "Please enter the current balance!",
    "保养模块权限": "Maintenance Module Privileges",
    "配件模块权限": "Accessory Module Privileges",
    "DLS配件订购": "DLS Accessory Order",
    "历史站点": "Historical Station",
    "新增子区域": "Add Subregion",
    "新增员工": "Add Employee",
    "删除员工": "Delete Employee",
    "确定要删除当前员工【{{:a}}】吗？": "Are you sure you want to delete the current employee【{{:a}}】?",
    "员工信息": "Employee Information",
    "编辑员工信息": "Edit Employee Information",
    "员工姓名": "Employee Name",
    "请输入员工姓名!": "Please enter employee name!",
    "所属部门": "Department",
    "员工职位": "Position",
    "上级主管": "Supervisor",
    "性别": "Sex",
    "女": "Female",
    "男": "Male",
    "登录用户名": "LoginName",
    "请输入登录用户名!": "Please enter loginName!",
    "登录密码": "Password",
    "咖啡机养护工程师": "Coffee Machine Maintenance Engineer",
    "养护工程师编号": "Maintenance Engineer No",
    "工程师编号": "Engineer No",
    "导出Excel权限": "Export Excel Privileges",
    "请选择需要修改的延保信息": "Please select the extended warranty information that needs to be modified",
    "请选择要删除的延保信息": "Please select the extended warranty information to be deleted",
    "删除延保信息": "Delete Extended Warranty Information",
    "确定要删除选中所有延保信息吗？": "Sure you want to delete all the extended warranty information from the selection?",
    "确定要删除订单编号为【{{:a}}】的延保信息吗？": "Are you sure you want to delete an extended warranty with an order number of【{{:a}}】?",
    "延保数据": "Extended Warranty Data",
    "用户设置": "User Settings",
    "服务期限维护": "Service Term Maintenance",
    "配件库存": "Accessory Inventory",
    "Excel": "Excel",
    "设置成功": "Setup Success",
    "设置失败": "Setup Failure",
    "点击或者拖拽上传语音提醒文件": "Click or drag and drop to upload a voice reminder file",
    "每次只能上传一个音频文件": "Only one audio file can be uploaded at a time",
    "用户电话不接，稍后联系": "The user's phone is not answering, contact later",
    "用户出差，稍后联系": "The user is travelling, contact later",
    "用户出差，等用户来电": "User travelling, waiting for user to call",
    "等用户来电确认时间": "Waiting for user call confirmation time",
    "已经电话/视频指导，稍后联系确认": "Already telephoned/Video tutored, contact later to confirm",
    "等用户提供有效凭证": "Waiting for the subscriber to provide valid credentials",
    "用户申请费用中，等用户来电": "User application fee in progress, waiting for user to call",
    "配件已订购，待配件到货": "Accessory ordered, awaiting arrival of accessory",
    "机器已修复，待客户付款/客户待取/待送回": "Machine has been repaired, pending customer payment/customer pickup/pending return",
    "机器/生产编号": "Machine/Production Number",
    "制作杯数(自动咖啡机)": "Used cup",
    "除垢次数(自动咖啡机)": "Desclae No.",
    "使用水量(自动咖啡机)": "Water used",
    "渠道分类": "Sales channel",
    "配件金额合计": "Total spare part",
    "回访状态": "Return Visit Status",
    "工单数据已被修改过，请先获取最新工单": "The work order data has been modified, please get the latest work order first",
    "没有发现数据，导⼊失败": "o data found, import failed",
    "没有发现数据": "No data found",
    "Excel数据导⼊格式不正确，请确认": "The Excel data import format is incorrect, please confirm",
    "数据导⼊失败": "Data Entry Failed",
    "文件不存在": "File does not exist",
    "开始查询维修单服务数据...": "Starting to query repair order service data...",
    "维修单服务数据导出失败": "Repair Order Service Data Export Failed",
    "维修单服务数据获取成功，开始生成Excel文件": "Repair Order Service Data Acquisition Successful, Starting Excel File Generation",
    "正在将维修单服务数据写入Excel文件": "Writing repair order service data to Excel file in progress",
    "维修单服务数据文件导出成功": "Repair Order Service Data File Export Successful",
    "开始查询维修单查询数据...": "Starting to query repair order query data...",
    "维修单查询数据获取成功，开始构建数据": "Repair order query data fetched successfully, started constructing data",
    "维修单查询数据构建完成，开始生成Excel文件": "Repair Order Query Data Construction Complete, Starting Excel File Generation",
    "维修单查询数据": "Repair Order Query Data",
    "维修单查询数据文件导出成功": "Repair Order Query Data File Export Successful",
    "没有可导出的维修单查询数据": "There is no exportable repair order query data",
    "没有可导出的维修单数据": "No exportable repair order data",
    "维修单查询数据导出失败": "Failed to export data for repair order query",
    "正在将维修单查询数据写入Excel文件": "Writing repair order query data to Excel file in progress",
    "超预约时间": "Over appointment time",
    "超上门服务时间": "Over visit time",
    "超维修时间": "Over repair time",
    "超回访时间": "Over return time",
    "找不到模板文件": "Template file not found",
    "正在统计，请稍候...": "Counting, please wait...",
    "批量作废完成": "Batch voiding complete",
    "旧件数配件": "Old Pieces Fittings",
    "维修站点": "Station",
    "配件名称": "Accessory name",
    "配件编号": "Accessory No",
    "没有找到咖啡机保养单": "Coffee machine maintenance sheet not found",
    "清洁前咖啡粉粗细": "Coarseness of coffee grounds before cleaning",
    "清洁后咖啡粉粗细": "Coarseness of coffee grounds after cleaning",
    "清洁前粉饼松散度": "Pre-clean powder looseness",
    "清洁后粉饼松散度": "Powder Looseness After Cleaning",
    "预计下次保养时间-年": "Expected Next Maintenance Time - Year",
    "预计下次保养时间-月": "Expected Next Maintenance Time - Month",
    "预计下次保养时间-日": "Expected Next Maintenance Time - YDay",
    "修改下次保养时间为：": "Modify the next maintenance time to:",
    "修改预约成功单号为：": "Modify Booking Successful Order No to:",
    "表头格式": "Table header format",
    "预计到货时间": "Estimated time of arrival",
    "已存在相同登录账户用户": "The same login account user already exists",
    "没有找到对应用户": "Corresponding user not found",
    "登录失败，请重试": "Login Failed, Please Retry",
    "用户名不存在": "Loginname does not exist",
    "密码修改失败，请重试": "Password modification failed, please try again",
    "上传时间": "Upload Time",
    "保修内": "Under Warranty",
    "过保": "out-of-warranty",
    "图片上传失败": "Image Upload Failed",
    "故障现象：": "Failure phenomenon:",
    "故障现象": "Failure phenomenon",
    "关闭": "Close",
    "上传人：": "Uploaded by:",
    "上传时间：": "Upload Time:",
    "（点击查看大图）": "(Click to view larger image)",
    "查看照片": "View Photo",
    "正在提交，请不要重复操作": "Submitting, please don't repeat the operation",
    "请不要重复操作": "Please don't repeat the operation",
    "维修状态统计": "Repair Status Statistics",
    "开单时间-预约确认时间": "Create date - Appointment Settle Date",
    "3小时以内": "Within 3 hours",
    "3-6小时": "3-6 hours",
    "6-12小时": "6-12 hours",
    "1天": "1 day",
    "3天": "3 days",
    "3天以上": "More than 3 days",
    "预约上门时间-完成维修时间": "Appointment Date - Syetem complete date",
    "当天": "The day",
    "待送修": "To be delivery",
    "待寄修": "To be sent",
    "顾客送修": "Customer Delivery",
    "顾客寄修": "Customer Send",
    "送修已收到": "Delivery received",
    "寄修已收到": "Send received",
    "因疫情需要，我们将每天统计您站的工作范围": "Due to the outbreak, we will be counting your station's scope of work on a daily basis",
    "请告知您站目前的工作范围": "Please advise your station's current scope of work",
    "请问您站预计恢复工作的时间": "Please tell me when your station is expected to resume work",
    "休假": "Leave",
    "站点休假": "Station leave",
    "正常营业": "Normal business",
    "部分区域上门": "Partial regional visit",
    "仅接修": "Repair only",
    "仅接机": "Pick-up only",
    "站点关闭": "Station Closed",
    "确认提交": "Confirm submit",
    "全国服务网点工作范围查询": "National Service Outlet Work Scope Enquiry",
    "全国网点": "Nationwide Locations",
    "休假统计": "Leave statistics",
    "未统计": "Uncounted",
    "统计中": "Statistics in progress",
    "已结束": "Closed",
    "标题": "Title",
    "有效期限": "Validity period",
    "开始统计": "Starting Statistics",
    "创建时间": "Create Time",
    "更新时间": "Update Time",
    "数据提交失败，请重试": "ata submission failed, please retry",
    "前往填报": "Go to fill in",
    "请选择要删除的休假统计公告": "Please select the leave statistics bulletin to be deleted",
    "删除休假统计公告": "Delete Leave Statistics Bulletin",
    "确定要删除休假统计公告【{{:a}}】吗？": "Are you sure you want to delete the leave statistics announcement【{{:a}}】?",
    "请选择要删除的记录": "Please select the records to be deleted",
    "删除记录": "Delete Record",
    "确定要删除维修站【{{:a}}】的记录吗？": "Are you sure you want to delete the record for the repair station【{{:a}}】?",
    "工作范围": "Scope of work",
    "营业状态": "Business Status",
    "起止日期": "From Date",
    "休假起止日期": "Leave start and end date",
    "休假日期": "Date of leave",
    "更新人": "Updated by",
    "补充说明": "Supplementary note",
    "疫情统计": "Outbreak statistics",
    "疫情期间工作范围": "Scope of work during the epidemic",
    "节假日营业状态": "Holiday Business Status",
    "电话转接": "Call Forwarding",
    "预计恢复时间": "Estimated Recovery Time",
    "请输入标题": "Please enter the title",
    "请选择有效期限": "Please select the expiry date",
    "休假统计记录": "Leave Statistics Record",
    "查看记录": "View Record",
    "公告标题": "Announcement Title",
    "已删除，无需填报": "Deleted, no need to fill in",
    "其他人已填报": "Others have filled in",
    "WEB页面已更新，当前WEB端版本不是最低要求版本，请刷新页面": "The WEB page has been updated, the current WEB version is not the minimum required version, please refresh the page",
    "WEB页面已更新，请刷新页面": "WEB page has been updated, please refresh page",
    "WEB页面更新": "WEB page update",
    "刷新页面": "Refresh Page",
    "最后更新日期": "Last Updated Date",
    "最新操作": "Latest operation",
    "无效的延保": "Invalid extended warranty",
    "保修依据购买日期不能大于注册时间": "Warranty cannot be based on a date of purchase greater than the time of registration",
    "保修日期不符合": "Warranty date not met",
    "延保不符合": "Extended Warranty Not Compliant",
    "该维修单的预约上门时间为：": "The appointment time for this repair order is:",
    "请修改上门日期": "Please change the date of visit",
    "该维修单的预约上门时间为：{{:1}}，请修改上门日期": "The appointment time for this repair order is: {{:1}}, please revise the date of visit",
    "操作太快": "Operating too fast",
    "正在获取数据，请稍候...": "Acquiring data, please wait...",
    "收机方法：": "Receiving method:",
    "收机方法": "Receiving method",
    "短信已发送": "SMS Sent",
    "短信发送失败，请重试": "SMS failed to send, please try again",
    "订单编号/手机号": "Order No./Mobile No.",
    "订单编号/手机号/型号/机身编号": "Order No./Mobile No./Model No./Fuselage No.",
    "产品名称/产品型号/导出产品型号": "uct name/Product model/Exported product model",
    "请填写维修站物流单号!": "ase fill in the service station logistics No!",
    "移动电话必须填写11位号码!": "Mobile phones must be filled with an 11-digit number!",
    "请选择出仓日期": "Please select the date of exit from the position",
    "请输入正确格式的手机号!": "Please enter the correctly formatted mobile phone No!",
    "预约上门时间不能小于当前时间": "The appointment time cannot be less than the current time",
    "寄出旧件单号：": "Sending Old Document Order No:",
    "寄出旧件单号": "Sending Old Document Order No",
    "寄送发票单号：": "Sending Invoice Order No:",
    "寄送发票单号": "Sending Invoice Order No",
    "发票号：": "Invoice No.:",
    "发票号": "Invoice No.",
    "发票照片：": "Invoice Photo:",
    "发票照片": "Invoice Photo",
    "点击或拖拽文件到此区域上传照片": "Click or drag a file to this area to upload a photo",
    "更换旧件": "Replacement of old parts",
    "待盘点旧件": "Old pieces to be inventoried",
    "待盘点": "To be inventoried",
    "旧件已盘点": "Old pieces have been inventoried",
    "快递公司+快递单号": "courier company + courier single number",
    "请填写寄出发票单号": "Please fill in the outgoing invoice number",
    "请填写发票号": "Please fill in the Invoice No",
    "请上传发票照片": "Please upload invoice photo",
    "请输入配件旧件数量": "Please enter the number of old parts of the accessory",
    "请输入整机旧件数量": "Please enter the number of used parts of the whole machine",
    "配件搜索": "Accessory Search",
    "应返数量": "Quantity to be returned",
    "盘点数量": "Inventory Quantity",
    "总部备注": "Headquarters Remark",
    "经销商": "Distributor",
    "经销商数据": "Distributor Data",
    "请选择需要修改的经销商信息": "Please select the distributor information to be modified",
    "请选择要删除的经销商信息": "Please select the dealer information to be deleted",
    "删除经销商信息": "Delete Distributor Information",
    "确定要删除名称为【{{:a}}】的经销商信息吗？": "Are you sure you want to delete the dealer information  with the name【{{:a}}】?",
    "确定要删除选中所有经销商信息吗？": "Sure you want to delete all dealer information in the selection?",
    "经销商数据导出失败，后台发生错误。": "Dealer data export failed, an error occurred in the backend.",
    "经销商数据导出失败，请重试": "Failed to export distributor data, please retry",
    "商品": "Product",
    "商品数据": "Product Data",
    "请选择需要修改的商品信息": "Please select the product information that needs to be modified",
    "请选择要删除的商品信息": "Please select the product information to be deleted",
    "删除商品信息": "Delete Product Information",
    "确定要删除名称为【{{:a}}】的商品信息吗？": "Are you sure you want to delete the product information with the name【{{:a}}】?",
    "确定要删除选中所有商品信息吗？": "Sure you want to delete all product information in the selection?",
    "商品数据导出失败，后台发生错误。": "Product data export failed, an error occurred in the backend.",
    "商品数据导出失败，请重试": "Product Data Export Failed, Please Retry",
    "商品型号": "Product Model",
    "EAN/UPC": "EAN/UPC",
    "导入成功, 【{{:a}}】等{{:b}}个经销商已存在": "Imported successfully, 【{{:a}}】and other {{:b}} dealers already exist!",
    "导入成功，【{{:a}}】等{{:b}}个800条码已存在": "Import Successful, 【{{{:a}}】and {{:b}} 800 barcodes already exist!",
    "导入文件模板": "Import File Templates",
    "导入文件\n模板": "Import File\n Template",
    "没有可提交的数据": "No submittable data",
    "请输入商品型号": "Please enter the product model number",
    "请输入商品EAN/UPC": "Please enter the product EAN/UPC",
    "仓库扫码": "Warehouse Sweep",
    "没有访问权限": "No access",
    "上传离仓数据": "Uploading data for leaving a position",
    "上传离仓数据统计": "Uploading Statistics of Departing Positions",
    "上传人": "Uploader",
    "请填写EKP单号": "Please fill in the EKP order No",
    "待发货": "To be shipped",
    "请联系总部索要单号": "Please contact head office for a bill number",
    "请输入维修站物流单号！1、送货到家，请联系总部索要单号。2、维修站寄出自行填写": "Please enter the repair station logistics single number! 1. Delivery to home, please contact headquarters for a single number. 2. Repair station send out to fill in on their own.",
    "旧件待寄出": "Old pieces to be sent",
    "旧件已寄出": "Old pieces has been sent",
    "待返回旧件": "Old pieces to be returned",
    "急": "Urgent",
    "诉": "Complain",
    "投诉待处理": "Complaints Pending",
    "投诉已处理": "Complaints Handled",
    "按批次删除": "Delete by batch",
    "上传批次": "Upload batch",
    "请输入上传批次": "Please enter the upload batch",
    "按批次删除订单失败": "Failed to delete order by batch",
    "制作杯数大于等于10000不能做保修": "Making cups greater than or equal to 10000 cannot be done under warranty",
    "拍照人": "Photographed by",
    "点击查看大图": "Click to view larger image",
    "工单完成量": "Work Order Completion",
    "工单完成量统计": "Work Order Completion Statistics",
    "需总部选择保修日期": "Warranty date to be selected by Headquarters",
    "工单实效(品类/天)": "Work Order Effectiveness (category/day)",
    "年度汇总": "Annual Summary",
    "工单实效(品类/天)统计": "Work Order Effectiveness (Category/Day) Statistics",
    "请选择年份": "Please select a year",
    "所在城市：": "City:",
    "工作范围：": "Scope of Work:",
    "电话转接：": "Call Forwarding:",
    "预计恢复时间：": "Estimated Recovery Time:",
    "休假时间：": "Leave time:",
    "更新人：": "Updated by:",
    "更新时间：": "Update Time:",
    "请输入站点所在城市": "Please enter the city where the station is located",
    "平均工单用时（天）": "Average work order time (days)",
    "月份": "Month",
    "年份": "Year",
    "总工单数": "Total Number of Work Orders",
    "结算公司抬头": "Clearing company letterhead",
    "请选择过滤条件": "Please select filter criteria",
    "短信记录": "SMS Record",
    "短信内容": "SMS Content",
    "已发送": "Sent",
    "已发送其他短信": "Sent Other SMS",
    "已过期": "Expired",
    "待发送": "To be sent",
    "今天": "Today",
    "昨天": "Yesterday",
    "前天": "Day before yesterday",
    "最近三天": "Last 3 days",
    "最近一周": "Last week",
    "最近一月": "Last month",
    "选择状态": "Select Status",
    "单号/移动电话/SMSKey": "Order No/Mobile Phone/SMSKey",
    "开发维护": "Development Maintenance",
    "两次或以上维修": "Two or more repairs",
    "结账单号": "Checkout No",
    "400热线派单": "400 hotline dispatch",
    "订单号/手机号/保修卡号/海外机身号/京东单号": "Order No/Mobile Phone No/Warranty Card No/Overseas Fuselage No/JD Order No",
    "退回作废申请": "Return the void request",
    "保修性质": "Warranty nature",
    "导出明细": "Export details",
    "抽查": "Spot Check",
    "已抽查": "Sampled",
    "确定将【{{:a}}】标记为已抽查吗?": "Are you sure you want to mark 【{{:a}}】 as sampled?",
    "查看图片": "View Photos",
    "添加发票照片（最多10张）": "Add invoice photos (up to 10)",
    "维修站备注": "Station Remark",
    "维修结果": "Repair Result",
    "维修时长": " Repair Duration",
    "维修师服务": " Repairer Service",
    "产品使用": "Product Use",
    "NPS打分": "NPS Score",
    "IP地址": "IP Address",
    "操作人": "Operator",
    "操作账号": "Operation Account",
    "描述": "Description",
    "数据写入失败": "Data write failure",
    "请上传上次更新时间": "Please upload last update time",
    "没有登录权限": "No login privileges",
    "年": "Year",
    "月": "Month",
    "区域": "Region",
    "分区": "Subarea",
    "新界": "New Territories",
    "离岛": "Outlying Islands",
    "九龙": "Kowloon",
    "香港岛": "Hong Kong Island",
    "大围": "Tai Wai",
    "沙田": "Shatin",
    "火炭": "Fo Tan",
    "马鞍山": "Ma On Shan",
    "大埔": "Tai Po",
    "太和": "Tai Wo",
    "粉岭": "Fanling",
    "上水": "Sheung Shui",
    "罗湖": "Lo Wu",
    "落马洲": "Lok Ma Chau",
    "葵芳": "Kwai Fong",
    "葵涌": "Kwai Chung",
    "葵兴": "Kwai Hing",
    "荃湾": "Tsuen Wan",
    "青衣": "Tsing Yi",
    "马湾": "Ma Wan",
    "深井": "Sham Tseng",
    "屯门": "Tuen Mun",
    "元朗": "Yuen Long",
    "天水围": "Tin Shui Wai",
    "流浮山": "Lau Fau Shan",
    "西贡": "Sai Kung",
    "将军澳": "Tseung Kwan O",
    "宝琳": "Po Lam",
    "坑口": "Hang Hau",
    "愉景湾": "Discovery Bay",
    "东涌": "Tung Chung",
    "赤𫚭角": "Chek Lap Kok",
    "大屿山": "Lantau Island",
    "大澳": "Tai O",
    "长洲": "Cheung Chau",
    "坪洲": "Peng Chau",
    "南丫岛": "Lamma Island",
    "蒲苔岛": "Po Toi Island",
    "美孚": "Mei Foo",
    "荔枝角": "Lai Chi Kok",
    "长沙湾": "Cheung Sha Wan",
    "深水埗": "Sham Shui Po",
    "太子": "Prince Edward",
    "旺角": "Mongkok",
    "大角咀": "Tai Kok Tsui",
    "油麻地": "Yau Ma Tei",
    "佐敦": "Jordan",
    "尖沙咀": "Tsim Sha Tsui",
    "红磡": "Hung Hom",
    "何文田": "Ho Man Tin",
    "土瓜湾": "To Kwa Wan",
    "石硖尾": "Shek Kip Mei",
    "九龙塘": "Kowloon Tong",
    "九龙城": "Kowloon City",
    "乐富": "Lok Fu",
    "黄大仙": "Wong Tai Sin",
    "新蒲岗": "San Po Kong",
    "钻石山": "Diamond Hill",
    "慈云山": "Tsz Wan Shan",
    "彩虹": "Choi Hung",
    "九龙湾": "Kowloon Bay",
    "牛头角": "Ngau Tau Kok",
    "观塘": "Kwun Tong",
    "蓝田": "Lam Tin",
    "油塘": "Yau Tong",
    "鲤鱼门": "Lei Yue Mun",
    "西环": "Sai Wan",
    "石塘咀": "Shek Tong Tsui",
    "西营盘": "Sai Ying Pun",
    "坚尼地城": "Kennedy Town",
    "上环": "Sheung Wan",
    "中环": "Central",
    "半山": "Mid-levels",
    "山顶": "The Peak",
    "金钟": "Admiralty",
    "湾仔": "Wan Chai",
    "跑马地": "Happy Valley",
    "铜锣湾": "Causeway Bay",
    "大坑": "Tai Hang",
    "天后": "Tin Hau",
    "北角": "North Point",
    "鲗鱼涌": "Quarry Bay",
    "太古": "Taikoo",
    "西湾河": "Sai Wan Ho",
    "筲箕湾": "Shau Kei Wan",
    "杏花邨": "Heng Fa Chuen",
    "柴湾": "Chai Wan",
    "薄扶林": "Pok Fu Lam",
    "数码港": "Cyberport",
    "香港仔": "Aberdeen",
    "鸭脷洲": "Ap Lei Chau",
    "深水湾": "Deep Water Bay",
    "黄竹坑": "Wong Chuk Hang",
    "浅水湾": "Repulse Bay",
    "石澳": "Shek O",
    "赤住": "Chek Lap Kok",
    "修改了用户：{{:a}} 的密码": "Changed password for user:{{:a}}",
    "开始查询系统日志数据...": "Started querying syslog data...",
    "系统日志数据获取成功，开始构建数据": "Successfully fetched syslog data, started building data",
    "没有可导出的系统日志数据": "No exportable syslog data",
    "系统日志数据导出失败": "Failed to export syslog data",
    "系统日志数据构建完成，开始生成Excel文件": "System log data construction is complete, start generating Excel file.",
    "正在写入系统日志数据文件": "Writing syslog data file",
    "系统日志数据": "System log data",
    "系统日志数据文件导出中": "System log data file is being exported",
    "系统日志数据解压": "System log data decompression",
    "系统日志加密数据导出成功": "System log encrypted data exported successfully",
    "系统日志数据文件导出失败": "System log data file export failed",
    "数据提交失败": "Data submission failed",
    "用户名或密码错误，请确认输入是否正确": "Loginname or password is incorrect, please make sure it is correct.",
    "密码不正确": "Incorrect password",
    "工作统计公告": "Job Statistics Announcement",
    "电话转接服务": "Call Forwarding Service",
    "预计恢复日期": "Expected Resumption Date",
    "休假开始日期": "Holiday Start Date",
    "休假结束日期": "Holiday End Date",
    "更新日期": "Update Date",
    "工作范围统计": "Scope of Work Statistics",
    "插座故障": "Socket fault",
    "维修单查询数据文件导出失败": "Failure to export repair order enquiry data file",
    "正在写入维修单查询数据文件": "Repair order enquiry data file is being written",
    "维修单查询数据文件导出中": "Repair order enquiry data file is being exported",
    "1月": "January", // 缩写Jan.
    "2月": "February", // 缩写Feb.
    "3月": "March", // 缩写Mar.
    "4月": "April", // 缩写Apr.
    "5月": "May", // 缩写May
    "6月": "June", // 缩写Jun.
    "7月": "July", // 缩写Jul.
    "8月": "August", // 缩写Aug.
    "9月": "September", // 缩写Sept.或者Sep.
    "10月": "October", // 缩写Oct.
    "11月": "November", // 缩写Nov.
    "12月": "December", // 缩写Dec.
    "添加备注：": "Add remark:",
    "添加备注": "Add remark",
    "400系统派单": "400 System Dispatch",
    "已短信通知": "Notified by SMS",
    "处理维修单，单号：": "Processing repair order, System No:",
    "操作：": "Operation:",
    "APP版本已更新，请安装新版本APP": "APP version has been updated, please install the new version of APP.",
    "YYYY": "YYYY",
    "MM": "MM",
    "DD": "DD",
    "德龙Delonghi京东自营旗舰店": "Delonghi JD Self-operated",
    "知道了": "Got it!",
    "更新测试页面": "Updating the test page",
    "确定更新测试页面代码？": "Sure you want to update the test page code?",
    "拉花师": "Flower Arranger",
    "订单维护审批权限": "Order Maintenance Approval Authority",
    "订单上传": "Order Upload",
    "模板文件": "Template File",
    "点击下载": "Click to Download",
    "点击下载模板文件": "Click to download template file",
    "养护工程师": "Maintenance Engineer",
    "拉花师泵压式咖啡机EC系列": "La Florist Pump Coffee Maker EC Series",
    "拉花师自动咖啡机": "Flower Puller Automatic Coffee Maker",
    "账户统计": "Account Statistics",
    "拉花照片：": "Pulling Photo:",
    "拉花照片": "Pulling Photo",
    "拉花费用：": "Flower Pulling Fee:",
    "拉花费用": "Flower Pulling Fee",
    "未预约：": "No Appointment:",
    "未预约": "No Appointment",
    "未完成：": "Incomplete:",
    "未完成": "Incomplete",
    "未完成维修：": "Incomplete Repair:",
    "未完成维修": "Incomplete Repair",
    "维修单": "Repair Orders",
    "深度清洁": "Deep Cleaning",
    "系统工单状态": "Order Status",
    "订单数据": "Order Data",
    "已提交": "Submitted",
    "已退回": "Returned",
    "已上传": "Uploaded",
    "保存订单数据": "Saved Order Data",
    "提交订单数据": "Submit Order Data",
    "退回订单数据": "Return Order Data",
    "上传订单数据": "Upload Order Data",
    "重新上传订单数据": "Re-upload Order Data",
    "订单数据获取失败": "Order Data Acquisition Failed",
    "订单数据已被修改过，请先获取最新数据": "Order Data Has Been Modified, Please Get Latest Data First",
    "导出订单数据": "Export Order Data",
    "新增订单数据，手机号：{{:1}}": "Add Order Data, Mobile No.: {{:1}}",
    "新增订单数据，订单编号：{{:1}}": "Add Order Data, Order No.: {{:1}}",
    "新增订单数据，保修卡号：{{:1}}": "Add Order Data, Warranty Card No.: {{:1}}",
    "保存订单数据，手机号：{{:1}}": "Save Order Data, Mobile No.: {{:1}}",
    "保存订单数据，订单编号：{{:1}}": "Save Order Data, Order No.: {{:1}}",
    "保存订单数据，保修卡号：{{:1}}": "Save Order Data, Warranty Card No.: {{:1}}",
    "删除订单数据，订单ID：{{:1}}，手机号：{{:2}}": "Delete Order Data, Order ID: {{:1}}, Mobile No.: {{:2}}",
    "删除订单数据，订单ID：{{:1}}，订单编号：{{:2}}": "Delete Order Data, Order ID: {{:1}}, Order No.: {{:2}}",
    "删除订单数据，订单ID：{{:1}}，保修卡号：{{:2}}": "Delete Order Data, Order ID: {{:1}}, Warranty Card No.: {{:2}}",
    "上传订单数据到订单维护，手机号：{{:1}}": "Uploading order data to Order Maintenance, Mobile No.: {{:1}}",
    "上传订单数据到订单维护，订单编号：{{:1}}": "Uploading order data to Order Maintenance, Order No.: {{:1}}",
    "上传订单数据到订单维护，保修卡号：{{:1}}": "Uploading order data to Order Maintenance, Warranty Card No.: {{:1}}",
    "上传订单数据到订单维护失败，手机号：{{:1}}": "Failed to upload order data to Order Maintenance, Mobile No.: {{:1}}",
    "上传订单数据到订单维护失败，订单编号：{{:1}}": "Failed to upload order data to Order Maintenance, Order No.: {{:1}}",
    "上传订单数据到订单维护失败，保修卡号：{{:1}}": "Failed to upload order data to Order Maintenance, Warranty Card No.: {{:1}}",
    "上传订单数据，上传批次：{{:1}}": "Uploading order data, upload batch: {{:1}}",
    "批量删除订单数据，订单ID：{{:1}}": "Delete order data by batch, order ID: {{:1}}",
    "按批次删除订单数据，订单批次：{{:1}}": "Delete order data by batch, order batch: {{:1}}",
    "重新提交": "Resubmit",
    "订单数据上传明细": "Order data upload details",
    "重新上传订单数据，手机号：{{:1}}": "Re-upload order data, Mobile No.: {{:1}}",
    "重新上传订单数据，订单编号：{{:1}}": "Re-upload order data, Order No.: {{:1}}",
    "重新上传订单数据，保修卡号：{{:1}}": "Re-upload order data, Warranty Card No.: {{:1}}",
    "重新上传": "Re-upload",
    "订单维护已存在，手机号：{{:1}}": "Order Maintenance Already Exists, Mobile No.: {{:1}}",
    "订单维护已存在，订单编号：{{:1}}": "Order Maintenance Already Exists, Order No.: {{:1}}",
    "订单维护已存在，保修卡号：{{:1}}": "Order Maintenance Already Exists, Warranty Card No.: {{:1}}",
    "《De'longhi Group》服务器磁盘空间不足警告": "《De'longhi Group》server disc space is running low",
    "注意：《De'longhi Group》网络售后维护系统服务器磁盘空间即将用尽，空间不足会影响系统功能正常使用，请尽快处理": "Attention:《De'longhi Group》server is running out of disk space, and the lack of space will affect the normal use of system functions, so please deal with it as soon as possible.",
    "模板": "Template",
    "导入模板": "Import Template",
    "深度清洁费用": "Deep Cleaning Fee",
    "深度清洁结算金额": "Deep Cleaning Settlement Amount",
    "小程序单号": "Applet No.",
    "海外数据": "Overseas Data",
    "请选择需要修改的海外数据": "Please select the overseas data to be modified",
    "请选择要删除的海外数据": "Please select the overseas data to be deleted",
    "删除海外数据": "Delete Overseas Data",
    "确定要删除机器型号为【{{:a}}】的海外数据吗？": "Are you sure you want to delete the overseas data whose machine model is [{{:a}}]?",
    "确定要删除选中所有海外数据吗？": "Are you sure you want to delete all the selected overseas data?",
    "海外数据导入出错": "Overseas data import error",
    "{{:a}}等海外数据导入出错": "There is an error importing overseas data such as {{:a}}.",
    "海外数据导出失败，后台发生错误。": "Overseas data export failed and an error occurred in the background.",
    "海外数据上传明细": "Overseas Data Upload Details",
    "导出海外数据": "Export Overseas Data",
    "导出咖啡机离仓数据": "Export Coffee Machine De-storage Data",
    "新增海外数据，机器型号：{{:1}}，机器系列号：{{:2}}": "Overseas data added, Machine Model: {{:1}} , Machine Series No.: {{:2}}",
    "更新海外数据，机器型号：{{:1}}，机器系列号：{{:2}}": "Update Overseas Data, Machine Model: {{:1}} , Machine Series No.: {{:2}}",
    "删除海外数据，机器型号：{{:1}}，机器系列号：{{:2}}": "Delete Overseas Data, Machine Model: {{:1}} , Machine Serial No.: {{:2}}",
    "删除海外数据，ID：{{:1}}": "Delete Overseas Data, ID: {{:1}}",
    "批量删除海外数据，ID：{{:1}}": "Batch Delete Overseas Data, ID: {{:1}}",
    "批量删除海外数据": "Batch Delete Overseas Data",
    "上传海外数据": "Upload Overseas Data",
    "新增咖啡机离仓数据，机器型号：{{:1}}，机器系列号：{{:2}}": "Added Coffee Machine De-storage Data, Machine Model: {{:1}}, Machine Series No.: {{:2}}",
    "更新咖啡机离仓数据，机器型号：{{:1}}，机器系列号：{{:2}}": "Updated Coffee Machine De-storage Data, Machine Model: {{:1}}, Machine Series No.: {{:2}}.",
    "删除咖啡机离仓数据，机器型号：{{:1}}，机器系列号：{{:2}}": "Delete Coffee Machine De-storage Data, Machine Model: {{:1}}, Machine Series No.: {{:2}}",
    "批量删除咖啡机离仓数据，ID：{{:1}}": "Batch Delete Coffee Machine De-storage Data, ID: {{:1}}",
    "批量删除咖啡机离仓数据": "Batch Delete Coffee Machine De-storage Data",
    "上传咖啡机离仓数据": "Upload Coffee Machine De-storage Data",
    "否决所选保养单": "Veto the selected maintenance order",
    "请选择要否决的工单": "Please select the work order to veto",
    "保养": "Maintenance",
    "结账单类型": "Checkout Type",
    "新建维修单，单号：": "New repair order, System No: ",
    "账号统计": "Account Statistics",
    "上月": "Last month",
    "本月": "This month",
    "本年": "This year",
    "1月": "January",
    "2月": "February",
    "3月": "March",
    "4月": "April",
    "5月": "May",
    "6月": "June",
    "7月": "July",
    "8月": "August",
    "9月": "September",
    "10月": "October",
    "11月": "November",
    "12月": "December",
    "系统版本": "System Version",
    "最低要求版本": "Min Version",
    "当前版本": "Current Version",
    "新版本": "New Version",
    "休假时间": "Leave Time",
    "新建保养单，单号：": "New Maintenance Order, System No:",
    "处理保养单，单号：": "Process Maintenance Order, System No:",
    "删除保养单，单号：": "Delete Maintenance Order, System No:",
    "删除维修单，单号：": "Delete Repair Order, System No:",
    "批量上传维修单，单号：": "Batch Upload Repair Order, System No:",
    "更新维修单机器编号，单号：{{:1}} 机器编号：{{:2}}": "Update Repair Order Machine No, System No: {{:1}} Machine No: {{:2}}",
    "更新维修单服务单编号，单号：{{:1}} 服务单编号：{{:2}}": "Update Repair Order Sheet No, System No: {{:1}} Sheet No: {{:2}}",
    "更新下次保养时间，单号：": "Update the next maintenance time, System No:",
    "更新预约成功单号，单号：": "Update the appointment successful order No, System No:",
    "确定删除发票{{:1}}吗？": "Are you sure you want to delete invoice{{:1}}?",
    "查看发票": "View Invoice",
    "国内出仓日期保修": "Domestic Warranty",
    "海外出仓日期保修": "Overseas Warranty",
    "否决所选订单": "Veto the selected order",
    "订单数据加载中...": "Order data loading...",
    "订单维护中已存在，手机号：{{:1}}": "Order maintenance already exists, Mobile Phone No: {{:1}}",
    "订单维护中已存在，订单编号：{{:1}}": "Order maintenance already exists, Order No: {{:1}}",
    "订单维护中已存在，保修卡号：{{:1}}": "Order maintenance already exists, Warranty Card No: {{:1}}",
    "删除订单数据，ID：{{:1}}，手机号：{{:2}}": "Delete order data, ID: {{:1}}, Mobile Phone No: {{:2}}",
    "删除订单数据，ID：{{:1}}，订单编号：{{:2}}": "Delete order data, ID: {{:1}}, Order No: {{:2}}",
    "删除订单数据，ID：{{:1}}，保修卡号：{{:2}}": "Delete order data, ID: {{:1}}, Warranty Card No: {{:2}}",
    "收起": "Collapse",
    "展开": "Expand",
    "查看全部订单": "View All Orders",
    "查看退回订单": "View Returned Orders",
    "查看否决订单": "View Vetoed Orders",
    "退回数量": "Returned Quantity",
    "否决数量": "Vetoed Quantity",
    "待提交": "Pending",
    "否决订单数据": "Veto Order Data",
    "作废订单数据": "Void Order Data",
    "删除订单": "Delete Order",
    "删除订单服务中的订单": "Delete orders from the order service",
    "删除{{:1}}条订单": "Delete {{{:1}}} orders",
    "按上传批次删除订单维护数据，上传批次：{{:1}}": "Delete order maintenance data by upload batch, upload batch: {{:1}}",
    "订单服务工单": "Order Service Work Order",
    "订单服务工单获取失败": "Order Service Work Order Failure",
    "请选择订单服务工单": "Please select Order Service Work Order",
    "删除订单服务工单": "Delete Order Service Work Order",
    "删除工单同时会删除工单中所有关联的订单数据，确定要删除订单服务工单【{{:1}}】吗？": "Deleting a work order also deletes all associated order data in the work order. Are you sure you want to delete the order service work order [{{:1}}]?",
    "请选择要删除的订单服务工单": "Please select the Order Service Work Order you want to delete.",
    "配件重复":"Duplicate accessories",
    "保养回访查询导出失败，后台发生错误。": "Maintenance return query export failed and an error occurred in the backend.",
    "离仓数据上传明细导出失败，后台发生错误。": "Failed to export the uploaded details of the departure data, an error occurred in the backend.",
    "数据导出失败，后台发生错误。": "The data export fails and an error occurs in the backend.",
    "数据导出失败，请重试": "Data export failed, please try again",
}